var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sec_banner" },
    [
      _vm.width && _vm.height
        ? _c(
            "div",
            { staticClass: "warn-box" },
            [
              _c("svg-icon", {
                staticStyle: { "margin-right": "17px" },
                attrs: { "icon-class": "warn", size: 20 }
              }),
              _c("p", [
                _c("span", [_vm._v("图片要求！")]),
                _c("br"),
                _vm._v(
                  "图片宽度" +
                    _vm._s(_vm.width) +
                    "px，高度" +
                    _vm._s(_vm.height) +
                    "px。"
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._l(_vm.itemList, function(item, index) {
        return [
          _c("bannerForm", {
            key: index,
            staticStyle: { "margin-top": "10px" },
            attrs: {
              "item-index": index,
              "show-tool": true,
              "form-data": item,
              type: "imageLink",
              imgWidth: _vm.width,
              imgHeight: _vm.height
            },
            on: {
              upSortItem: function($event) {
                return _vm.upSortItem(index)
              },
              downSortItem: function($event) {
                return _vm.downSortItem(index)
              },
              deleteItem: function($event) {
                return _vm.deleteItem(index)
              }
            }
          })
        ]
      }),
      _vm.itemList.length > 0 && _vm.itemList.length < _vm.itemNum
        ? _c("el-divider")
        : _vm._e(),
      _vm.itemList.length < _vm.itemNum
        ? _c("div", { staticClass: "add-div", on: { click: _vm.addItem } }, [
            _c("i", { staticClass: "el-icon-plus" }),
            _vm._v(
              "添加图片(" +
                _vm._s(_vm.itemList.length) +
                "/" +
                _vm._s(_vm.itemNum) +
                ") "
            )
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }