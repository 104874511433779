"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _guest = require("@/api/guest");
var _myDatePicker = _interopRequireDefault(require("@/components/myDatePicker"));
var _customPage = _interopRequireDefault(require("@/components/customPage"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    customPage: _customPage.default,
    myDatePicker: _myDatePicker.default
  },
  data: function data() {
    return {
      loading: true,
      searchForm: {
        channel: '',
        orderStatus: '',
        orderCreateTime: '',
        riskMobile: ''
      },
      total: 0,
      tableData: [],
      statusList: []
    };
  },
  computed: {
    resetType: function resetType() {
      var type = '';
      for (var key in this.searchForm) {
        if (key != 'channel' && this.searchForm[key] != '') {
          return 'danger';
        }
      }
      return '';
    }
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      this.getChannel();
      this.getOrderStatus();
      this.getList();
    },
    getChannel: function getChannel() {
      this.searchForm.channel = this.$route.query.channel ? this.$route.query.channel : '';
    },
    getList: function getList(flag) {
      var _this = this;
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      if (!flag) {
        // this.tableData = []
      }
      this.loading = true;
      (0, _guest.getChannelOrderList)((0, _objectSpread2.default)({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        _this.tableData = res.list.data || [];
        _this.total = res.list.total;
        _this.loading = false;
      }).catch(function (res) {
        _this.loading = false;
      });
    },
    getOrderStatus: function getOrderStatus() {
      var _this2 = this;
      (0, _guest.getOrderStatus)().then(function (res) {
        var list = [];
        for (var key in res) {
          list.push({
            id: key,
            name: res[key]
          });
        }
        _this2.statusList = list || [];
      });
    },
    // 查询
    search: function search() {
      var pickerVal = this.$refs.myDatePicker.getVal();
      if (pickerVal !== 'error') {
        this.searchForm.orderCreateTime = pickerVal;
      }
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      if (this.$refs.myDatePicker) {
        this.$refs.myDatePicker.reset();
      }
      this.searchForm = this.$options.data().searchForm;
      this.getChannel();
      this.getList();
    },
    filterUserOrder: function filterUserOrder(item) {
      this.searchForm.riskMobile = item.mobile;
      this.getList();
    }
  }
};
exports.default = _default;