var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "flex align-center table-top-btns" },
        [
          _c(
            "h5",
            { staticClass: "flex align-center" },
            [
              _c("svg-icon", {
                staticStyle: { "margin-right": "15px" },
                attrs: { "icon-class": "list", size: 15 }
              }),
              _vm._v("活动列表\n        ")
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "add-btn",
              attrs: { size: "small", icon: "el-icon-plus" },
              on: {
                click: function($event) {
                  return _vm.toEdit()
                }
              }
            },
            [_vm._v("添加活动页")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tableData.length > -1,
              expression: "tableData.length > -1"
            },
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: { data: _vm.tableData, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", prop: "id", label: "id", width: "70" }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "title",
              label: "标题",
              width: "300"
            }
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "", label: "小程序地址" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [_vm._v(_vm._s(_vm.alipayExample(scope.row.id)))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "status",
              label: "状态",
              width: "120"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status == 2
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("已发布")
                        ])
                      : _c("el-tag", { attrs: { type: "info" } }, [
                          _vm._v("未发布")
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              align: "center",
              label: "操作",
              width: "160"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "btn-box" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.toEdit(scope.row.id)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        ),
                        scope.row.status == 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "warning", size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.changeRelease(scope.row)
                                  }
                                }
                              },
                              [_vm._v("发布")]
                            )
                          : _c(
                              "el-button",
                              {
                                attrs: { type: "danger", size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.changeRelease(scope.row)
                                  }
                                }
                              },
                              [_vm._v("取消发布")]
                            )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("custom-page", {
        ref: "customPage",
        attrs: { total: _vm.total },
        on: { getList: _vm.getList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }