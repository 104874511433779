var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "div",
        [
          _vm._l(_vm.goodsList, function(item, index) {
            return _c("goods-link", {
              key: item.goods_id,
              attrs: { "goods-data": item, "show-tool": true },
              on: {
                handleDelete: function($event) {
                  return _vm.deleteGoods(index)
                },
                upSort: function($event) {
                  return _vm.upSort(index)
                },
                downSort: function($event) {
                  return _vm.downSort(index)
                }
              }
            })
          }),
          _vm.goodsList.length < _vm.goodsNum
            ? _c(
                "div",
                { staticClass: "add-div", on: { click: _vm.openSelect } },
                [
                  _c("i", { staticClass: "el-icon-plus" }),
                  _vm._v(
                    "\n            添加商品(" +
                      _vm._s(_vm.goodsList.length) +
                      "/" +
                      _vm._s(_vm.goodsNum) +
                      ")\n        "
                  )
                ]
              )
            : _vm._e()
        ],
        2
      ),
      _c("goodsDialog", {
        ref: "goodsDialog",
        attrs: {
          multiple: true,
          maxNum: _vm.goodsNum,
          "show-checked-tool": true
        },
        on: { setSelect: _vm.setSelect }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }