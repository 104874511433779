import { render, staticRenderFns } from "./share.vue?vue&type=template&id=547eac59&scoped=true&"
import script from "./share.vue?vue&type=script&lang=js&"
export * from "./share.vue?vue&type=script&lang=js&"
import style0 from "./share.vue?vue&type=style&index=0&id=547eac59&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "547eac59",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\www\\ssz_gold_backend\\multi_merchant_web\\gold_supplier\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('547eac59')) {
      api.createRecord('547eac59', component.options)
    } else {
      api.reload('547eac59', component.options)
    }
    module.hot.accept("./share.vue?vue&type=template&id=547eac59&scoped=true&", function () {
      api.rerender('547eac59', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/goods/components/share.vue"
export default component.exports