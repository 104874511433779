"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addOrderLeaseNoShow = addOrderLeaseNoShow;
exports.addressGetRiskArea = addressGetRiskArea;
exports.applyWithhold = applyWithhold;
exports.cancelOrderSave = cancelOrderSave;
exports.changeLease = changeLease;
exports.clearRateTypeData = clearRateTypeData;
exports.clearWithholdCacheApi = clearWithholdCacheApi;
exports.confirmGoods = confirmGoods;
exports.delRiskArea = delRiskArea;
exports.delRiskName = delRiskName;
exports.deleteRemarkOne = deleteRemarkOne;
exports.deliverGoods = deliverGoods;
exports.editMemberInfo = editMemberInfo;
exports.editOrderExpress = editOrderExpress;
exports.editOrderService = editOrderService;
exports.editRiskArea = editRiskArea;
exports.editRiskName = editRiskName;
exports.editRiskNameExcel = editRiskNameExcel;
exports.getApplyCancelList = getApplyCancelList;
exports.getApplyWithholdList = getApplyWithholdList;
exports.getCanPayMoneyByOrderId = getCanPayMoneyByOrderId;
exports.getEsignContract = getEsignContract;
exports.getLease = getLease;
exports.getLeaseFlow = getLeaseFlow;
exports.getLeaseForMayi = getLeaseForMayi;
exports.getLeaseInfo = getLeaseInfo;
exports.getLogisticAll = getLogisticAll;
exports.getLogisticInfo = getLogisticInfo;
exports.getMerchant = getMerchant;
exports.getMerchantTransByMoreServiceId = getMerchantTransByMoreServiceId;
exports.getNumCountList = getNumCountList;
exports.getOrderContract = getOrderContract;
exports.getOrderRemark = getOrderRemark;
exports.getOrderRiskControl = getOrderRiskControl;
exports.getOrderStatus = getOrderStatus;
exports.getRefushDistribution = getRefushDistribution;
exports.getRiskAreaList = getRiskAreaList;
exports.getRiskBlacklistList = getRiskBlacklistList;
exports.getRiskNameList = getRiskNameList;
exports.mayicancelPayOne = mayicancelPayOne;
exports.merRefuseRefund = merRefuseRefund;
exports.moreServicePay = moreServicePay;
exports.moreServiceRefund = moreServiceRefund;
exports.orderBuyOutList = orderBuyOutList;
exports.orderBuyOutListExport = orderBuyOutListExport;
exports.orderClose = orderClose;
exports.orderCloseMerchant = orderCloseMerchant;
exports.orderComfirmReturn = orderComfirmReturn;
exports.orderFinish = orderFinish;
exports.orderHasSue = orderHasSue;
exports.orderHuaBeiExport = orderHuaBeiExport;
exports.orderHuaBeiList = orderHuaBeiList;
exports.orderList = orderList;
exports.orderListCount = orderListCount;
exports.orderListExport = orderListExport;
exports.orderListRentExport = orderListRentExport;
exports.orderReletPass = orderReletPass;
exports.orderReletReject = orderReletReject;
exports.orderRenewalList = orderRenewalList;
exports.orderReturnAgree = orderReturnAgree;
exports.orderReturnFinish = orderReturnFinish;
exports.orderReturnRefuse = orderReturnRefuse;
exports.orderShareList = orderShareList;
exports.orderSignedList = orderSignedList;
exports.orderSignedListExport = orderSignedListExport;
exports.riskPassApi = riskPassApi;
exports.saveDistributionOrder = saveDistributionOrder;
exports.saveLockStatus = saveLockStatus;
exports.saveSetMeal = saveSetMeal;
exports.setAddress = setAddress;
exports.setDeviceNumber = setDeviceNumber;
exports.setDistributionOrder = setDistributionOrder;
exports.setLeaseSplite = setLeaseSplite;
exports.setOrderCostPrice = setOrderCostPrice;
exports.setOrderRemark = setOrderRemark;
exports.setOrderVoucher = setOrderVoucher;
exports.setReletId = setReletId;
exports.successOrderReturn = successOrderReturn;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
/**
 * 订单管理
 */
// 订单列表
function orderList(params) {
  return _fetch.default.post("/Supplier/Order/orderList", params);
}
// 订单对应状态数量
function orderListCount(params) {
  return _fetch.default.post("/Supplier/Order/orderListCount", params);
}
// 修改用户身份证信息
function editMemberInfo(params) {
  return _fetch.default.post("/Supplier/Order/editMemberInfo", params);
}
// 查看租期
function getLease(params) {
  return _fetch.default.post("/Supplier/Order/getLease", params);
}
// 查看风控信息
function getOrderRiskControl(params) {
  return _fetch.default.post("/Supplier/Order/getOrderRisk", params);
}
// 转单
function setDistributionOrder(params) {
  return _fetch.default.post("/Supplier/Order/distributionOrder", params);
}
// 提交接单状态
function saveDistributionOrder(params) {
  return _fetch.default.post("/Supplier/Order/saveDistributionOrder", params, true);
}
// 商户拒绝退款申请，继续订单流程
function merRefuseRefund(params) {
  return _fetch.default.post("/Supplier/Order/merRefuseRefund", params, true);
}
// 设置修改收货地址
function setAddress(params) {
  return _fetch.default.post("/Supplier/Order/setAddress", params, true);
}
// 修改套餐
function saveSetMeal(params) {
  return _fetch.default.post("/Supplier/Order/saveSetMeal", params, true);
}
// 添加设备编号
function setDeviceNumber(params) {
  return _fetch.default.post("/Supplier/Order/setDeviceNumber", params, true);
}
// 添加客服备注
function setOrderRemark(params) {
  return _fetch.default.post("/Supplier/Order/setOrderRemark", params, true);
}
// 绑定续租商品
function setReletId(params) {
  return _fetch.default.post("/Supplier/Order/setReletId", params, true);
}
// 设置订单供应商价格
function setOrderCostPrice(params) {
  return _fetch.default.post("/Supplier/Order/setOrderCostPrice", params, true);
}
// 获取客服备注记录
function getOrderRemark(params) {
  return _fetch.default.post("/Supplier/Order/getOrderRemark", params);
}
// 查看流水记录
function getLeaseFlow(orderNo) {
  return _fetch.default.post("/Supplier/Order/getLeaseFlow", {
    orderNo: orderNo
  });
}
// 获取物流公司列表
function getLogisticAll() {
  return _fetch.default.post("/Supplier/Order/getLogisticAll");
}
// 发货
function deliverGoods(params) {
  return _fetch.default.post("/Supplier/Order/deliverGoods", params, true);
}
// 确认收货
function confirmGoods(params) {
  return _fetch.default.post("/Supplier/Order/confirmGoods", params, true);
}
// 获取账单详情
function getLeaseInfo(orderId) {
  return _fetch.default.post("/Supplier/Order/getLeaseInfo", {
    orderId: orderId
  });
}

// 删除订单备注记录
function deleteRemarkOne(id) {
  return _fetch.default.post("/Supplier/Order/delOrderRemark", {
    id: id
  });
}

// 获取物流信息
function getLogisticInfo(params) {
  return _fetch.default.post("/Supplier/Order/getLogisticInfo", params);
}
// 补充发货确认收货凭证
function setOrderVoucher(params) {
  return _fetch.default.post("/Supplier/Order/setOrderVoucher", params, true);
}
// 获取订单状态列表
function getOrderStatus() {
  return _fetch.default.post("/Supplier/Order/getOrderStatus");
}
// 获取供应商所属商户
function getMerchant() {
  return _fetch.default.get("/Supplier/Order/getMerchant");
}
// 订单列表导出
function orderListExport(params) {
  return _fetch.default.export("/Supplier/Order/orderListExport", params, "订单列表");
}

// 订单列表导出
function orderListRentExport(params) {
  return _fetch.default.export("/Supplier/Order/orderListRentExport", params, "租用中订单列表");
}

// 花呗订单列表
function orderHuaBeiList(params) {
  return _fetch.default.post("/Supplier/Order/orderHuaBeiList", params);
}
// 花呗订单列表-导出
function orderHuaBeiExport(params) {
  return _fetch.default.export("/Supplier/Order/orderHuaBeiExport", params, "花呗订单列表");
}
// 买断订单列表
function orderBuyOutList(params) {
  return _fetch.default.post("/Supplier/Order/orderBuyOutList", params);
}
// 买断订单列表-导出
function orderBuyOutListExport(params) {
  return _fetch.default.export("/Supplier/Order/orderBuyOutListExport", params, "买断订单列表");
}
// 签约待付款订单列表
function orderSignedList(params) {
  return _fetch.default.post("/Supplier/Order/orderSignedList", params);
}
// 签约待支付订单列表-导出
function orderSignedListExport(params) {
  return _fetch.default.export("/Supplier/Order/orderSignedListExport", params, "签约待支付订单列表");
}
// 交易完成
function orderFinish(orderId) {
  return _fetch.default.post("/Supplier/Order/orderFinish", {
    orderId: orderId
  }, true);
}

// 订单同意归还
function orderReturnAgree(orderId) {
  return _fetch.default.post("/Supplier/Order/orderReturnAgree", {
    orderId: orderId
  }, true);
}
// 订单归还审核拒绝
function orderReturnRefuse(params) {
  return _fetch.default.post("/Supplier/Order/orderReturnRefuse", params, true);
}
// 订单确认归还
function orderComfirmReturn(orderId) {
  return _fetch.default.post("/Supplier/Order/orderComfirmReturn", {
    orderId: orderId
  }, true);
}
// 订单归还完成
function orderReturnFinish(orderId) {
  return _fetch.default.post("/Supplier/Order/orderReturnFinish", {
    orderId: orderId
  }, true);
}

// 订单关闭
function orderClose(orderId) {
  return _fetch.default.post("/Supplier/Order/orderClose", {
    orderId: orderId
  }, true);
}

// 订单关闭 (供应商关闭)
function orderCloseMerchant(params) {
  return _fetch.default.post("/Supplier/Order/orderCloseMerchant", params, true);
}

// 风控审核通过
function riskPassApi(orderId) {
  return _fetch.default.post("/Supplier/Order/riskPass", {
    orderId: orderId
  }, true);
}

// 续租订单列表
function orderRenewalList(params) {
  return _fetch.default.post("/Supplier/Order/orderRenewalList", params);
}
// 修改订单物流信息
function editOrderExpress(params) {
  return _fetch.default.post("/Supplier/Order/editOrderExpress", params, true);
}

// 获取扣款申请列表
function getApplyWithholdList(params) {
  return _fetch.default.get("/Supplier/Order/getApplyWithhold", params);
}

// 获取扣款申请列表
function getApplyCancelList(params) {
  return _fetch.default.get("/Supplier/Order/getApplyCancel", params);
}
// 申请扣款
function applyWithhold(params) {
  return _fetch.default.post("/Supplier/Order/applyWithhold", params);
}
// 取消订单申请审核
function cancelOrderSave(params) {
  return _fetch.default.post("/Supplier/Order/cancelOrderSave", params, true);
}

// 获取合同信息
function getOrderContract(params) {
  return _fetch.default.post("/Supplier/Order/getOrderContract", params);
}
// 获取易签宝签约合同
function getEsignContract(params) {
  return _fetch.default.post("/Supplier/Order/getEsignContract", params);
}

// 获取风险地区列表
function getRiskAreaList(params) {
  return _fetch.default.get("/Supplier/Order/getRiskAreaList", params);
}
// 编辑风险地区列表
function editRiskArea(params) {
  return _fetch.default.post("/Supplier/Order/editRiskArea", params, true);
}
// 删除风险地区列表
function delRiskArea(id) {
  return _fetch.default.post("/Supplier/Order/delRiskArea", {
    id: id
  }, true);
}

// 获取风险姓名列表
function getRiskNameList(params) {
  return _fetch.default.get("/Supplier/Order/getRiskNameList", params);
}
// 编辑风险姓名列表
function editRiskName(params) {
  return _fetch.default.post("/Supplier/Order/editRiskName", params, true);
}

// 编辑风险姓名列表 导入
function editRiskNameExcel(params) {
  return _fetch.default.post("/Supplier/Order/editRiskNameExcel", params, true);
}
// 删除风险姓名
function delRiskName(id) {
  return _fetch.default.post("/Supplier/Order/delRiskName", {
    id: id
  }, true);
}

// 获取下单拦截列表
function getRiskBlacklistList(params) {
  return _fetch.default.get("/Supplier/Order/getRiskBlacklistList", params);
}

// 查询下属风险地区
function addressGetRiskArea(params) {
  return _fetch.default.get("/Supplier/Order/addressGetRiskArea", params);
}
// 查询下属风险地区
function getRefushDistribution(order_id) {
  return _fetch.default.get("/Supplier/Order/getRefushDistribution", {
    order_id: order_id
  });
}

// 编辑订单增值服务
function editOrderService(params) {
  return _fetch.default.post("/Supplier/Order/editOrderService", params, true);
}

// 获取订单分销信息
function orderShareList(order_id) {
  return _fetch.default.get("/Supplier/Order/orderShareList", {
    order_id: order_id
  }, true);
}

// 编辑订单增值服务
function clearRateTypeData(params) {
  return _fetch.default.post("/Supplier/Order/clearRateTypeData", params, true);
}

// 查看代扣详情
function getLeaseForMayi(orderId) {
  return _fetch.default.post("/Supplier/Order/getLeaseForMayi", {
    orderId: orderId
  });
}

// 取消指定期数的代扣
function mayicancelPayOne(params) {
  return _fetch.default.post("/Supplier/Order/mayicancelPayOne", params, true);
}
// 获取待审核订单数据
function getNumCountList(params) {
  return _fetch.default.get("/Supplier/Order/getNumCountList", params);
}

// 更多服务退款
function moreServiceRefund(params) {
  return _fetch.default.post("/Supplier/Order/moreServiceRefund", params, true);
}

// 添加已起诉标识
function orderHasSue(params) {
  return _fetch.default.post("/Supplier/Order/orderHasSue", params, true);
}

// 租期账单拆分
function setLeaseSplite(params) {
  return _fetch.default.post("/Supplier/Order/setLeaseSplite", params, true);
}
// 编辑订单手机是否可上锁
function saveLockStatus(params) {
  return _fetch.default.post("/Supplier/Order/saveLockStatus", params, true);
}
// 仅修改订单状态交易完成为已退款
function successOrderReturn(params) {
  return _fetch.default.post("/Supplier/Order/successOrderReturn", params, true);
}

// 续租订单通过申请
function orderReletPass(params) {
  return _fetch.default.post("/Supplier/Order/orderReletPass", params, true);
}
// 续租订单拒绝申请
function orderReletReject(params) {
  return _fetch.default.post("/Supplier/Order/orderReletReject", params, true);
}

// 获取可扣除的押金总额
function getCanPayMoneyByOrderId(params) {
  return _fetch.default.get("/Supplier/Order/getCanPayMoneyByOrderId", params);
}

// 扣除押金
function addOrderLeaseNoShow(params) {
  return _fetch.default.post("/Supplier/Order/addOrderLeaseNoShow", params, true);
}
// 清除支付失败后5分钟不让支付的限制
function clearWithholdCacheApi(params) {
  return _fetch.default.post("/Supplier/Order/clearWithholdCacheApi", params, true);
}
// 清除支付失败后5分钟不让支付的限制
function getMerchantTransByMoreServiceId(params) {
  return _fetch.default.get("/Supplier/Order/getMerchantTransByMoreServiceId", params);
}

// 更多服务线下支付
function moreServicePay(params) {
  return _fetch.default.post("/Supplier/Order/moreServicePay", params, true);
}

// 更换租期
function changeLease(params) {
  return _fetch.default.post("/Supplier/Order/changeLease", params);
}