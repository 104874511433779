"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es6.string.sub");
var _index = require("@/api/index");
var _shop = require("@/api/shop");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["editId"],
  data: function data() {
    return {
      dialogVisible: false,
      form: {
        provinceArr: '',
        address: '',
        promise: ''
      },
      rules: {
        provinceArr: [{
          required: true,
          message: '请选择所在地址',
          trigger: 'change'
        }],
        address: [{
          required: true,
          message: '地址详细信息不能为空',
          trigger: 'blur'
        }],
        promise: [{
          required: true,
          message: '服务承诺不能为空',
          trigger: 'blur'
        }]
      },
      areaList: [],
      optionProps: {
        value: 'name',
        label: 'name',
        expandTrigger: 'hover',
        children: 'sub'
      }
    };
  },
  computed: {
    dialogTitle: function dialogTitle() {
      return this.editId ? "编辑租赁地址" : "添加租赁地址";
    }
  },
  methods: {
    getSelect: function getSelect() {
      var _this = this;
      (0, _index.getRegion)().then(function (res) {
        _this.areaList = res ? res[0].sub : [];
      });
    },
    submit: function submit() {
      var _this2 = this;
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          var params = (0, _objectSpread2.default)({}, _this2.form);
          params.province = params.provinceArr[0];
          params.city = params.provinceArr[1];
          params.area = params.provinceArr[2];
          delete params.provinceArr;
          if (_this2.editId) {
            params.id = _this2.editId;
          }
          (0, _shop.addOrEditLeaseAddress)(params).then(function (res) {
            _this2.dialogVisible = false;
            _this2.$emit('getList');
          });
        } else {
          _this2.$message({
            message: "请检查表单错误",
            type: "error"
          });
          return false;
        }
      });
    },
    getData: function getData() {
      var _this3 = this;
      (0, _shop.leaseAddressById)(this.editId).then(function (res) {
        var province = res.province,
          city = res.city,
          area = res.area,
          address = res.address,
          promise = res.promise;
        _this3.form.provinceArr = [province, city, area];
        _this3.form.address = address;
        _this3.form.promise = promise;
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var _this4 = this;
      if (val) {
        this.getSelect();
        this.$nextTick(function () {
          _this4.$refs['form'].resetFields();
          if (_this4.editId) {
            // 编辑
            _this4.getData();
          }
        });
      } else {
        this.form = {
          provinceArr: '',
          address: '',
          promise: ''
        };
      }
    }
  }
};
exports.default = _default;