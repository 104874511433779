"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _account = require("@/api/account");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      activeStep: 0,
      balance: 0,
      frozen: 0,
      form: {
        type: 1,
        user: '',
        amount: ''
      },
      infoData: {}
    };
  },
  props: ['withdrawId'],
  methods: {
    changeMoneyInput: function changeMoneyInput() {
      var pattern = /^[0-9]+([.]{1}[0-9]{1,2})?$/; // 正数的正则表达式
      // 不符合正整数时
      if (!pattern.test(this.form.amount)) {
        // input 框绑定的内容为空
        this.form.amount = '';
      }
    },
    submit: function submit() {
      var _this = this;
      if (!this.form.user) {
        return;
      }
      if (this.form.amount <= 0) {
        this.$message({
          message: "提现金额不能为空，不能小于等于零",
          type: "warning"
        });
        return;
      }
      // const pos = String(this.form.amount).indexOf('.') + 1;
      // const num = String(this.form.amount).length - pos;
      // console.log(pos, num);
      // if (num > 2) {
      //   this.$message({
      //     message: "提现金额小数点后不能超过两位",
      //     type: "warning"
      //   })
      //   return;
      // }
      (0, _account.withdrawalApply)(this.form.amount).then(function (res) {
        _this.$router.push({
          name: 'myWithdraw',
          params: {
            withdrawId: res.withdrawal_id
          }
        });
      });
    },
    getData: function getData() {
      var _this2 = this;
      (0, _account.myAccount)().then(function (res) {
        var balance = res.balance,
          frozen = res.frozen;
        _this2.balance = parseFloat(balance).toFixed(2);
        _this2.frozen = parseFloat(frozen).toFixed(2);
      });
    },
    getAccount: function getAccount() {
      var _this3 = this;
      this.activeStep = 0;
      this.form = {
        type: 1,
        user: '',
        amount: ''
      };
      (0, _account.getPayeeInfo)().then(function (res) {
        var payeename = res.payeename,
          alipayaccount = res.alipayaccount;
        if (alipayaccount) {
          _this3.form.user = alipayaccount + '（' + payeename + '）';
        }
      });
    },
    getInfo: function getInfo() {
      var _this4 = this;
      (0, _account.withdrawalInfo)(this.withdrawId).then(function (res) {
        _this4.infoData = res && res.withdrawal_info ? res.withdrawal_info : {};
        console.log(_this4.infoData);
        _this4.activeStep = _this4.infoData.status == 2 ? 2 : 1;
      });
    }
  },
  mounted: function mounted() {
    this.getData();
    if (this.withdrawId) {
      this.getInfo();
    } else {
      this.getAccount();
    }
  },
  watch: {
    $route: function $route(val) {
      console.log(val);
      if (this.withdrawId) {
        this.getInfo();
      } else {
        this.getAccount();
      }
    }
  }
};
exports.default = _default;