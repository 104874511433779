"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _defineProperty2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _xcx = require("@/api/xcx");
var _compForm = _interopRequireDefault(require("./components/compForm"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    compForm: _compForm.default
  },
  data: function data() {
    return {
      activeNum: 1,
      pageId: '',
      configData: (0, _defineProperty2.default)({
        first_level: {
          goods: [],
          main_title: ''
        },
        second_level: {
          goods: [],
          main_title: ''
        },
        third_level: {
          goods: [],
          main_title: ''
        }
      }, "third_level", {
        goods: [],
        main_title: ''
      }),
      pageTitle: '办公设备',
      pageType: 0,
      compFormData: {},
      imgWidth: 750,
      imgHeight: 810
    };
  },
  computed: {
    sectionList: function sectionList() {
      var sectionList = [];
      switch (this.pageTitle) {
        case '办公设备':
          sectionList = [{
            title: "商品（1+3）推荐位",
            key: 'first_level',
            imgName: '1.jpg',
            num: 4
          }, {
            title: "商品（3+4）推荐位",
            key: 'second_level',
            imgName: '2.jpg',
            num: 7
          }, {
            title: "商品（9）推荐位",
            key: 'third_level',
            imgName: '3.jpg',
            num: 9
          }];
          break;
        case '电脑平板':
          sectionList = [{
            title: "商品（3）推荐位",
            key: 'first_level',
            imgName: '5.jpg',
            num: 3
          }, {
            title: "商品（3+4）推荐位",
            key: 'second_level',
            imgName: '7.jpg',
            num: 6
          }, {
            title: "商品（9）推荐位",
            key: 'third_level',
            imgName: '3.jpg',
            num: 9
          }];
          break;
        case '健康理疗':
          sectionList = [{
            title: "商品（3）推荐位",
            key: 'first_level',
            imgName: '4.jpg',
            num: 3
          }, {
            title: "商品（3+4）推荐位",
            key: 'second_level',
            imgName: '2.jpg',
            num: 7
          }, {
            title: "商品（9）推荐位",
            key: 'third_level',
            imgName: '3.jpg',
            num: 9
          }];
          break;
        case '品牌手机':
          sectionList = [{
            title: "商品（1+4）推荐位",
            key: 'first_level',
            imgName: '6.jpg',
            num: 5
          }, {
            title: "商品（1+4）推荐位",
            key: 'second_level',
            imgName: '6.jpg',
            num: 5
          }];
          break;
        case '能源设备':
          sectionList = [{
            title: "商品（3）推荐位",
            key: 'first_level',
            imgName: '4.jpg',
            num: 3
          }, {
            title: "商品（3+4）推荐位",
            key: 'second_level',
            imgName: '2.jpg',
            num: 7
          }, {
            title: "商品（9）推荐位",
            key: 'third_level',
            imgName: '3.jpg',
            num: 9
          }];
          break;
        case '开店赋能':
          sectionList = [{
            title: "商品（1+3）推荐位",
            key: 'first_level',
            imgName: '1.jpg',
            num: 4
          }, {
            title: "商品（3+4）推荐位",
            key: 'second_level',
            imgName: '2.jpg',
            num: 7
          }, {
            title: "商品（9）推荐位",
            key: 'third_level',
            imgName: '3.jpg',
            num: 9
          }];
          break;
        case '数码摄影':
          sectionList = [{
            title: "商品（3）推荐位",
            key: 'first_level',
            imgName: '5.jpg',
            num: 3
          }, {
            title: "商品（3+4）推荐位",
            key: 'second_level',
            imgName: '7.jpg',
            num: 6
          }, {
            title: "商品（9）推荐位",
            key: 'third_level',
            imgName: '3.jpg',
            num: 9
          }];
          break;
        case '游戏设备':
          sectionList = [{
            title: "商品（3）推荐位",
            key: 'first_level',
            imgName: '4.jpg',
            num: 3
          }, {
            title: "商品（3+4）推荐位",
            key: 'second_level',
            imgName: '2.jpg',
            num: 7
          }, {
            title: "商品（9）推荐位",
            key: 'third_level',
            imgName: '3.jpg',
            num: 9
          }];
          break;
        case '智能家居':
          sectionList = [{
            title: "商品（1+3）推荐位",
            key: 'first_level',
            imgName: '1.jpg',
            num: 4
          }, {
            title: "商品（3+4）推荐位",
            key: 'second_level',
            imgName: '2.jpg',
            num: 7
          }, {
            title: "商品（9）推荐位",
            key: 'third_level',
            imgName: '3.jpg',
            num: 9
          }];
          break;
        case '无人机相机':
        case '二手精选':
          sectionList = [{
            title: "商品（2）推荐位",
            key: 'first_level',
            imgName: '10.jpg',
            num: 2
          }, {
            title: "商品（9）推荐位",
            key: 'second_level',
            imgName: '3.jpg',
            num: 9
          }, {
            title: "商品（2）推荐位",
            key: 'third_level',
            imgName: '10.jpg',
            num: 2
          }, {
            title: "商品（9）推荐位",
            key: 'fourth_level',
            imgName: '3.jpg',
            num: 9
          }];
          break;
        case '花呗专区':
          sectionList = [{
            title: "商品（3）推荐位",
            key: 'first_level',
            imgName: '4.jpg',
            num: 3
          }, {
            title: "商品（3+4）推荐位",
            key: 'second_level',
            imgName: '2.jpg',
            num: 7
          }, {
            title: "商品（9）推荐位",
            key: 'third_level',
            imgName: '3.jpg',
            num: 9
          }];
          break;
        case '热租爆品':
          sectionList = [{
            title: "商品（3）推荐位",
            key: 'first_level',
            imgName: '4.jpg',
            num: 3
          }, {
            title: "商品（3+4）推荐位",
            key: 'second_level',
            imgName: '2.jpg',
            num: 7
          }, {
            title: "商品（9）推荐位",
            key: 'third_level',
            imgName: '3.jpg',
            num: 9
          }];
          break;
        case '短租精选':
          sectionList = [{
            title: "商品（3）推荐位",
            key: 'first_level',
            imgName: '4.jpg',
            num: 3
          }, {
            title: "商品（3+4）推荐位",
            key: 'second_level',
            imgName: '2.jpg',
            num: 7
          }, {
            title: "商品（9）推荐位",
            key: 'third_level',
            imgName: '3.jpg',
            num: 9
          }];
          break;
        case '租完即送':
          sectionList = [{
            title: "商品（1+3）推荐位",
            key: 'first_level',
            imgName: '1.jpg',
            num: 4
          }, {
            title: "商品（3+4）推荐位",
            key: 'second_level',
            imgName: '2.jpg',
            num: 7
          }, {
            title: "商品（9）推荐位",
            key: 'third_level',
            imgName: '3.jpg',
            num: 9
          }];
          break;
        case '一元租机':
          sectionList = [{
            title: "商品（4）推荐位",
            key: 'first_level',
            imgName: '9.jpg',
            num: 4
          }, {
            title: "商品（3）推荐位",
            key: 'second_level',
            imgName: '4.jpg',
            num: 3
          }, {
            title: "商品（6）推荐位",
            key: 'third_level',
            imgName: '8.jpg',
            num: 6
          }, {
            title: "商品（6）推荐位",
            key: 'fourth_level',
            imgName: '8.jpg',
            num: 6
          }];
          break;
      }
      return sectionList;
    }
  },
  watch: {
    activeNum: function activeNum(val) {
      if (val) {
        this.setData(val);
      }
    }
  },
  mounted: function mounted() {
    this.pageTitle = this.$route.meta.title;
    switch (this.pageTitle) {
      case '办公设备':
        this.pageType = 1;
        break;
      case '电脑平板':
        this.pageType = 2;
        break;
      case '健康理疗':
        this.pageType = 3;
        break;
      case '品牌手机':
        this.pageType = 4;
        break;
      case '能源设备':
        this.pageType = 5;
        break;
      case '开店赋能':
        this.pageType = 6;
        break;
      case '数码摄影':
        this.pageType = 7;
        break;
      case '游戏设备':
        this.pageType = 8;
        break;
      case '智能家居':
        this.pageType = 9;
        break;
      case '花呗专区':
        this.pageType = 10;
        break;
      case '热租爆品':
        this.pageType = 11;
        break;
      case '短租精选':
        this.pageType = 12;
        break;
      case '租完即送':
        this.pageType = 13;
        break;
      case '一元租机':
        this.pageType = 14;
        this.imgHeight = 320;
        break;
      case '无人机相机':
        this.pageType = 30;
        this.imgHeight = 320;
        break;
      case '二手精选':
        this.pageType = 31;
        this.imgHeight = 320;
        break;
    }
    this.getData();
  },
  beforeRouteUpdate: function beforeRouteUpdate(to, from, next) {
    this.activeNum = 1;
    this.pageTitle = to.$route.meta.title;
    this.getData();
  },
  methods: {
    submit: function submit() {
      (0, _xcx.release)(this.pageType).then(function (res) {});
    },
    setData: function setData(pos) {
      var key = this.sectionList[pos - 1].key;
      var data = (0, _objectSpread2.default)({}, this.configData[key]);
      data.goods = data.goods ? data.goods : [];
      if (data.goods.length > 0) {
        data.goods.forEach(function (item) {
          item.id = item.id ? item.id : item.goods_id;
          item.goods_id = item.goods_id ? item.goods_id : item.id;
        });
      }
      this.compFormData = data;
      // this.$nextTick(() => {
      // 	this.$refs.compForm.formData = data;
      // })
    },
    getData: function getData() {
      var _this = this;
      (0, _xcx.getActivityPageByType)(this.pageType).then(function (result) {
        // console.log('getActivityPageByType', result)
        var data = result && result.details ? JSON.parse(result.details) : false;
        _this.setConfigData(data);
        _this.setData(_this.activeNum);
      });
    },
    setConfigData: function setConfigData() {
      var _this2 = this;
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.sectionList.forEach(function (item, index) {
        var key = 'first_level';
        switch (index + 1) {
          case 1:
            key = 'first_level';
            break;
          case 2:
            key = 'second_level';
            break;
          case 3:
            key = 'third_level';
            break;
          case 4:
            key = 'fourth_level';
            break;
        }
        _this2.configData[key] = data && data[key] ? data[key] : {
          goods: [],
          main_title: ''
        };
      });
    },
    save: function save() {
      var _this3 = this;
      var params = this.$refs.compForm.getSubmitData();
      if (params) {
        (0, _xcx.editActivityPage)((0, _objectSpread2.default)({
          type: this.pageType,
          page_title: this.pageTitle
        }, params)).then(function (res) {
          _this3.getData();
        });
      }
    },
    preview: function preview() {}
  }
};
exports.default = _default;