var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shop_classify" },
    [
      _c("div", { staticClass: "flex top_exp" }, [
        _c("img", {
          staticStyle: { "max-width": "280px", height: "200px" },
          attrs: {
            src: require("../../../assets/images/classify_exp.jpg"),
            alt: "轮播图设置"
          }
        }),
        _c("div", { staticClass: "flex-1 warn-box" }, [
          _c(
            "p",
            { staticClass: "flex align-center" },
            [
              _c("svg-icon", {
                staticStyle: { "margin-right": "17px" },
                attrs: { "icon-class": "warn", size: 20 }
              }),
              _c("span", { staticStyle: { "font-size": "16px" } }, [
                _vm._v("店铺分类设置说明")
              ])
            ],
            1
          ),
          _vm._m(0)
        ])
      ]),
      _c(
        "div",
        { staticClass: "flex align-center table-top-btns" },
        [
          _c(
            "h5",
            { staticClass: "flex align-center" },
            [
              _c("svg-icon", {
                staticStyle: { "margin-right": "15px" },
                attrs: { "icon-class": "list", size: 15 }
              }),
              _vm._v("数据列表\n    ")
            ],
            1
          ),
          _vm.$hasMethod("#addCategory")
            ? _c(
                "el-button",
                {
                  staticClass: "add-btn",
                  attrs: { size: "small", icon: "el-icon-plus" },
                  on: {
                    click: function($event) {
                      return _vm.openAddDialog()
                    }
                  }
                },
                [_vm._v("添加自定义分类")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "排序", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    !_vm.$hasMethod("#sortCategory")
                      ? _c("span", [_vm._v(_vm._s(scope.row.sort))])
                      : _c("el-input", {
                          attrs: {
                            oninput: "value=value.replace(/[^\\d]/g,'')",
                            maxLength: "9"
                          },
                          on: {
                            change: function($event) {
                              return _vm.changeSort(
                                scope.row.id,
                                scope.row.sort
                              )
                            }
                          },
                          model: {
                            value: scope.row.sort,
                            callback: function($$v) {
                              _vm.$set(scope.row, "sort", $$v)
                            },
                            expression: "scope.row.sort"
                          }
                        })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "cat_name", label: "分类名称", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "goodsCount", label: "商品数量", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "create_time", label: "添加时间", align: "center" }
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              align: "center",
              label: "操作",
              width: "200"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.$hasMethod("#manage")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "warning", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.goToManage(
                                  scope.row.id,
                                  scope.row.cat_name
                                )
                              }
                            }
                          },
                          [_vm._v("关联商品")]
                        )
                      : _vm._e(),
                    _vm.$hasMethod("#deleteCategory")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.removeItem(
                                  scope.row.id,
                                  scope.row.goodsCount
                                )
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("custom-page", {
        ref: "customPage",
        attrs: { total: _vm.total },
        on: { getList: _vm.getList }
      }),
      _c("add-category", { ref: "addCategory", on: { getList: _vm.getList } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticClass: "color-666", staticStyle: { "line-height": "28px" } },
      [
        _vm._v(
          "\n        1. 系统为商品创建了默认分类，自定义分类需避免与默认分类重复。"
        ),
        _c("br"),
        _vm._v(
          "\n        2. 店铺商品分类展示在店铺详情页中，可以帮助消费者快速找到商品。"
        ),
        _c("br"),
        _vm._v(
          "\n        3. 从多角度对店铺商品分类，例如品牌、适用人群、使用场景，有利于消费者快速定位到商品。\n      "
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }