var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "stock_dialog",
      attrs: {
        width: "900px",
        title: "修改库存",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "warn-box" },
        [
          _c("svg-icon", {
            staticStyle: { "margin-right": "17px" },
            attrs: { "icon-class": "warn", size: 20 }
          }),
          _c("p", [
            _vm._v(
              " 温馨提示：请如实填写库存信息，以确保商品可以在承诺发货时间内发出，避免可能的物流违规"
            )
          ])
        ],
        1
      ),
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, border: "" } },
        [
          _c("el-table-column", {
            attrs: { align: "center", prop: "title", label: "套餐信息" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "price", label: "套餐租金" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "quantity", label: "当前库存" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "库存增减", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "flex align-center" },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { size: "mini" },
                            on: {
                              change: function($event) {
                                return _vm.handleChange(scope.row)
                              }
                            },
                            model: {
                              value: scope.row.type,
                              callback: function($$v) {
                                _vm.$set(scope.row, "type", $$v)
                              },
                              expression: "scope.row.type"
                            }
                          },
                          [
                            _c("el-radio-button", { attrs: { label: "add" } }, [
                              _vm._v("增")
                            ]),
                            _c(
                              "el-radio-button",
                              { attrs: { label: "minus" } },
                              [_vm._v("减")]
                            )
                          ],
                          1
                        ),
                        _c("el-input", {
                          staticClass: "flex-1",
                          staticStyle: { "margin-left": "10px" },
                          attrs: { placeholder: "请输入" },
                          on: {
                            change: function($event) {
                              return _vm.handleChange(scope.row)
                            },
                            input: function(val) {
                              return _vm.changeNumberInput(scope.row, val)
                            }
                          },
                          model: {
                            value: scope.row.add,
                            callback: function($$v) {
                              _vm.$set(scope.row, "add", $$v)
                            },
                            expression: "scope.row.add"
                          }
                        })
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "改后库存", prop: "stock" }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.submit }
            },
            [_vm._v("保 存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }