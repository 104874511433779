"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _finance = require("@/api/finance");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["orderId", "leaseId", "payData"],
  data: function data() {
    return {
      dialogStatus: false,
      billForm: {
        trade_no: "",
        mobile: "",
        which_period: "",
        sms_code: ""
      },
      codeShow: true,
      count: "",
      timer: null
    };
  },
  methods: {
    clearTimer: function clearTimer() {
      this.codeShow = true;
      clearInterval(this.timer);
      this.timer = null;
    },
    editMobile: function editMobile() {
      this.$router.push({
        name: 'serviceMessage'
      });
    },
    getCode: function getCode() {
      var _this = this;
      (0, _finance.offlinePaySendCode)(this.orderId, this.leaseId).then(function (res) {
        var TIME_COUNT = 120;
        if (!_this.timer) {
          _this.count = TIME_COUNT;
          _this.codeShow = false;
          _this.timer = setInterval(function () {
            if (_this.count > 0 && _this.count <= TIME_COUNT) {
              _this.count--;
            } else {
              _this.clearTimer();
            }
          }, 1000);
        }
      }).catch(function (err) {
        _this.$message.error("验证码发送失败");
      });
    },
    onSubmit: function onSubmit() {
      var _this2 = this;
      if (!this.billForm.sms_code) {
        this.$message({
          message: "验证码不能为空",
          type: "error"
        });
        return false;
      }
      (0, _finance.setOfflinePay)({
        order_id: this.orderId,
        lease_id: this.leaseId,
        sms_code: this.billForm.sms_code
      }).then(function (res) {
        _this2.dialogStatus = false;
        _this2.billForm.sms_code = "";
        _this2.clearTimer();
        _this2.$parent.getList();
      }).catch(function (err) {});
    }
  },
  watch: {
    dialogStatus: function dialogStatus(val) {
      var _this3 = this;
      if (val) {
        this.$nextTick(function () {
          _this3.billForm = _this3.payData;
        });
        this.clearTimer();
      }
    }
  }
};
exports.default = _default;