"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/toConsumableArray.js"));
var _bannerForm = _interopRequireDefault(require("./bannerForm"));
var _goodsLink = _interopRequireDefault(require("@/components/goodsLink"));
var _goodsDialog = _interopRequireDefault(require("@/components/goodsDialog.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    bannerForm: _bannerForm.default,
    goodsLink: _goodsLink.default,
    goodsDialog: _goodsDialog.default
  },
  props: {
    formData: {
      type: Object,
      default: {}
    },
    title: {
      type: String
    }
  },
  watch: {
    imgData: {
      deep: true,
      handler: function handler(newVal) {
        for (var key in newVal) {
          this.formData.main[key] = newVal[key];
        }
      }
    }
  },
  mounted: function mounted() {
    for (var key in this.imgData) {
      this.imgData[key] = this.formData.main[key];
    }
  },
  data: function data() {
    return {
      imgData: {
        img: '',
        jumpType: '',
        jumpUrl: '',
        goods: {}
      }
    };
  },
  methods: {
    // 升序
    upSort: function upSort(index) {
      if (index === 0) {
        return;
      }
      var item = this.formData.goods[index];
      this.$set(this.formData.goods, index, this.formData.goods[index - 1]);
      this.$set(this.formData.goods, index - 1, item);
    },
    // 降序
    downSort: function downSort(index) {
      if (index === this.formData.goods.length - 1) {
        return;
      }
      var item = this.formData.goods[index];
      this.$set(this.formData.goods, index, this.formData.goods[index + 1]);
      this.$set(this.formData.goods, index + 1, item);
    },
    // 删除商品
    deleteGoods: function deleteGoods(index) {
      this.formData.goods.splice(index, 1);
    },
    // 设置选中
    setSelect: function setSelect(goodsList) {
      this.formData.goods = goodsList;
    },
    // 添加商品
    openSelect: function openSelect() {
      this.$refs.goodsDialog.dialogVisible = true;
      this.$refs.goodsDialog.selectItems = (0, _toConsumableArray2.default)(this.formData.goods);
    }
  }
};
exports.default = _default;