var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sort" }, [
    _c("div", { staticClass: "top" }, [
      _c("div", { staticClass: "flex top_exp" }, [
        _c("img", {
          staticStyle: { width: "280px", height: "240px" },
          attrs: {
            src: require("../../../assets/images/sort_exp.jpg"),
            alt: "商品排序"
          }
        }),
        _c("div", { staticClass: "flex-1 warn-box" }, [
          _c(
            "p",
            { staticClass: "flex align-center" },
            [
              _c("svg-icon", {
                staticStyle: { "margin-right": "17px" },
                attrs: { "icon-class": "warn", size: 20 }
              }),
              _c("span", { staticStyle: { "font-size": "16px" } }, [
                _vm._v("商品排序说明")
              ])
            ],
            1
          ),
          _vm._m(0)
        ])
      ]),
      _c(
        "div",
        { staticClass: "common_form_search" },
        [
          _c(
            "el-form",
            {
              ref: "searchForm",
              attrs: { inline: true, model: _vm.searchForm }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入商品名称", clearable: "" },
                    model: {
                      value: _vm.searchForm.title,
                      callback: function($$v) {
                        _vm.$set(_vm.searchForm, "title", $$v)
                      },
                      expression: "searchForm.title"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.search()
                        }
                      }
                    },
                    [_vm._v("搜索")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "list_content" },
      [
        _c("div", { staticClass: "flex align-center table-top-btns" }, [
          _c(
            "h5",
            { staticClass: "flex align-center" },
            [
              _c("svg-icon", {
                staticStyle: { "margin-right": "15px" },
                attrs: { "icon-class": "list", size: 15 }
              }),
              _vm._v("数据列表\n      ")
            ],
            1
          ),
          _c("p", { staticClass: "color-999" }, [
            _vm._v("共查询"),
            _c("span", { staticClass: "red-font" }, [
              _vm._v(_vm._s(_vm.total))
            ]),
            _vm._v("个商品")
          ])
        ]),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: { data: _vm.tableData, border: "" }
          },
          [
            _c("el-table-column", {
              attrs: { align: "center", label: "排序", width: "80" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      !_vm.$hasMethod("#sort")
                        ? _c("span", [_vm._v(_vm._s(scope.row.shop_sort))])
                        : _c("el-input", {
                            attrs: {
                              oninput: "value=value.replace(/[^\\d]/g,'')",
                              maxLength: "9"
                            },
                            on: {
                              change: function($event) {
                                return _vm.changeSort(
                                  scope.row.id,
                                  scope.row.shop_sort
                                )
                              }
                            },
                            model: {
                              value: scope.row.shop_sort,
                              callback: function($$v) {
                                _vm.$set(scope.row, "shop_sort", $$v)
                              },
                              expression: "scope.row.shop_sort"
                            }
                          })
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: { prop: "id", label: "ID", width: "70", align: "center" }
            }),
            _c("el-table-column", {
              attrs: { label: "图片", width: "90", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-image",
                        {
                          staticStyle: { width: "50px", height: "50px" },
                          attrs: {
                            src: scope.row.imgurl
                              ? _vm.$globalObj.imgBaseUrl + scope.row.imgurl
                              : ""
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "image-slot",
                              attrs: { slot: "error" },
                              slot: "error"
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-picture-outline"
                              })
                            ]
                          )
                        ]
                      )
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: { prop: "title", label: "商品名称", align: "center" }
            }),
            _c("el-table-column", {
              attrs: { prop: "name", label: "商品分类", align: "center" }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "create_time",
                label: "添加时间",
                align: "center",
                width: "100"
              }
            }),
            _c("el-table-column", {
              attrs: { prop: "status", label: "状态", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-tag",
                        {
                          attrs: {
                            type:
                              scope.row.status == 1
                                ? "success"
                                : scope.row.status == 3
                                ? "warning"
                                : "danger"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              scope.row.status == 1
                                ? "已上架"
                                : scope.row.status == 2
                                ? "已下架"
                                : scope.row.status == 3
                                ? "申请下架"
                                : scope.row.status == 4
                                ? "申请下架拒绝"
                                : "违规下架"
                            )
                          )
                        ]
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        ),
        _c("custom-page", {
          ref: "customPage",
          attrs: { total: _vm.total },
          on: { getList: _vm.getList }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticClass: "color-666", staticStyle: { "line-height": "28px" } },
      [
        _vm._v("\n          1. 商品排序只显示正常上架的商品。"),
        _c("br"),
        _vm._v(
          "\n          2. 店铺页面商品根据排序数值进行排序，数值越小商品展示位置越靠前。"
        ),
        _c("br"),
        _vm._v(
          "\n          3. 同数值排序时，添加时间越早商品展示位置越靠前。\n        "
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }