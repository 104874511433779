"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
var _goods = require("@/api/goods");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ['currentMeal', 'editId', 'goodInfo'],
  data: function data() {
    return {
      isCopy: 0,
      submitForm: {
        sort: 0,
        setmeal_id: 0,
        title: '',
        lease_set: 1,
        is_huabei: 2,
        quantity: 99999,
        foregift: '',
        is_buy_out: 1,
        meal_status: 1,
        price: 0,
        start_date: '',
        methods: '',
        specs_name: '规格',
        specs_value: '规格',
        price_rate: 1.3,
        reference_price: 0,
        send_out: '2',
        // 是否包邮 1-包邮, 2-不包邮
        transfer_cost: 0,
        process_cost: 0
      },
      initData: {
        send_out: {} // 寄出
      },

      defaultPriceRate: 1.3,
      // priceRate: 0,
      // referencePrice: 0,
      rules: {
        // specs_name: [{ required: true, message: '请输入规格名称', trigger: 'blur' }],
        // specs_value: [{ required: true, message: '请输入规格参数', trigger: 'blur' }],
        // methods: [{ required: true, message: '请输入套餐方式', trigger: 'blur' }],
        title: [{
          required: true,
          message: '请输入套餐名字',
          trigger: 'blur'
        }],
        quantity: [{
          required: true,
          message: '请输入库存数量',
          trigger: 'blur'
        }],
        // foregift: [{ required: true, message: '请输入押金', trigger: 'blur' }],
        is_buy_out: [{
          required: true,
          message: '请选择是否买断',
          trigger: 'change'
        }],
        price: [{
          required: true,
          message: '请输入销售价格',
          trigger: 'blur'
        }],
        transfer_cost: [{
          required: true,
          message: '请输入运费',
          trigger: 'blur'
        }],
        process_cost: [{
          required: true,
          message: '请输入手工费',
          trigger: 'blur'
        }]
      },
      startDateSelect: [],
      rentType: 0,
      goodsId: 0,
      status: 2,
      hideAuditBtn: false
    };
  },
  watch: {
    currentMeal: function currentMeal(val, oldVal) {
      this.getData();
    },
    'submitForm.send_out': {
      handler: function handler(val) {
        if (val == 1) {
          this.submitForm.transfer_cost = 0;
        }
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.init();
    this.$nextTick(function () {
      if (_this.$route.name === 'goodsMeal') {
        _this.goodsId = _this.$route.params.goodsId;
        if (_this.editId > 0) {
          _this.isCopy = _this.$route.params.isCopy ? _this.$route.params.isCopy : 0;
          _this.getInfo();
        } else {
          _this.rentType = _this.$route.params.pact_type;
        }
      } else {
        _this.rentType = JSON.parse(sessionStorage.getItem('baseInfo')) ? JSON.parse(sessionStorage.getItem('baseInfo')).formData.pact_type : 0;
        _this.goodsId = sessionStorage.getItem('goodsId') || '';
        _this.getData();
      }
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (this.$route.name === 'goodsAdd') {
      this.saveStorage(this.currentMeal);
    }
  },
  methods: {
    init: function init() {
      var _this2 = this;
      (0, _goods.getGoodsRentData)().then(function (res) {
        if (res) {
          _this2.initData = res;
        }
      });
    },
    getData: function getData() {
      if (sessionStorage.getItem('mealInfo')) {
        var list = JSON.parse(sessionStorage.getItem('mealInfo'));
        if (list[this.currentMeal - 1]) {
          var data = list[this.currentMeal - 1];
          console.log('data.formData', data.formData);
          this.submitForm = (0, _objectSpread2.default)({}, data.formData);
        }
      }
      this.status = parseInt(sessionStorage.getItem('goodsStatus'));
    },
    getInfo: function getInfo() {
      var _this3 = this;
      (0, _goods.getGoodsSetmealInfo)(this.editId).then(function (res) {
        _this3.hideAuditBtn = res.ischeck == 3;
        for (var key in _this3.submitForm) {
          if (key === 'huabei_data') {
            if (res.is_huabei == 1) {
              _this3.submitForm[key] = res[key];
            }
          } else if (key === 'setmeal_id') {
            _this3.submitForm[key] = res.id;
          } else {
            _this3.submitForm[key] = res[key];
          }
        }
        _this3.rentType = res.pact_type;
        _this3.status = res.goods_status;
      });
    },
    subTotal: function subTotal(items) {
      return items.reduce(function (acc, ele) {
        console.log(ele.lease_price);
        return acc + parseFloat(ele.lease_price);
      }, 0);
    },
    chkPriceRate: function chkPriceRate() {
      var value = this.submitForm.price_rate;
      value = parseFloat(value);
      value = isNaN(value) || value <= 0 ? this.defaultPriceRate : value;
      this.submitForm.price_rate = value.toFixed(2);
    },
    // 提交审核
    submitAudit: function submitAudit() {
      var _this4 = this;
      (0, _goods.goodsSubmitReview)(this.goodsId).then(function (res) {
        _this4.$router.push({
          name: 'goodsSuccess',
          params: {
            goodsId: _this4.goodsId,
            isEdit: true
          }
        });
      });
    },
    // 保存草稿
    saveToDraft: function saveToDraft() {
      var _this5 = this;
      if (!this.goodsId) {
        this.$message({
          message: '请先提交商品基础信息',
          type: 'warning'
        });
        return;
      }
      if (!this.submitForm.title) {
        this.$message({
          message: '套餐名字不能为空',
          type: 'warning'
        });
        return;
      }
      var params = (0, _objectSpread2.default)((0, _objectSpread2.default)({
        goods_id: this.goodsId,
        spec: []
      }, this.submitForm), {}, {
        status: this.status,
        operation_type: 1
      });
      // 添加/编辑套餐
      (0, _goods.editGoodsSetmeal)(params).then(function (res) {
        _this5.submitForm.setmeal_id = res.setmeal_id;
        _this5.saveStorage();
        _this5.$message({
          message: '操作成功',
          type: 'success'
        });
      });
    },
    // 返回上一标签
    returnTab: function returnTab() {
      if (this.currentMeal == 1) {
        this.$emit('setTab', 'base');
      } else {
        this.$emit('setTab', 'meal' + (this.currentMeal - 1));
      }
    },
    save: function save() {
      var _this6 = this;
      // this.$confirm('商品信息变更需要平台审核，审核时间1个工作日，确定修改商品？', '修改商品', {
      // 	confirmButtonText: '确定',
      // 	cancelButtonText: '取消',
      // }).then(() => {
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          _this6.status = 2; // 商品编辑之后,状态自动下架
          var params = (0, _objectSpread2.default)((0, _objectSpread2.default)({
            goods_id: _this6.goodsId,
            spec: []
          }, _this6.submitForm), {}, {
            status: _this6.status,
            operation_type: 3
          });

          // this.setPriceRate()
          if (_this6.isCopy == 0) {
            (0, _goods.editGoodsSetmeal)(params).then(function (res) {
              _this6.$message({
                message: '操作成功',
                type: 'success'
              });
              _this6.$router.back();
            });
          }
        } else {
          _this6.$message({
            message: '请检查表单错误',
            type: 'error'
          });
          return false;
        }
      });
      // }).catch(() => {});
    },
    // 表单校验
    validCheck: function validCheck() {},
    // 添加套餐
    addMeal: function addMeal(isPublish) {
      var _this7 = this;
      // 判断是否有商品id
      if (!this.goodsId) {
        this.$message({
          message: '请先提交商品基础信息',
          type: 'warning'
        });
        return;
      }
      // 提交当前表单
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          var params = (0, _objectSpread2.default)((0, _objectSpread2.default)({
            goods_id: _this7.goodsId,
            spec: []
          }, _this7.submitForm), {}, {
            status: _this7.status,
            operation_type: isPublish ? 2 : 3
          });
          // this.setPriceRate()
          (0, _goods.editGoodsSetmeal)(params).then(function (res) {
            if (isPublish) {
              _this7.$router.push({
                name: 'goodsSuccess',
                params: {
                  goodsId: _this7.goodsId
                }
              });
            } else {
              _this7.submitForm.setmeal_id = res.setmeal_id;
              _this7.saveStorage();
              _this7.$emit('addTab');
            }
          });
        } else {
          _this7.$message({
            message: '请检查表单错误',
            type: 'error'
          });
          return false;
        }
      });
    },
    // 保留两位小数
    toFix2: function toFix2(calVal) {
      return (Math.round(calVal * 100) / 100).toFixed(2);
    },
    changeNumberInput: function changeNumberInput(obj, key) {
      var pattern = /^[1-9][0-9]*$/; // 正整数的正则表达式
      // 不符合正整数时
      if (!pattern.test(obj[key])) {
        // input 框绑定的内容为空
        obj[key] = '';
      }
    },
    changeMoneyInput: function changeMoneyInput(obj, key) {
      var pattern = /^[0-9]+([.]{1}[0-9]{1,2})?$/; // 正数的正则表达式
      // 不符合正数时
      if (!pattern.test(obj[key])) {
        // input 框绑定的内容为空
        obj[key] = '';
      }
    },
    saveStorage: function saveStorage(index) {
      var params = {
        formData: this.submitForm,
        monthList: this.monthList,
        dayList: this.dayList,
        customList: this.customList
      };
      var list = sessionStorage.getItem('mealInfo') ? JSON.parse(sessionStorage.getItem('mealInfo')) : [];
      list[index - 1] = (0, _objectSpread2.default)({}, params);
      sessionStorage.setItem('mealInfo', JSON.stringify(list));
      sessionStorage.setItem('goodsStatus', this.status);
    }
  }
};
exports.default = _default;