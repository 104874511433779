var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "meal_form",
          attrs: {
            model: _vm.submitForm,
            rules: _vm.rules,
            "label-width": "200px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "排序" } },
            [
              _c("el-input", {
                staticStyle: { width: "100%" },
                attrs: {
                  maxlength: "1",
                  "show-word-limit": "",
                  placeholder: "排序"
                },
                model: {
                  value: _vm.submitForm.sort,
                  callback: function($$v) {
                    _vm.$set(_vm.submitForm, "sort", $$v)
                  },
                  expression: "submitForm.sort"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "套餐名字", prop: "title" } },
            [
              _c("el-input", {
                staticStyle: { width: "100%" },
                attrs: {
                  maxlength: "50",
                  "show-word-limit": "",
                  placeholder: "请输入套餐名字，如租完即送,必填"
                },
                model: {
                  value: _vm.submitForm.title,
                  callback: function($$v) {
                    _vm.$set(_vm.submitForm, "title", $$v)
                  },
                  expression: "submitForm.title"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "数量", prop: "quantity" } },
            [
              _c("el-input", {
                attrs: { placeholder: "库存数量", clearable: "" },
                on: {
                  input: function(val) {
                    return _vm.changeNumberInput(_vm.submitForm, "quantity")
                  }
                },
                model: {
                  value: _vm.submitForm.quantity,
                  callback: function($$v) {
                    _vm.$set(_vm.submitForm, "quantity", $$v)
                  },
                  expression: "submitForm.quantity"
                }
              })
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "销售价格", prop: "price" } }, [
            _c(
              "div",
              [
                _c("el-input", {
                  staticClass: "suffix_input",
                  attrs: { placeholder: "请输入销售价格", clearable: "" },
                  on: {
                    blur: function($event) {
                      return _vm.changeMoneyInput(_vm.submitForm, "price")
                    }
                  },
                  model: {
                    value: _vm.submitForm.price,
                    callback: function($$v) {
                      _vm.$set(_vm.submitForm, "price", $$v)
                    },
                    expression: "submitForm.price"
                  }
                }),
                _c("span", { staticClass: "suffix" }, [_vm._v("元")])
              ],
              1
            )
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "手工费", prop: "process_cost" } },
            [
              _c(
                "div",
                [
                  _c("el-input", {
                    staticClass: "suffix_input",
                    attrs: { placeholder: "请输入手工费", clearable: "" },
                    on: {
                      blur: function($event) {
                        return _vm.changeMoneyInput(
                          _vm.submitForm,
                          "process_cost"
                        )
                      }
                    },
                    model: {
                      value: _vm.submitForm.process_cost,
                      callback: function($$v) {
                        _vm.$set(_vm.submitForm, "process_cost", $$v)
                      },
                      expression: "submitForm.process_cost"
                    }
                  }),
                  _c("span", { staticClass: "suffix" }, [_vm._v("元")])
                ],
                1
              )
            ]
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { "margin-bottom": "0px" },
              attrs: { label: "配送方式", prop: "send_out" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "flex align-center",
                  staticStyle: { height: "40px" }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.submitForm.send_out,
                        callback: function($$v) {
                          _vm.$set(_vm.submitForm, "send_out", $$v)
                        },
                        expression: "submitForm.send_out"
                      }
                    },
                    _vm._l(_vm.initData.send_out, function(val, key) {
                      return _c(
                        "el-radio",
                        { key: key, attrs: { label: key } },
                        [_vm._v(_vm._s(val))]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ]
          ),
          _vm.submitForm.send_out == 2
            ? _c(
                "el-form-item",
                { attrs: { label: "运费", prop: "transfer_cost" } },
                [
                  _c(
                    "div",
                    [
                      _c("el-input", {
                        staticClass: "suffix_input",
                        attrs: { placeholder: "请输入运费", clearable: "" },
                        on: {
                          blur: function($event) {
                            return _vm.changeMoneyInput(
                              _vm.submitForm,
                              "transfer_cost"
                            )
                          }
                        },
                        model: {
                          value: _vm.submitForm.transfer_cost,
                          callback: function($$v) {
                            _vm.$set(_vm.submitForm, "transfer_cost", $$v)
                          },
                          expression: "submitForm.transfer_cost"
                        }
                      }),
                      _c("span", { staticClass: "suffix" }, [_vm._v("元")])
                    ],
                    1
                  )
                ]
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "是否开启", prop: "meal_status" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.submitForm.meal_status,
                    callback: function($$v) {
                      _vm.$set(_vm.submitForm, "meal_status", $$v)
                    },
                    expression: "submitForm.meal_status"
                  }
                },
                [_vm._v("是")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: 0 },
                  model: {
                    value: _vm.submitForm.meal_status,
                    callback: function($$v) {
                      _vm.$set(_vm.submitForm, "meal_status", $$v)
                    },
                    expression: "submitForm.meal_status"
                  }
                },
                [_vm._v("否")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "fix-footer-btns" }, [
        _c(
          "div",
          [
            _vm.$route.name === "goodsAdd"
              ? [
                  _vm.$hasMethod("#saveMealDraft")
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: { click: _vm.saveToDraft }
                        },
                        [_vm._v("保存草稿")]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      staticClass: "blue_border",
                      attrs: { size: "small" },
                      on: { click: _vm.returnTab }
                    },
                    [_vm._v("返回")]
                  ),
                  _vm.$hasMethod("#addMeal")
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.addMeal()
                            }
                          }
                        },
                        [_vm._v("添加套餐")]
                      )
                    : _vm._e(),
                  _vm.$hasMethod("#publish")
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "warning" },
                          on: {
                            click: function($event) {
                              return _vm.addMeal(true)
                            }
                          }
                        },
                        [_vm._v("发布商品")]
                      )
                    : _vm._e()
                ]
              : [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.$router.go(-1)
                        }
                      }
                    },
                    [_vm._v("返回")]
                  ),
                  _vm.$hasMethod("#saveMeal")
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.save }
                        },
                        [_vm._v("保存")]
                      )
                    : _vm._e()
                ]
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }