var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "rate_info footer-page"
    },
    [
      _vm._m(0),
      _c(
        "div",
        {},
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.delAllRedis()
                }
              }
            },
            [_vm._v("清除所有缓存")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "label_title" }, [
      _c("p", [_vm._v("清除缓存")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }