"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _finance = require("@/api/finance");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ['viewItem'],
  data: function data() {
    return {
      activeName: 'deduct',
      dialogVisible: false,
      tableData: [],
      smsData: []
    };
  },
  methods: {
    handleClick: function handleClick(tab, event) {
      this.activeName = tab.name;
      if (this.activeName === 'deduct') {
        this.getDeduct();
      } else {
        this.getSmsLog();
      }
    },
    getSmsLog: function getSmsLog() {
      var _this = this;
      (0, _finance.getOverdueSmsLog)(this.viewItem.lease_id).then(function (res) {
        _this.smsData = res || [];
      });
    },
    getDeduct: function getDeduct() {
      var _this2 = this;
      var _this$viewItem = this.viewItem,
        order_id = _this$viewItem.order_id,
        lease_id = _this$viewItem.lease_id;
      (0, _finance.getLeaseFlow)({
        orderId: order_id,
        leaseId: lease_id
      }).then(function (res) {
        _this2.tableData = res || [];
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.getDeduct();
      } else {
        this.activeName = 'deduct';
      }
    }
  }
};
exports.default = _default;