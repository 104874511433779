"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _orderEC = require("@/api/orderEC");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ['editItem', 'selfItem'],
  data: function data() {
    return {
      form: {
        id: 0,
        order_id: 0,
        name: "",
        relation: "",
        phone: "",
        is_wechar: 0,
        is_alipay: 0
      },
      tradeNo: '',
      rules: {
        name: [{
          required: true,
          message: '名字不能为空'
        }],
        relation: [{
          required: true,
          message: '关系不能为空'
        }],
        phone: [{
          required: true,
          message: '联系方式'
        }]
      },
      dialogVisible: false
    };
  },
  methods: {
    submit: function submit() {
      var _this = this;
      if (this.selectedId != null) {
        this.form.id = this.selectedId;
      }
      console.log(this.form);
      (0, _orderEC.saveOrderEmeCon)((0, _objectSpread2.default)({}, this.form)).then(function (res) {
        _this.dialogVisible = false;
        _this.$emit('success', true);
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.tradeNo = this.editItem.trade_no;
        if (this.selfItem != null) {
          this.form = (0, _objectSpread2.default)({}, this.selfItem);
        } else {
          this.form = this.$options.data().form;
        }
        this.form.order_id = this.editItem.id;
      } else {
        this.$refs['ruleForm'].resetFields();
      }
    }
  }
};
exports.default = _default;