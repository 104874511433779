"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    total: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      page: 1,
      pageSize: 10,
      pageList: [5, 10, 20, 50, 100]
    };
  },
  computed: {
    pageTotal: function pageTotal() {
      var num = Math.ceil(this.total / this.pageSize);
      return num > 0 ? num : 1;
    },
    layout: function layout() {
      if (this.total === 0) {
        return "prev, pager, next";
      }
      if (this.pageTotal > 1) {
        return "sizes, prev, pager, next, jumper";
      }
      return "sizes, prev, pager, next";
    }
  },
  methods: {
    changePageSize: function changePageSize(val) {
      this.pageSize = val;
      if (this.pageList.indexOf(val) === -1) {
        this.pageList.unshift(val);
      }
    },
    goEnd: function goEnd() {
      this.page = this.pageTotal;
      this.$emit("getList");
    },
    goFirst: function goFirst() {
      this.page = 1;
      this.$emit("getList");
    },
    handleSizeChange: function handleSizeChange(val) {
      this.page = 1;
      this.pageSize = val;
      this.$emit("getList");
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.page = val;
      this.$emit("getList");
    }
  }
};
exports.default = _default;