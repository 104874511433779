"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addCategory = addCategory;
exports.delMarketInfo = delMarketInfo;
exports.delTendencyInfo = delTendencyInfo;
exports.getAllGoldTendencyByPage = getAllGoldTendencyByPage;
exports.getCategoryInfo = getCategoryInfo;
exports.getGoldTendency = getGoldTendency;
exports.getMarketDynamicsList = getMarketDynamicsList;
exports.getSonCategoryList = getSonCategoryList;
exports.updateGoldTendency = updateGoldTendency;
exports.updateMarketDynamicsList = updateMarketDynamicsList;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
// 获取黄金市场行情列表
function getMarketDynamicsList() {
  return _fetch.default.get('/Supplier/Gold/getMarketDynamicsList');
}
// 更新或添加
function updateMarketDynamicsList(params) {
  return _fetch.default.post('/Supplier/Gold/updateMarketDynamicsList', params);
}
// 删除
function delMarketInfo(id) {
  return _fetch.default.post('/Supplier/Gold/delMarketInfo/' + id);
}

// 获取黄金价格走势
function getGoldTendency() {
  return _fetch.default.get('/Supplier/Gold/getMarketDynamicsList');
}
// 分页获取每日金价走势
function getAllGoldTendencyByPage(params) {
  return _fetch.default.post('/Supplier/Gold/getAllGoldTendencyByPage', params);
}
// 更新或添加金价走势
function updateGoldTendency(params) {
  return _fetch.default.post('/Supplier/Gold/updateGoldTendency', params);
}
// 删除某一天金价走势
function delTendencyInfo(id) {
  return _fetch.default.post('/Supplier/Gold/delTendencyInfo/' + id);
}

// 获取该商户的品类信息
function getCategoryInfo() {
  return _fetch.default.get('/Supplier/CategoryManagement/categoryInfoByMemberid');
}
// 申请品类
function addCategory(params) {
  return _fetch.default.post('/Supplier/CategoryManagement/addCategoryUser', params, true);
}

// 获取所有二级分类
function getSonCategoryList(params) {
  return _fetch.default.post('/Supplier/CategoryManagement/getSonCategoryList', params);
}