var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.activeTab != 3
        ? _c(
            "div",
            { staticClass: "warn-box" },
            [
              _c("svg-icon", {
                staticStyle: { "margin-right": "17px" },
                attrs: { "icon-class": "warn", size: 20 }
              }),
              _c("p", [
                _vm._v(
                  " 商品图片和文字描述不能存在手机号和任何二维码，不得存在微信字样。"
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.$route.name === "goodsBaseEdit"
        ? [_c("base-info", { attrs: { "edit-id": _vm.goodsId } })]
        : _vm._e(),
      _vm.$route.name === "goodsMeal" && _vm.goodInfo
        ? [
            _c("meal-info", {
              attrs: { editId: _vm.mealId, goodInfo: _vm.goodInfo }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }