"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es6.number.constructor");
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
var _linkDialog = _interopRequireDefault(require("./linkDialog"));
var _components$props$com;
var _default = (_components$props$com = {
  components: {
    cropUpload: _cropUpload.default,
    linkDialog: _linkDialog.default
  },
  props: {
    itemIndex: {
      type: Number
    },
    showTool: {
      type: Boolean,
      default: false
    },
    formData: {
      type: Object,
      default: {}
    },
    imgWidth: {
      default: 200
    },
    imgHeight: {
      default: 200
    },
    type: {
      // type: String
    }
  },
  computed: {
    linkVal: function linkVal() {
      return this.formData.jumpType == 1 ? this.formData.jumpUrl : this.formData.goods.title;
    }
  },
  data: function data() {
    return {
      editItem: null,
      photoWidth: 359
    };
  }
}, (0, _defineProperty2.default)(_components$props$com, "computed", {
  photoHeight: function photoHeight() {
    var height = this.imgHeight && this.imgWidth ? 359 * this.imgHeight / this.imgWidth : this.photoWidth;
    return height;
  }
}), (0, _defineProperty2.default)(_components$props$com, "methods", {
  deleteLink: function deleteLink() {
    this.formData.jumpUrl = '';
    this.formData.goods = {};
  },
  setVal: function setVal(_ref) {
    var jumpType = _ref.jumpType,
      jumpUrl = _ref.jumpUrl,
      goods = _ref.goods;
    this.formData.jumpType = jumpType;
    this.formData.jumpUrl = jumpUrl;
    this.formData.goods = jumpType == 2 ? goods : {};
  },
  addLink: function addLink() {
    this.editItem = (0, _objectSpread2.default)({}, this.formData);
    this.$refs.linkDialog.dialogVisible = true;
  }
}), _components$props$com);
exports.default = _default;