var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "base_form",
          attrs: {
            model: _vm.submitForm,
            rules: _vm.rules,
            "label-width": "200px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "商品品类", prop: "category_id" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择商品品类" },
                  on: { change: _vm.categoryChange },
                  model: {
                    value: _vm.submitForm.category_id,
                    callback: function($$v) {
                      _vm.$set(_vm.submitForm, "category_id", $$v)
                    },
                    expression: "submitForm.category_id"
                  }
                },
                _vm._l(_vm.categoryList, function(item) {
                  return _c("el-option", {
                    key: item.category_id,
                    attrs: { label: item.name, value: item.category_id }
                  })
                }),
                1
              ),
              _c(
                "p",
                {
                  staticClass: "color-999 inline-block",
                  staticStyle: { "margin-left": "15px" }
                },
                [
                  _vm._v(
                    "\n                    没有所需品类？点击立即\n                    "
                  ),
                  _vm.$hasMethod("#apply")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.$router.push({ name: "goodsCategory" })
                            }
                          }
                        },
                        [_vm._v("添加品类")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品名称", prop: "goods_name" } },
            [
              _c("el-input", {
                staticStyle: { width: "100%" },
                attrs: {
                  maxlength: "30",
                  "show-word-limit": "",
                  placeholder: "请输入商品名称"
                },
                model: {
                  value: _vm.submitForm.goods_name,
                  callback: function($$v) {
                    _vm.$set(_vm.submitForm, "goods_name", $$v)
                  },
                  expression: "submitForm.goods_name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "排序" } },
            [
              _c("el-input", {
                staticStyle: { width: "100%" },
                attrs: {
                  maxlength: "50",
                  "show-word-limit": "",
                  placeholder: "请输入排序号"
                },
                model: {
                  value: _vm.submitForm.goods_sort,
                  callback: function($$v) {
                    _vm.$set(_vm.submitForm, "goods_sort", $$v)
                  },
                  expression: "submitForm.goods_sort"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "推广关键字" } },
            [
              _c("el-input", {
                staticStyle: { width: "100%" },
                attrs: {
                  maxlength: "50",
                  "show-word-limit": "",
                  placeholder: "请输入推广关键字，以中文逗号隔开"
                },
                model: {
                  value: _vm.submitForm.spread_title,
                  callback: function($$v) {
                    _vm.$set(_vm.submitForm, "spread_title", $$v)
                  },
                  expression: "submitForm.spread_title"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品标签" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    multiple: "",
                    filterable: "",
                    "allow-create": "",
                    "default-first-option": "",
                    placeholder: "请输入/选择标签"
                  },
                  model: {
                    value: _vm.submitForm.rent_label,
                    callback: function($$v) {
                      _vm.$set(_vm.submitForm, "rent_label", $$v)
                    },
                    expression: "submitForm.rent_label"
                  }
                },
                _vm._l(_vm.initData.rent_label, function(item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品封面", prop: "imgurl" } },
            [
              _c("crop-upload", {
                ref: "coverUpload",
                attrs: {
                  "show-type": "photo",
                  fixScale: true,
                  "is-square": true,
                  "always-crop": true,
                  fixedBox: false,
                  "init-val": _vm.submitForm.imgurl,
                  "wrapper-width": "120px",
                  "wrapper-height": "120px",
                  "photo-width": 120,
                  "photo-height": 120,
                  autoCropWidth: 150,
                  autoCropHeight: 150,
                  cropperWidth: "400px",
                  cropperHeight: "400px",
                  dialogWidth: "600px",
                  "min-width": 750,
                  "min-height": 750,
                  enlarge: 5
                },
                on: { setVal: _vm.setUrl }
              }),
              _c(
                "div",
                { staticClass: "color-999" },
                [
                  _vm._v(
                    "\n                    注：尺寸为750*750px及以上正方形，图片要求为纯白色底图\n                    "
                  ),
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "right",
                        width: "400",
                        trigger: "hover"
                      }
                    },
                    [
                      _c("div", { staticClass: "img_exp" }, [
                        _c("h5", [_vm._v("商品封面图片示例")]),
                        _c("div", { staticClass: "exp_wrap" }, [
                          _c(
                            "p",
                            { staticClass: "item" },
                            [
                              _c("el-image", {
                                staticStyle: {
                                  width: "100px",
                                  height: "100px"
                                },
                                attrs: {
                                  src: require("@/assets/images/exp1.png")
                                }
                              }),
                              _c("span", [
                                _c("i", {
                                  staticClass: "green-font el-icon-success"
                                }),
                                _vm._v(
                                  "\n                                        推荐\n                                    "
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "p",
                            { staticClass: "item" },
                            [
                              _c("el-image", {
                                staticStyle: {
                                  width: "100px",
                                  height: "100px"
                                },
                                attrs: {
                                  src: require("@/assets/images/exp2.png")
                                }
                              }),
                              _c("span", [
                                _c("i", {
                                  staticClass: "red-font el-icon-error"
                                }),
                                _vm._v(
                                  "\n                                        不推荐\n                                    "
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "p",
                            { staticClass: "item" },
                            [
                              _c("el-image", {
                                staticStyle: {
                                  width: "100px",
                                  height: "100px"
                                },
                                attrs: {
                                  src: require("@/assets/images/exp3.png")
                                }
                              }),
                              _c("span", [
                                _c("i", {
                                  staticClass: "red-font el-icon-error"
                                }),
                                _vm._v(
                                  "\n                                        不推荐\n                                    "
                                )
                              ])
                            ],
                            1
                          )
                        ]),
                        _c("p", { staticClass: "red-font" }, [
                          _vm._v(
                            "\n                                注：图片要求750*750像素以上的正方形图片；\n                                "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n                                注：上传封面图片要求纯白色底图；\n                                "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n                                注：精品纯白色底图可上首页推荐位！\n                            "
                          )
                        ])
                      ]),
                      _c(
                        "el-button",
                        {
                          attrs: { slot: "reference", type: "text" },
                          slot: "reference"
                        },
                        [_vm._v("图片示例>")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品图片", prop: "carousel_img" } },
            [
              _c("crop-upload", {
                ref: "cropUpload",
                attrs: {
                  sort: true,
                  multiple: true,
                  "always-crop": true,
                  "show-type": "photo",
                  fixScale: true,
                  fixedBox: false,
                  "is-square": true,
                  "init-val": _vm.submitForm.carousel_img,
                  "wrapper-width": "120px",
                  "wrapper-height": "120px",
                  autoCropWidth: 150,
                  autoCropHeight: 150,
                  cropperWidth: "400px",
                  cropperHeight: "400px",
                  dialogWidth: "600px",
                  enlarge: 5,
                  maxNum: 8,
                  "photo-width": 120,
                  "photo-height": 120,
                  "min-width": 750,
                  "min-height": 750
                },
                on: { setVal: _vm.bannerChange }
              })
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "商品参数" } }, [
            _c(
              "div",
              { staticClass: "editor_wrap" },
              [
                _c("text-editor", {
                  model: {
                    value: _vm.submitForm.goods_parameter,
                    callback: function($$v) {
                      _vm.$set(_vm.submitForm, "goods_parameter", $$v)
                    },
                    expression: "submitForm.goods_parameter"
                  }
                })
              ],
              1
            )
          ]),
          _c("el-form-item", { attrs: { label: "商品详情" } }, [
            _c(
              "div",
              { staticClass: "editor_wrap" },
              [
                _c("text-editor", {
                  model: {
                    value: _vm.submitForm.goods_content,
                    callback: function($$v) {
                      _vm.$set(_vm.submitForm, "goods_content", $$v)
                    },
                    expression: "submitForm.goods_content"
                  }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _c("div", { staticClass: "fix-footer-btns" }, [
        _c(
          "div",
          [
            !_vm.editId
              ? [
                  _vm.$hasMethod("#saveBaseDraft")
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: { click: _vm.saveToDraft }
                        },
                        [_vm._v("保存草稿")]
                      )
                    : _vm._e(),
                  _vm.$hasMethod("#addBase")
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.nextStep }
                        },
                        [_vm._v("下一步")]
                      )
                    : _vm._e()
                ]
              : [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.$router.go(-1)
                        }
                      }
                    },
                    [_vm._v("返回")]
                  ),
                  _vm.$hasMethod("#editBase")
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.save }
                        },
                        [_vm._v("保存")]
                      )
                    : _vm._e()
                ]
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }