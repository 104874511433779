var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "风险地区", visible: _vm.dialogVisible, width: "800px" },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: { data: _vm.tableData, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", prop: "id", label: "id", width: "60" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "risk_type", label: "风险等级" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.risk_type == 1
                      ? _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v("中风险")
                        ])
                      : _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("高风险")
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "province", label: "省" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "city", label: "市" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "district", label: "区" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "street", label: "地址" }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("关闭")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }