"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.adminCheckPass = adminCheckPass;
exports.adminCheckRefuse = adminCheckRefuse;
exports.orderRefundDetail = orderRefundDetail;
exports.orderRefundInfo = orderRefundInfo;
exports.orderRefundList = orderRefundList;
exports.orderRefundTransData = orderRefundTransData;
exports.shopCheckPass = shopCheckPass;
exports.shopCheckRefuse = shopCheckRefuse;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
// 订单退款列表
function orderRefundList(params) {
  return _fetch.default.post("/Supplier/OrderRefund/orderRefundList", params);
}
// 订单退款详情
function orderRefundInfo(id) {
  return _fetch.default.get("/Supplier/OrderRefund/orderRefundInfo", {
    order_id: id
  });
}
// 退款订单补足金额明细
function orderRefundTransData(id) {
  return _fetch.default.get("/Supplier/OrderRefund/orderRefundTransData", {
    refund_id: id
  });
}
// 退款订单明细查看详情
function orderRefundDetail(id) {
  return _fetch.default.get("/Supplier/OrderRefund/orderRefundDetail", {
    refund_id: id
  });
}
// 商户审核通过
function shopCheckPass(id) {
  return _fetch.default.post("/Supplier/OrderRefund/shopCheckPass", {
    refund_id: id
  }, true);
}
// 商户审核拒绝
function shopCheckRefuse(params) {
  return _fetch.default.post("/Supplier/OrderRefund/shopCheckRefuse", params, true);
}
// 平台审核通过
function adminCheckPass(id) {
  return _fetch.default.post("/Supplier/OrderRefund/adminCheckPass", {
    refund_id: id
  }, true);
}
// 平台审核拒绝
function adminCheckRefuse(params) {
  return _fetch.default.post("/Supplier/OrderRefund/adminCheckRefuse", params, true);
}