"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addHomeModule = addHomeModule;
exports.addOrEditCategory = addOrEditCategory;
exports.addOrEditH5HomeCategory = addOrEditH5HomeCategory;
exports.addOrEditH5HomeSecondCategory = addOrEditH5HomeSecondCategory;
exports.addOrEditH5HomeShow = addOrEditH5HomeShow;
exports.addOrEditInfoConfig = addOrEditInfoConfig;
exports.addSecondCategory = addSecondCategory;
exports.delAllRedis = delAllRedis;
exports.delH5HomeCategory = delH5HomeCategory;
exports.delH5HomeSecondCategory = delH5HomeSecondCategory;
exports.delH5HomeShow = delH5HomeShow;
exports.delInfoConfig = delInfoConfig;
exports.deleteCategory = deleteCategory;
exports.deleteModule = deleteModule;
exports.editArticleTypeStatus = editArticleTypeStatus;
exports.editCategoryStatus = editCategoryStatus;
exports.editModuleStatus = editModuleStatus;
exports.getCategortList = getCategortList;
exports.getHomeCategoryById = getHomeCategoryById;
exports.getHomeModule = getHomeModule;
exports.getHomeModuleById = getHomeModuleById;
exports.getInfoConfig = getInfoConfig;
exports.getInfoTypeList = getInfoTypeList;
exports.getSecondCategory = getSecondCategory;
exports.h5HomeCategoryById = h5HomeCategoryById;
exports.h5HomeCategoryList = h5HomeCategoryList;
exports.h5HomeSecondCategoryById = h5HomeSecondCategoryById;
exports.h5HomeShowById = h5HomeShowById;
exports.h5HomeShowList = h5HomeShowList;
exports.sortCategory = sortCategory;
exports.sortModule = sortModule;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
/**
 * 信息配置
 */
// 获取列表
function getInfoTypeList() {
  var param = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _fetch.default.get("/Supplier/InfoConfig/infoConfigList", param);
}
// 添加或编辑信息配置
function addOrEditInfoConfig(params) {
  return _fetch.default.post("/Supplier/InfoConfig/addOrEditInfoConfig", params, true);
}
// 根据id删除信息配置
function delInfoConfig(id) {
  return _fetch.default.post("/Supplier/InfoConfig/delInfoConfig", {
    id: id
  }, true);
}
// 根据id查询信息配置
function getInfoConfig(id) {
  return _fetch.default.get("/Supplier/InfoConfig/infoConfigById", {
    id: id
  });
}
// 编辑文章分类状态
function editArticleTypeStatus(params) {
  return _fetch.default.post("/Supplier/InfoConfig/editArticleTypeStatus", params, true);
}

/**
 * 商城首页品类板块
 */
// 获取列表
function getCategortList(params) {
  return _fetch.default.post("/Supplier/Config/getCategortList", params);
}
// 添加、编辑首页一级分类
function addOrEditCategory(params) {
  return _fetch.default.post("/Supplier/Config/addCategory", params, true);
}
// 品类排序
function sortCategory(params) {
  return _fetch.default.post("/Supplier/Config/sortCategory", params, true);
}
// 修改分类状态（显示、隐藏）
function editCategoryStatus(params) {
  return _fetch.default.post("/Supplier/Config/editCategoryStatus", params, true);
}
// 删除分类
function deleteCategory(params) {
  return _fetch.default.post("/Supplier/Config/deleteCategory", params, true);
}
// 获取二级分类列表
function getSecondCategory(id) {
  return _fetch.default.get("/Supplier/Config/getSecondCategory", {
    cate_id: id
  });
}
// 根据id获取首页一级分类信息
function getHomeCategoryById(id) {
  return _fetch.default.get("/Supplier/Config/getHomeCategoryById", {
    cate_id: id
  });
}
// 添加二级分类
function addSecondCategory(params) {
  return _fetch.default.post("/Supplier/Config/addSecondCategory", params, true);
}

/**
 * 商城首页展示模块
 */
// 获取首页板块信息
function getHomeModule(params) {
  return _fetch.default.post("/Supplier/Config/getHomeModule", params);
}
// 首页板块排序
function sortModule(params) {
  return _fetch.default.post("/Supplier/Config/sortModule", params, true);
}
// 删除首页板块
function deleteModule(id) {
  return _fetch.default.post("/Supplier/Config/deleteModule", {
    module_id: id
  }, true);
}
// 编辑、添加首页模块
function addHomeModule(params) {
  return _fetch.default.post("/Supplier/Config/addHomeModule", params, true);
}
// 通过id获取板块信息
function getHomeModuleById(id) {
  return _fetch.default.get("/Supplier/Config/getHomeModuleById", {
    module_id: id
  });
}
// 更改状态
function editModuleStatus(id) {
  return _fetch.default.post("/Supplier/Config/editModuleStatus", {
    module_id: id
  }, true);
}

/**
 * H5端
 */
// H5端首页品类列表
function h5HomeCategoryList(params) {
  return _fetch.default.post("/Supplier/H5Config/h5HomeCategoryList", params);
}
// 根据id删除H5端首页品类
function delH5HomeCategory(id) {
  return _fetch.default.post("/Supplier/H5Config/delH5HomeCategory", {
    id: id
  }, true);
}
// 根据id删除H5端首页二级品类
function delH5HomeSecondCategory(id) {
  return _fetch.default.post("/Supplier/H5Config/delH5HomeSecondCategory", {
    id: id
  }, true);
}
// 添加或编辑H5端首页品类
function addOrEditH5HomeCategory(params) {
  return _fetch.default.post("/Supplier/H5Config/addOrEditH5HomeCategory", params, true);
}
// 根据id查询H5端首页品类
function h5HomeCategoryById(id) {
  return _fetch.default.get("/Supplier/H5Config/h5HomeCategoryById", {
    id: id
  });
}
// 根据id查询H5端首页二级品类
function h5HomeSecondCategoryById(id) {
  return _fetch.default.get("/Supplier/H5Config/h5HomeSecondCategoryById", {
    id: id
  });
}
// 添加或编辑H5端首页二级品类
function addOrEditH5HomeSecondCategory(params) {
  return _fetch.default.post("/Supplier/H5Config/addOrEditH5HomeSecondCategory", params, true);
}

// H5端首页展示列表
function h5HomeShowList(params) {
  return _fetch.default.post("/Supplier/H5Config/h5HomeShowList", params);
}
// 添加或编辑H5端首页展示
function addOrEditH5HomeShow(params) {
  return _fetch.default.post("/Supplier/H5Config/addOrEditH5HomeShow", params, true);
}
// 根据id查询H5端首页展示
function h5HomeShowById(id) {
  return _fetch.default.get("/Supplier/H5Config/h5HomeShowById", {
    id: id
  });
}
// 根据id删除H5端首页展示
function delH5HomeShow(id) {
  return _fetch.default.post("/Supplier/H5Config/delH5HomeShow", {
    id: id
  }, true);
}

// 删除所有redis缓存
function delAllRedis() {
  return _fetch.default.post("/Supplier/Auth/delAllRedis");
}