"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _order = require("@/api/order");
var _finance = require("@/api/finance");
var _deductLog = _interopRequireDefault(require("@/components/dialogs/deductLog"));
var _setWithhold = _interopRequireDefault(require("./setWithhold"));
var _mayi = require("@/api/mayi");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ['orderId', 'orderStatus'],
  components: {
    deductLog: _deductLog.default,
    setWithhold: _setWithhold.default
  },
  data: function data() {
    return {
      loading: false,
      dialogVisible: false,
      tableData: {
        order: {
          trade_no: ''
        }
      },
      selectedItem: {}
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.getLease();
      }
    }
  },
  methods: {
    getLease: function getLease() {
      var _this = this;
      this.loading = true;
      (0, _order.getLeaseForMayi)(this.orderId).then(function (res) {
        _this.tableData = res;
        _this.loading = false;
      });
    },
    getTagLabel: function getTagLabel(status) {
      var label = '';
      switch (status) {
        case 1:
          label = '已支付';
          break;
        case 2:
          label = '已退款';
          break;
        case 3:
          label = '已买断';
          break;
        case 4:
          label = '已逾期';
          break;
        case 5:
          label = '线下支付';
          break;
        case 6:
          label = '审核中';
          break;
        case 7:
          label = '审核被拒';
          break;
        case 8:
          label = '代扣失败';
          break;
        default:
          label = '未支付';
      }
      return label;
    },
    getTagType: function getTagType(status) {
      var type = 'info';
      if (["扣款成功", "交易成功无法退款"].indexOf(status) > -1) {
        type = 'success';
      } else if (["等待扣款", "代扣准备中", "代扣进行中"].indexOf(status) > -1) {
        type = 'warning';
      } else if (["已退款或一年未成功", "扣款失败", "代扣取消"].indexOf(status) > -1) {
        type = 'danger';
      } else if ([2, 3].indexOf(status) > -1) {
        type = '';
      }
      return type;
    },
    switchChange: function switchChange(row) {
      var _this2 = this;
      (0, _finance.withholdSwitch)({
        orderId: row.order_id,
        leaseId: row.id,
        withholdStatus: row.withhold_status
      }).then(function (res) {
        _this2.$parent.getList(true);
      });
    },
    cancelPay: function cancelPay(orderId, item) {
      var _this3 = this;
      (0, _order.mayicancelPayOne)({
        orderId: orderId,
        outTradeNo: item.outTradeNo,
        lease_num: item.lease_num
      }).then(function (res) {
        _this3.getLease();
      });
      this.selectedItem = (0, _objectSpread2.default)({}, row);
      this.$refs.deductLog.dialogVisible = true;
    },
    // 申请扣款
    openWithhold: function openWithhold(row) {
      this.selectedItem = (0, _objectSpread2.default)({}, row);
      this.$refs.setWithhold.dialogVisible = true;
      return;
      // this.$confirm("您确定要申请扣款吗？", "申请扣款", {
      // 	confirmButtonText: '确定',
      // 	cancelButtonText: '取消'
      // }).then(() => {
      // 	applyWithhold({
      // 		order_id: row.order_id,
      // 		lease_id: row.id,
      // 	}).then(res => {
      // 		this.getLease()
      // 		setTimeout(() => {
      // 			this.$parent.getList(true);
      // 		}, 1000);
      // 	})
      // }).catch(() => {});
    },
    refresh: function refresh() {
      var _this4 = this;
      setTimeout(function () {
        _this4.getLease();
        _this4.$parent.getList(true);
      }, 2000);
    },
    // 发起扣款
    withholdItem: function withholdItem(row) {
      var _this5 = this;
      console.log('row2', row);
      this.$confirm("您确定要发起扣款吗？", "发起扣款", {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _finance.manualWithholding)({
          orderId: row.order_id,
          leaseId: row.id
        }).then(function (res) {
          _this5.getLease();
          setTimeout(function () {
            _this5.$parent.getList(true);
          }, 1000);
        });
      }).catch(function () {});
    },
    // 线下还款
    payOffline: function payOffline(item) {
      var _this6 = this;
      this.$confirm("请确认是否收到账款,您确定要线下还款吗？", "线下还款", {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _finance.setOfflinePay)({
          order_id: item.order_id,
          lease_id: item.id
        }).then(function (res) {
          console.log('getOfflinePayData', res);
          _this6.getLease();
          setTimeout(function () {
            _this6.$parent.getList(true);
          }, 1000);
        });
      }).catch(function () {});
    },
    mayiOrderRentalOne: function mayiOrderRentalOne(row) {
      var _this7 = this;
      this.$confirm("您确定要上链租期信息吗？", "上链信息", {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _mayi.maYiLeaseRentalCreateOne)({
          order_id: row.order_id,
          lease_id: row.id
        }).then(function (res) {
          _this7.getLease();
          setTimeout(function () {
            _this7.$parent.getList(true);
          }, 1000);
        });
      }).catch(function () {});
    }
  }
};
exports.default = _default;