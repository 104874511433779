"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _base = _interopRequireDefault(require("./child/base"));
var _meal = _interopRequireDefault(require("./child/meal"));
var _goods = require("@/api/goods");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    baseInfo: _base.default,
    mealInfo: _meal.default
  },
  props: ['goodsId', 'mealId'],
  data: function data() {
    return {
      activeTab: 0,
      goodInfo: false,
      title: ''
    };
  },
  mounted: function mounted() {
    if (this.mealId > 0) {
      // 编辑信息
      this.title = "\u7F16\u8F91\u5957\u9910";
    } else if (this.$route.name === 'goodsMeal') {
      // 添加信息
      this.title = "添加套餐";
    }
    if (this.goodsId) {
      this.getInfo();
    }
  },
  methods: {
    getInfo: function getInfo() {
      var _this = this;
      (0, _goods.getGoodsData)(this.goodsId).then(function (res) {
        _this.$route.meta.title = "".concat(_this.title, " - ").concat(res.title);
        _this.goodInfo = res || {};
      });
    }
  }
};
exports.default = _default;