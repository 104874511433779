var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "banner_form" },
    [
      _c("div", { staticClass: "flex flex-between-center header" }, [
        _vm.type === "bannerCol"
          ? _c("span", { staticClass: "color-666" }, [
              _vm._v("轮播图" + _vm._s(_vm.itemIndex + 1))
            ])
          : _vm.type === "categoryForm"
          ? _c("span", { staticClass: "color-666" }, [
              _vm._v("\n                一级推荐商品\n            ")
            ])
          : _c("span", { staticClass: "color-666" }, [
              _vm._v("图片" + _vm._s(_vm.itemIndex + 1))
            ]),
        _vm.showTool
          ? _c("p", { staticClass: "tools" }, [
              _c(
                "span",
                {
                  staticClass: "pointer",
                  on: {
                    click: function($event) {
                      return _vm.$emit("upSortItem")
                    }
                  }
                },
                [_c("i", { staticClass: "el-icon-top" })]
              ),
              _c(
                "span",
                {
                  staticClass: "pointer",
                  on: {
                    click: function($event) {
                      return _vm.$emit("downSortItem")
                    }
                  }
                },
                [_c("i", { staticClass: "el-icon-bottom" })]
              ),
              _c(
                "span",
                {
                  staticClass: "pointer",
                  on: {
                    click: function($event) {
                      return _vm.$emit("deleteItem")
                    }
                  }
                },
                [_c("i", { staticClass: "el-icon-delete" })]
              )
            ])
          : _vm._e()
      ]),
      _vm.type === "imageLink"
        ? _c("crop-upload", {
            staticClass: "w-100 banner_img",
            attrs: {
              "show-type": "photo",
              "init-val": _vm.formData.img,
              "wrapper-width": _vm.photoWidth + "px",
              "wrapper-height": _vm.photoHeight + "px",
              "img-width": _vm.imgWidth,
              "img-height": _vm.imgHeight,
              "photo-width": _vm.photoWidth,
              "photo-height": _vm.photoHeight
            },
            on: {
              setVal: function(val) {
                _vm.formData.img = val
              }
            }
          })
        : _vm._e(),
      _vm.type === "categoryForm"
        ? _c("crop-upload", {
            staticClass: "w-100 banner_img",
            attrs: {
              "show-type": "photo",
              "init-val": _vm.formData.img,
              "wrapper-width": "359px",
              "wrapper-height": "121px",
              "img-width": 650,
              "min-height": 220,
              "photo-width": 359,
              "photo-height": 121
            },
            on: {
              setVal: function(val) {
                _vm.formData.img = val
              }
            }
          })
        : _vm._e(),
      _vm.type === "topBanner"
        ? _c("crop-upload", {
            staticClass: "w-100 banner_img",
            attrs: {
              "show-type": "photo",
              "init-val": _vm.formData.img,
              "wrapper-width": "359px",
              "wrapper-height": "248px",
              "img-width": 750,
              "min-height": 520,
              "photo-width": 359,
              "photo-height": 248
            },
            on: {
              setVal: function(val) {
                _vm.formData.img = val
              }
            }
          })
        : _vm._e(),
      _vm.type === "capsuleBanner"
        ? _c("crop-upload", {
            staticClass: "w-100 banner_img",
            attrs: {
              "show-type": "photo",
              "init-val": _vm.formData.img,
              "wrapper-width": "359px",
              "wrapper-height": "88px",
              "img-width": 690,
              "img-height": 170,
              "photo-width": 359,
              "photo-height": 88
            },
            on: {
              setVal: function(val) {
                _vm.formData.img = val
              }
            }
          })
        : _vm._e(),
      _vm.type === "bannerCol"
        ? _c("crop-upload", {
            staticClass: "w-100 banner_img",
            attrs: {
              "show-type": "photo",
              "init-val": _vm.formData.img,
              "wrapper-width": "170px",
              "wrapper-height": "253px",
              "img-width": 335,
              "img-height": 500,
              "photo-width": 170,
              "photo-height": 253
            },
            on: {
              setVal: function(val) {
                _vm.formData.img = val
              }
            }
          })
        : _vm._e(),
      _vm.type === "bannerColThree"
        ? _c("crop-upload", {
            staticClass: "w-100 banner_img",
            attrs: {
              "show-type": "photo",
              "init-val": _vm.formData.img,
              "wrapper-width": "170px",
              "wrapper-height": "253px",
              "img-width": 335,
              "img-height": 500,
              "photo-width": 170,
              "photo-height": 253
            },
            on: {
              setVal: function(val) {
                _vm.formData.img = val
              }
            }
          })
        : _vm._e(),
      _vm.type === "bannerColThreesmall"
        ? _c("crop-upload", {
            staticClass: "w-100 banner_img",
            attrs: {
              "show-type": "photo",
              "init-val": _vm.formData.img,
              "wrapper-width": "170px",
              "wrapper-height": "253px",
              "img-width": 341,
              "img-height": 148,
              "photo-width": 170,
              "photo-height": 253
            },
            on: {
              setVal: function(val) {
                _vm.formData.img = val
              }
            }
          })
        : _vm._e(),
      _vm.type === "secBanner"
        ? _c("crop-upload", {
            staticClass: "w-100 banner_img",
            attrs: {
              "show-type": "photo",
              "init-val": _vm.formData.img,
              "wrapper-width": "359px",
              "wrapper-height": "88px",
              "img-width": 690,
              "img-height": 170,
              "photo-width": 359,
              "photo-height": 88
            },
            on: {
              setVal: function(val) {
                _vm.formData.img = val
              }
            }
          })
        : _vm._e(),
      _vm.type === "backgroundImg"
        ? _c("crop-upload", {
            staticClass: "w-100 banner_img",
            attrs: {
              "show-type": "photo",
              "init-val": _vm.formData.img,
              "wrapper-width": "359px",
              "wrapper-height": "88px",
              "img-width": 750,
              "img-height": 255,
              "photo-width": 359,
              "photo-height": 88
            },
            on: {
              setVal: function(val) {
                _vm.formData.img = val
              }
            }
          })
        : _vm._e(),
      _vm.type != "backgroundImg"
        ? _c("div", [
            _vm.formData.jumpType && _vm.formData.jumpUrl
              ? _c(
                  "div",
                  {
                    staticClass: "flex align-center link_val pointer",
                    on: { click: _vm.addLink }
                  },
                  [
                    _c("span", { staticClass: "type" }, [
                      _vm._v(
                        _vm._s(
                          _vm.formData.jumpType == 1 ? "文本链接" : "商品链接"
                        )
                      )
                    ]),
                    _vm.type === "bannerCol" || _vm.linkVal.length > 20
                      ? _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: _vm.linkVal,
                              placement: "top-start"
                            }
                          },
                          [
                            _c("p", { staticClass: "flex-1 text-ellipsis" }, [
                              _vm._v(_vm._s(_vm.linkVal))
                            ])
                          ]
                        )
                      : _c("p", { staticClass: "flex-1 text-ellipsis" }, [
                          _vm._v(_vm._s(_vm.linkVal))
                        ])
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "add-div", on: { click: _vm.addLink } },
                  [
                    _c("i", { staticClass: "el-icon-link" }),
                    _vm._v("添加链接\n\t\t\t")
                  ]
                )
          ])
        : _vm._e(),
      _c("link-dialog", {
        ref: "linkDialog",
        attrs: { editItem: _vm.editItem },
        on: { setVal: _vm.setVal }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }