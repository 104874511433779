"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    var _this = this;
    return {
      yearStart: '',
      yearEnd: '',
      dateValue: '',
      dateType: 'datetimerange',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      pickerOptions: {
        shortcuts: [{
          text: '年',
          onClick: function onClick(picker) {
            _this.valueFormat = 'yyyy';
            _this.changeType('year');
          }
        }, {
          text: '月',
          onClick: function onClick(picker) {
            _this.valueFormat = 'yyyy-MM';
            _this.changeType('monthrange');
          }
        }, {
          text: '日期时间',
          onClick: function onClick(picker) {
            _this.valueFormat = 'yyyy-MM-dd HH:mm:ss';
            _this.changeType('datetimerange');
          }
        }]
      },
      startDatePicker: this.beginDate(),
      endDatePicker: this.processDate()
    };
  },
  methods: {
    reset: function reset() {
      this.yearStart = '';
      this.yearEnd = '';
      this.dateValue = '';
      this.dateType = 'datetimerange';
      this.valueFormat = 'yyyy-MM-dd HH:mm:ss';
    },
    getVal: function getVal() {
      if (this.dateType === 'year') {
        if (!this.yearStart && !this.yearEnd) {
          return '';
        }
        if (!this.yearStart) {
          this.$message({
            message: "请选择起始年份",
            type: "warning"
          });
          return 'error';
        }
        if (!this.yearEnd) {
          this.$message({
            message: "请选择结束年份",
            type: "warning"
          });
          return 'error';
        }
        return this.yearStart + '~' + this.yearEnd;
      } else {
        return this.dateValue ? this.dateValue[0] + '~' + this.dateValue[1] : '';
      }
    },
    beginDate: function beginDate() {
      var _this2 = this;
      var self = this;
      return {
        shortcuts: [{
          text: '年',
          onClick: function onClick(picker) {
            _this2.valueFormat = 'yyyy';
            _this2.changeType('year');
          }
        }, {
          text: '月',
          onClick: function onClick(picker) {
            _this2.valueFormat = 'yyyy-MM';
            _this2.changeType('monthrange');
          }
        }, {
          text: '日期时间',
          onClick: function onClick(picker) {
            _this2.valueFormat = 'yyyy-MM-dd HH:mm:ss';
            _this2.changeType('datetimerange');
          }
        }],
        disabledDate: function disabledDate(time) {
          if (self.yearEnd) {
            return self.yearEnd < time.getFullYear();
          } else {
            // return time.getFullYear() > new Date().getFullYear()
          }
        }
      };
    },
    processDate: function processDate() {
      var _this3 = this;
      var self = this;
      return {
        shortcuts: [{
          text: '年',
          onClick: function onClick(picker) {
            _this3.valueFormat = 'yyyy';
            _this3.changeType('year');
          }
        }, {
          text: '月',
          onClick: function onClick(picker) {
            _this3.valueFormat = 'yyyy-MM';
            _this3.changeType('monthrange');
          }
        }, {
          text: '日期时间',
          onClick: function onClick(picker) {
            _this3.valueFormat = 'yyyy-MM-dd HH:mm:ss';
            _this3.changeType('datetimerange');
          }
        }],
        disabledDate: function disabledDate(time) {
          if (self.yearStart) {
            return self.yearStart > time.getFullYear();
          } else {
            // return time.getFullYear() > new Date().getFullYear()
          }
        }
      };
    },
    changeType: function changeType(type) {
      var _this4 = this;
      this.dateType = type;
      this.dateValue = '';
      this.yearStart = '';
      this.yearEnd = '';
      if (type !== 'year') {
        this.$nextTick(function () {
          _this4.$refs.datePicker.focus();
        });
      } else {
        this.$nextTick(function () {
          _this4.$refs.yearStart.focus();
        });
      }
    }
  }
};
exports.default = _default;