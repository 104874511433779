var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, border: "" } },
        [
          _c("el-table-column", {
            attrs: { align: "center", prop: "create_time", label: "购买时间" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "buy_num", label: "购买数量" }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "pay_amount",
              label: "支付金额（元）"
            }
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "buy_type_name", label: "类型" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "username", label: "操作人" }
          })
        ],
        1
      ),
      _c("custom-page", {
        ref: "customPage",
        attrs: { total: _vm.total },
        on: { getList: _vm.getList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }