"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
var _settle = require("@/api/settle");
var _customPage = _interopRequireDefault(require("@/components/customPage"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    customPage: _customPage.default
  },
  props: ['shopname'],
  data: function data() {
    return {
      searchForm: {
        name: "",
        mobile: ""
      },
      total: 0,
      tableData: [],
      routerName: ''
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    if (from.name === "memberView") {
      next(function (vm) {
        vm.getList();
      });
    } else {
      next();
    }
  },
  methods: {
    handleMore: function handleMore(command, item) {
      console.log(command, item);
      // this.selectedItem = item;
      if (command == 'edit') {
        // 编辑资料
        this.$router.push({
          name: 'memberView',
          params: {
            viewId: item.id,
            toEdit: true
          }
        });
      } else {
        // 查看备注
      }
    },
    // 查询
    search: function search() {
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      this.searchForm = {
        shopname: "",
        account_num: "",
        status: null
      };
      this.routerName = '';
      this.getList();
    },
    // 启用/禁用
    enable: function enable(id, endisable) {
      var _this = this;
      editSupplier({
        id: id,
        endisable: endisable == 1 ? 2 : 1
      }).then(function (res) {
        _this.getList(true);
      });
    },
    getList: function getList(flag) {
      var _this2 = this;
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      if (!flag) {
        this.tableData = [];
      }
      (0, _settle.getSettleList)((0, _objectSpread2.default)({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        _this2.tableData = res.data || [];
        _this2.total = res.total;
      });
    },
    delSettleById: function delSettleById(id) {
      var _this3 = this;
      this.$confirm("是否确认删除!!!", '确认删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _settle.delSettleById)({
          id: id
        }).then(function (res) {
          _this3.getList(true);
        });
      }).catch(function () {});
    }
  }
};
exports.default = _default;