"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.delSettleById = delSettleById;
exports.getSettleList = getSettleList;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
/**
 * 用户管理-用户列表
 */
// 获取入驻商户列表
function getSettleList(params) {
  return _fetch.default.get("/Supplier/Settle/getSettleList", params);
}

// 删除入驻商户
function delSettleById(params) {
  return _fetch.default.post("/Supplier/Settle/delSettleById", params);
}