"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _marketing = require("@/api/marketing");
var _add = _interopRequireDefault(require("./add"));
var _customPage = _interopRequireDefault(require("@/components/customPage"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    add: _add.default,
    customPage: _customPage.default
  },
  data: function data() {
    return {
      tableData: [],
      total: 0,
      editItem: {}
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      (0, _marketing.getChannelList)({
        page: page,
        pageSize: pageSize
      }).then(function (res) {
        _this.tableData = res.data || [];
        _this.total = res.total || 0;
      });
    },
    // 列表项删除
    removeItem: function removeItem(id) {
      var _this2 = this;
      this.$confirm('您确定要删除吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _marketing.delChannel)({
          id: id
        }).then(function (res) {
          _this2.getList();
        });
      });
    },
    editChannel: function editChannel() {
      var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.editItem = item;
      this.$refs.add.open();
    }
  }
};
exports.default = _default;