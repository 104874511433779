var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      attrs: { title: "查看风控详情", visible: _vm.dialogVisible, size: "50%" },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading"
          },
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        staticStyle: { width: "100%", height: "400px" }
      }),
      !_vm.loading
        ? _c(
            "div",
            { staticStyle: { padding: "10px" } },
            [
              _c("div", { staticClass: "flex idcard_wrap" }, [
                _c("div", { staticClass: "flex-1" }, [
                  _c("p", { staticClass: "text-center img_header" }, [
                    _vm._v("基本信息")
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "ant-descriptions ant-descriptions-bordered"
                    },
                    [
                      _c("div", { staticClass: "ant-descriptions-view" }, [
                        _c("table", [
                          _c("tbody", [
                            _c("tr", { staticClass: "ant-descriptions-row" }, [
                              _c(
                                "th",
                                {
                                  staticClass:
                                    "ant-descriptions-item-label ant-descriptions-item-colon"
                                },
                                [_vm._v("姓名")]
                              ),
                              _c(
                                "td",
                                {
                                  staticClass: "ant-descriptions-item-content",
                                  attrs: { colspan: "1" }
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.tableData.name) +
                                      "\n\t\t\t\t\t\t\t\t\t"
                                  )
                                ]
                              ),
                              _c(
                                "th",
                                {
                                  staticClass:
                                    "ant-descriptions-item-label ant-descriptions-item-colon"
                                },
                                [_vm._v("身份证号")]
                              ),
                              _c(
                                "td",
                                {
                                  staticClass: "ant-descriptions-item-content",
                                  attrs: { colspan: "1" }
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.tableData.ident_number) +
                                      "\n\t\t\t\t\t\t\t\t\t"
                                  )
                                ]
                              )
                            ]),
                            _c("tr", { staticClass: "ant-descriptions-row" }, [
                              _c(
                                "th",
                                {
                                  staticClass:
                                    "ant-descriptions-item-label ant-descriptions-item-colon"
                                },
                                [_vm._v("手机号")]
                              ),
                              _c(
                                "td",
                                {
                                  staticClass: "ant-descriptions-item-content",
                                  attrs: { colspan: "1" }
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.tableData.phone) +
                                      "\n\t\t\t\t\t\t\t\t\t"
                                  )
                                ]
                              )
                            ])
                          ])
                        ])
                      ])
                    ]
                  )
                ])
              ]),
              _c("div", { staticClass: "flex-2" }, [
                _c("p", { staticClass: "text-center img_header" }, [
                  _vm._v("风险建议")
                ]),
                _c(
                  "div",
                  { staticClass: "ant-descriptions ant-descriptions-bordered" },
                  [
                    _c("div", { staticClass: "ant-descriptions-view" }, [
                      _c("table", [
                        _c("tbody", [
                          _c("tr", { staticClass: "ant-descriptions-row" }, [
                            _c(
                              "th",
                              {
                                staticClass:
                                  "ant-descriptions-item-label ant-descriptions-item-colon",
                                attrs: { colspan: "1" }
                              },
                              [_vm._v("审核建议\n\t\t\t\t\t\t\t\t")]
                            ),
                            _c(
                              "th",
                              {
                                staticClass:
                                  "ant-descriptions-item-label ant-descriptions-item-colon",
                                attrs: { colspan: "3" }
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\t分值标注说明\n\t\t\t\t\t\t\t\t"
                                )
                              ]
                            )
                          ]),
                          _c("tr", { staticClass: "ant-descriptions-row" }, [
                            _c(
                              "td",
                              {
                                staticClass: "ant-descriptions-item-content",
                                attrs: { colspan: "1" }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    class: {
                                      success:
                                        _vm.tableData.decision == "accept",
                                      warning:
                                        _vm.tableData.decision == "validate",
                                      danger: _vm.tableData.decision == "reject"
                                    },
                                    staticStyle: {
                                      "font-size": "30px",
                                      "font-weight": "bold"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.tableData.decision == "accept"
                                            ? "通过"
                                            : _vm.tableData.decision ==
                                              "validate"
                                            ? "待定"
                                            : "拒绝"
                                        )
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "ant-descriptions-item-content",
                                attrs: { colspan: "3" }
                              },
                              [
                                _c("div", [
                                  _c("span", [
                                    _vm._v(" 80分以下安全，80分以上拒绝")
                                  ])
                                ])
                              ]
                            )
                          ])
                        ])
                      ])
                    ])
                  ]
                )
              ]),
              _c(
                "div",
                {
                  staticClass: "flex-2",
                  staticStyle: { "border-inline": "inherit" }
                },
                [
                  _c("p", { staticClass: "text-center img_header" }, [
                    _c("span", [_vm._v("模型结果详情")])
                  ])
                ]
              ),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tableData.models, border: "" }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "序号", width: "180" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                "\n\t\t\t\t\t" +
                                  _vm._s(scope.$index + 1) +
                                  "\n\t\t\t\t"
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1966914161
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "sceneCode",
                      label: "风险模型编码",
                      width: "180"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "score", label: "模型分数" }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "flex-2",
                  staticStyle: { "border-inline": "inherit" }
                },
                [
                  _c("p", { staticClass: "text-center img_header" }, [
                    _c("span", [_vm._v("风险场景的决策结果")])
                  ])
                ]
              ),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tableData.scenes, border: "" }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "序号", width: "180" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                "\n\t\t\t\t\t" +
                                  _vm._s(scope.$index + 1) +
                                  "\n\t\t\t\t"
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1966914161
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "sceneCode",
                      label: "风险场景编码",
                      width: "180"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "决策结果" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                "\n\t\t\t\t\t" +
                                  _vm._s(
                                    scope.row.decision == "accept"
                                      ? "通过"
                                      : scope.row.decision == "validate"
                                      ? "待定"
                                      : "拒绝"
                                  ) +
                                  "\n\t\t\t\t"
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3027868702
                    )
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "flex-2",
                  staticStyle: { "border-inline": "inherit" }
                },
                [
                  _c("p", { staticClass: "text-center img_header" }, [
                    _c("span", [_vm._v("策略结果详情")])
                  ])
                ]
              ),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tableData.strategies, border: "" }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "序号", width: "180" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                "\n\t\t\t\t\t" +
                                  _vm._s(scope.$index + 1) +
                                  "\n\t\t\t\t"
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1966914161
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "sceneCode",
                      label: "风险场景编码",
                      width: "180"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "策略名称", width: "180" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "决策结果" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                "\n\t\t\t\t\t" +
                                  _vm._s(
                                    scope.row.decision == "accept"
                                      ? "通过"
                                      : scope.row.decision == "validate"
                                      ? "待定"
                                      : "拒绝"
                                  ) +
                                  "\n\t\t\t\t"
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3027868702
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }