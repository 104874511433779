"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _orderEC = require("@/api/orderEC");
var _saveEmeCon = _interopRequireDefault(require("./saveEmeCon"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ['editItem'],
  components: {
    saveEmeCon: _saveEmeCon.default
  },
  data: function data() {
    return {
      loading: false,
      dialogVisible: false,
      isFullscreen: false,
      tableData: [],
      selectedItem: {},
      selectedId: 0,
      selfItem: {}
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.isFullscreen = false;
        this.orderId = this.editItem.id;
        this.getList();
      }
    }
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.loading = true;
      // this.tableData = []
      console.log(this.orderId);
      (0, _orderEC.getListEmeConByOrderId)({
        order_id: this.orderId
      }).then(function (res) {
        _this.tableData = res || [];
        _this.loading = false;
      });
    },
    addEmeCon: function addEmeCon() {
      this.selfItem = null;
      this.$refs.saveEmeCon.dialogVisible = true;
    },
    saveEmeCon: function saveEmeCon(itme) {
      this.selfItem = null;
      this.selfItem = itme;
      this.$refs.saveEmeCon.dialogVisible = true;
    },
    deleteEmeConById: function deleteEmeConById(id) {
      var _this2 = this;
      this.$confirm("您确定要删除该联系人吗？", "删除信息", {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _orderEC.delOrderEmeConById)({
          id: id
        }).then(function (res) {
          _this2.getList();
        });
      }).catch(function () {});
    }
  }
};
exports.default = _default;