"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {},
  props: {
    goodsNum: {
      default: 4
    },
    editData: {
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      shopList: [],
      options: ['约节省', '贵']
    };
  },
  watch: {
    editData: function editData(val) {
      if (val) {
        this.shopList = val;
      }
    },
    shopList: function shopList(val) {
      // console.log('shopList', val)
      this.$emit('setSubmitData', val);
    }
  },
  mounted: function mounted() {},
  methods: {
    // 添加门店
    addShop: function addShop() {
      this.shopList.push({});
    },
    // 删除商品
    deleteShop: function deleteShop(index) {
      this.shopList.splice(index, 1);
    },
    // 设置选中
    setSelect: function setSelect(shopList) {
      this.shopList = shopList;
    },
    // 升序
    upSort: function upSort(index) {
      if (index === 0) {
        return;
      }
      var item = this.shopList[index];
      this.$set(this.shopList, index, this.shopList[index - 1]);
      this.$set(this.shopList, index - 1, item);
    },
    // 降序
    downSort: function downSort(index) {
      if (index === this.shopList.length - 1) {
        return;
      }
      var item = this.shopList[index];
      this.$set(this.shopList, index, this.shopList[index + 1]);
      this.$set(this.shopList, index + 1, item);
    }
  }
};
exports.default = _default2;