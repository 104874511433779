var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "return_ad" },
    [
      _c(
        "div",
        { staticClass: "warn-box" },
        [
          _c("svg-icon", {
            staticStyle: { "margin-right": "17px" },
            attrs: { "icon-class": "warn", size: 20 }
          }),
          _c("p", [
            _vm._v("租赁地址为商品租赁发货地址，完善填写商品时所需内容。")
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex align-center table-top-btns" },
        [
          _c(
            "h5",
            { staticClass: "flex align-center" },
            [
              _c("svg-icon", {
                staticStyle: { "margin-right": "15px" },
                attrs: { "icon-class": "list", size: 15 }
              }),
              _vm._v("数据列表\n    ")
            ],
            1
          ),
          _vm.$hasMethod("#add")
            ? _c(
                "el-button",
                {
                  staticClass: "add-btn",
                  attrs: { size: "small", icon: "el-icon-plus" },
                  on: {
                    click: function($event) {
                      return _vm.openDialog()
                    }
                  }
                },
                [_vm._v("添加租赁地址")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: {
              fixed: "",
              prop: "id",
              label: "ID",
              width: "70",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "province", label: "省份", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "city", label: "城市", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "area", label: "区/县", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { label: "详细地址", align: "center", prop: "address" }
          }),
          _c("el-table-column", {
            attrs: { prop: "promise", label: "服务承诺", align: "center" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "status",
              label: "地址状态",
              width: "80",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        staticClass: "tagClass",
                        class: {
                          tag_success: scope.row.status == 1,
                          tag_danger: scope.row.status == 2,
                          pointer: _vm.$hasMethod("#enable")
                        },
                        attrs: {
                          size: "small",
                          type: scope.row.status == 1 ? "success" : "danger"
                        },
                        on: {
                          click: function($event) {
                            return _vm.changeStatus(
                              scope.row.id,
                              scope.row.status
                            )
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(scope.row.status == 2 ? "禁用" : "启用") +
                            "\n        "
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              label: "操作",
              width: "280",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.$hasMethod("#edit")
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.openDialog(scope.row.id)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    _vm.$hasMethod("#delete")
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.deleteItem(scope.row.id)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("custom-page", {
        ref: "customPage",
        attrs: { total: _vm.total },
        on: { getList: _vm.getList }
      }),
      _c("add-rent-ad", {
        ref: "addRentAd",
        attrs: { "edit-id": _vm.selectedId },
        on: { getList: _vm.getList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }