"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _vuex = _interopRequireDefault(require("vuex"));
var _getters = _interopRequireDefault(require("./getters"));
var _user = _interopRequireDefault(require("./modules/user"));
var _permission = _interopRequireDefault(require("./modules/permission"));
_vue.default.use(_vuex.default);
var store = new _vuex.default.Store({
  modules: {
    user: _user.default,
    permission: _permission.default
  },
  getters: _getters.default
});
var _default = store;
exports.default = _default;