var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "800px",
        title: "修改套餐",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading"
          },
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        staticStyle: { width: "100%", height: "160px" }
      }),
      !_vm.loading
        ? _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "选择套餐", prop: "setmeal_id" } },
                _vm._l(_vm.setmealList, function(item) {
                  return _c(
                    "div",
                    { staticClass: "setmeal-radio" },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: item.id, border: "" },
                          on: {
                            change: function($event) {
                              return _vm.setSpecId(item)
                            }
                          },
                          model: {
                            value: _vm.form.setmeal_id,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "setmeal_id", $$v)
                            },
                            expression: "form.setmeal_id"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(item.title) +
                              " - " +
                              _vm._s(_vm.getSpecLable(item)) +
                              " "
                          ),
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("总:" + _vm._s(item.price))
                          ]),
                          _c("span", { staticStyle: { color: "blue" } }, [
                            _vm._v(" 断:" + _vm._s(item.foregift))
                          ])
                        ]
                      )
                    ],
                    1
                  )
                }),
                0
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.submit }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }