var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "手续费率和手续费金额",
        visible: _vm.dialogVisible,
        width: "1000px"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, border: "" } },
        [
          _c("el-table-column", {
            attrs: { type: "expand" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(props) {
                  return [
                    _c("div", { staticClass: "flex flex-wrap detail" }, [
                      _c("p", { staticClass: "detail_item" }, [
                        _c("label", [_vm._v("平台手续费：")]),
                        _vm._v(
                          "\n            ￥" +
                            _vm._s(props.row.platform_amount) +
                            "（" +
                            _vm._s(props.row.platform_rate) +
                            "%）\n          "
                        )
                      ]),
                      _c("p", { staticClass: "detail_item" }, [
                        _c("label", [_vm._v("实名认证：")]),
                        _vm._v(
                          "\n            ￥" +
                            _vm._s(props.row.realname_amount || "0.00") +
                            "\n          "
                        )
                      ]),
                      _c("p", { staticClass: "detail_item" }, [
                        _c("label", [_vm._v("合同：")]),
                        _vm._v(
                          "\n            ￥" +
                            _vm._s(props.row.contract_amount || "0.00") +
                            "\n          "
                        )
                      ]),
                      _c("p", { staticClass: "detail_item" }, [
                        _c("label", [_vm._v("风控：")]),
                        _vm._v(
                          "\n            ￥" +
                            _vm._s(props.row.risk_amount || "0.00") +
                            "\n          "
                        )
                      ]),
                      _c("p", { staticClass: "detail_item" }, [
                        _c("label", [_vm._v("保险：")]),
                        _vm._v(
                          "\n            ￥" +
                            _vm._s(props.row.insurance_amount) +
                            "（" +
                            _vm._s(props.row.insurance_rate) +
                            "%）\n          "
                        )
                      ]),
                      _c("p", { staticClass: "detail_item" }, [
                        _c("label", [_vm._v("融资总租金：")]),
                        _vm._v(
                          "\n            ￥" +
                            _vm._s(props.row.financing_amount) +
                            "（" +
                            _vm._s(props.row.financing_rate) +
                            "%）\n          "
                        )
                      ]),
                      _c("p", { staticClass: "detail_item" }, [
                        _c("label", [_vm._v("存证：")]),
                        _vm._v(
                          "\n            ￥" +
                            _vm._s(props.row.file_amount) +
                            "（" +
                            _vm._s(props.row.file_rate) +
                            "%）\n          "
                        )
                      ]),
                      _c("p", { staticClass: "detail_item" }, [
                        _c("label", [_vm._v("代扣手续费：")]),
                        _vm._v(
                          "\n            ￥" +
                            _vm._s(props.row.withhold_amount) +
                            "（" +
                            _vm._s(props.row.withhold_rate) +
                            "%）\n          "
                        )
                      ]),
                      _c("p", { staticClass: "detail_item" }, [
                        _c("label", [_vm._v("花呗：")]),
                        _vm._v(
                          "\n            ￥" +
                            _vm._s(props.row.huabei_fee || "0.00") +
                            "\n          "
                        )
                      ])
                    ])
                  ]
                }
              }
            ])
          }),
          _c(
            "el-table-column",
            { attrs: { align: "center", label: "订单编号" } },
            [_c("span", [_vm._v(_vm._s(_vm.viewItem.trade_no))])]
          ),
          _c("el-table-column", {
            attrs: { align: "center", label: "租金", prop: "rent" }
          }),
          _c(
            "el-table-column",
            { attrs: { align: "center", label: "手续费率" } },
            [_c("span", [_vm._v(_vm._s(_vm.feeRate))])]
          ),
          _c(
            "el-table-column",
            { attrs: { align: "center", label: "手续费金额" } },
            [_c("span", [_vm._v(_vm._s(_vm.viewItem.service_fee))])]
          ),
          _c("el-table-column", {
            attrs: { align: "center", prop: "trade_no", label: "交易流水号" }
          }),
          _c(
            "el-table-column",
            { attrs: { align: "center", label: "店铺名称" } },
            [_vm._v("\n      " + _vm._s(_vm.viewItem.shopname) + "\n    ")]
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("关闭")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }