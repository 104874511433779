"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _marketing = require("@/api/marketing");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    dialogTitle: {
      type: String
    },
    type: {
      type: Number,
      default: 1
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      infoData: {},
      selectId: '',
      checked: false,
      showError: false
    };
  },
  methods: {
    goToRecharge: function goToRecharge() {
      var routeData = this.$router.resolve({
        name: "recharge"
      });
      window.open(routeData.href, '_blank');
    },
    submit: function submit() {
      var _this = this;
      if (!this.checked) {
        this.$message({
          message: "请勾选同意使用规范",
          type: "warning"
        });
        this.showError = true;
        return;
      }
      (0, _marketing.toBuy)({
        buy_type: this.type,
        setmeal_id: this.selectId
      }).then(function (res) {
        _this.dialogVisible = false;
        _this.$emit('getList');
      });
    },
    getData: function getData() {
      var _this2 = this;
      (0, _marketing.buyInfo)(this.type).then(function (res) {
        _this2.infoData = res || {};
        _this2.selectId = _this2.infoData.setMeal[0].id;
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.getData();
      }
    }
  }
};
exports.default = _default;