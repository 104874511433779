var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "申请扣款",
        visible: _vm.dialogVisible,
        width: "500px",
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        { ref: "formData", attrs: { model: _vm.formData, rules: _vm.rules } },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "上传图片：",
                "label-width": "120px",
                prop: "evidence_url"
              }
            },
            [
              _c("crop-upload", {
                attrs: {
                  "show-type": "photo",
                  "init-val": _vm.formData.evidence_url,
                  "file-size": "100K",
                  "wrapper-width": "95px",
                  "wrapper-height": "95px"
                },
                on: {
                  setVal: function(val) {
                    return _vm.setUrl(val)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注：", "label-width": "120px" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", rows: 3, placeholder: "请输入备注" },
                model: {
                  value: _vm.formData.merchant_remark,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "merchant_remark", $$v)
                  },
                  expression: "formData.merchant_remark"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.submit()
                }
              }
            },
            [_vm._v("提交")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", plain: "" },
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("关闭")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }