var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "footer-page" },
    [
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "300px"
          }
        },
        [
          _c("div", { staticClass: "base_info" }, [
            _c("p", { staticClass: "edit-header" }, [
              _c("span", [_vm._v("IP封禁管理")])
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading"
                  }
                ],
                staticClass: "info_list"
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "限制时间内：", prop: "limit_sec" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: { clearable: "" },
                        on: {
                          change: function($event) {
                            return _vm.chkNum("limit_sec")
                          }
                        },
                        model: {
                          value: _vm.formData.limit_sec,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "limit_sec", $$v)
                          },
                          expression: "formData.limit_sec"
                        }
                      },
                      [_c("template", { slot: "append" }, [_vm._v("秒")])],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "限制访问次数：", prop: "limit_count" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: { clearable: "" },
                        on: {
                          change: function($event) {
                            return _vm.chkNum("limit_count")
                          }
                        },
                        model: {
                          value: _vm.formData.limit_count,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "limit_count", $$v)
                          },
                          expression: "formData.limit_count"
                        }
                      },
                      [_c("template", { slot: "append" }, [_vm._v("次")])],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "封禁时间：" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: { clearable: "" },
                        on: {
                          change: function($event) {
                            return _vm.chkNum("block_sec")
                          }
                        },
                        model: {
                          value: _vm.formData.block_sec,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "block_sec", $$v)
                          },
                          expression: "formData.block_sec"
                        }
                      },
                      [_c("template", { slot: "append" }, [_vm._v("秒")])],
                      2
                    ),
                    _c("div", { staticClass: "tips" }, [_vm._v("0为永久封禁")])
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "封禁提示信息：" } },
                  [
                    _c("el-input", {
                      attrs: {
                        clearable: "",
                        placeholder: "您的操作太过频繁,请稍后再试"
                      },
                      model: {
                        value: _vm.formData.block_msg,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "block_msg", $$v)
                        },
                        expression: "formData.block_msg"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "状态 ：" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.formData.status,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "status", $$v)
                          },
                          expression: "formData.status"
                        }
                      },
                      [
                        _c("el-radio", { attrs: { label: "1" } }, [
                          _vm._v("启用")
                        ]),
                        _c("el-radio", { attrs: { label: "0" } }, [
                          _vm._v("禁用")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ]
      ),
      !_vm.$hasMethod("#isMerchant")
        ? _c(
            "div",
            { staticClass: "page-footer-btns" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.submit }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }