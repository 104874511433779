"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["orderId", "merchantId"],
  components: {},
  data: function data() {
    return {
      loading: true,
      dialogVisible: false,
      tableData: [],
      selectedItem: {}
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.loading = true;
        this.getOrderRiskControl();
      }
    }
  },
  methods: {
    getOrderRiskControl: function getOrderRiskControl() {
      var _this = this;
      console.log(this.orderId);
      (0, _order.getOrderRiskControl)(this.orderId).then(function (res) {
        _this.tableData = res || [];
        console.log(_this.tableData);
        // this.tableData.base_info.name = this.orderId.name;
        // this.tableData.base_info.phone = this.orderId.phone;
        _this.loading = false;
      });
    },
    getType: function getType(row) {
      if (row.key == "rentOnlineRiskLevelOverall") {
        return "综合风险等级";
      }
      if (row.key == "rentOnlineRiskLevelMultiRent") {
        return "共租行为等级";
      }
      if (row.key == "rentOnlineRiskLevelFulfill") {
        return "履约历史等级";
      }
      // return "未知";
    },
    getLevel: function getLevel(row) {
      console.log(row);
      if (row.key == "rentOnlineRiskLevelOverall") {
        switch (row.value) {
          case "0":
            return "低风险";
          case "1":
            return "中风险";
          case "2":
            return "高风险";
          case "3":
            return "极高风险";
        }
      }
      if (row.key == "rentOnlineRiskLevelMultiRent") {
        switch (row.value) {
          case "0":
            return "共租风险低";
          case "1":
            return "共租风险中";
          case "2":
            return "共租风险高";
        }
      }
      if (row.key == "rentOnlineRiskLevelFulfill") {
        switch (row.value) {
          case "0":
            return "无逾期";
          case "1":
            return "正在逾期或者历史有N+逾期";
        }
      }
      // return "未知";
    },
    getRecom: function getRecom(row) {
      if (row.key == "rentOnlineRiskLevelOverall") {
        switch (row.value) {
          case "0":
            return "建议发货";
          case "1":
            return "可适当放宽商家风控条件，提高发货率";
          case "2":
            return "缴纳押金或者预缴多期租金情况下，谨慎发货";
          case "3":
            return "不建议发货";
        }
      }
      if (row.key == "rentOnlineRiskLevelMultiRent") {
        switch (row.value) {
          case "0":
            return "无明显共租行为";
          case "1":
            return "共租行为较多";
          case "2":
            return "共租行为过多，明显超过平均水位";
        }
      }
      if (row.key == "rentOnlineRiskLevelFulfill") {
        return "该字段仅供商家参考使用，等级为1不一定代表用户有较高风险";
      }

      // return "未知"
    },
    getTask: function getTask(row) {
      if (row.key == "rentOnlineRiskLevelOverall") {
        return "基于芝麻信用的大数据风控模型与策略，综合评估用户在租物场景的风险(含欺诈风险和信用风险)";
      }
      if (row.key == "rentOnlineRiskLevelMultiRent") {
        return "用户在芝麻信用免押租物场景的共租行为等级,用户在下单前后N天内在租数/在租商品金额/咨询数/授权数/授权商家数较多";
      }
      if (row.key == "rentOnlineRiskLevelFulfill") {
        return "用户在近N年内芝麻信用服务场景中的履约情况";
      }
      // return "未知";
    }
  }
};
exports.default = _default;