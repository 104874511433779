"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _goods = require("@/api/goods");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ['goodsId'],
  data: function data() {
    return {
      goodsInfo: {},
      baseMeta: [{
        label: '商品品类',
        name: 'cate_name'
      }, {
        label: '商品名称',
        name: 'title'
      }, {
        label: '推广关键字',
        name: 'spread_title'
      },
      // {
      //     label: '商品属性',
      //     name: 'attribute',
      // },
      // {
      //     label: '新旧',
      //     name: 'new_grade',
      // },
      // {
      //     label: '租赁标签',
      //     name: 'rent_label',
      // },
      {
        label: '商品封面',
        name: 'imgurl'
      }, {
        label: '商品图片',
        name: 'carousel_images'
      }, {
        label: '商品详情',
        name: 'goods_content'
      }, {
        label: '产品合同类型',
        name: 'pact_type'
      },
      // {
      //     label: '服务城市',
      //     name: 'service_province',
      // },
      // {
      //     label: '租赁地址',
      //     name: 'service_address',
      // },
      {
        label: '运费',
        name: 'transfer_cost'
      }, {
        label: '配送方式',
        name: 'convey_type'
      }],
      mealMeta: [{
        label: '套餐名字',
        name: 'title'
      },
      // {
      // 	label: "租期设置",
      // 	name: 'lease_set'
      // },
      // {
      // 	label: "花呗分期",
      // 	name: 'is_huabei'
      // },
      // {
      // 	label: "花呗设置",
      // 	name: 'huabei_data'
      // },
      {
        label: '数量',
        name: 'quantity'
      },
      // {
      // 	label: "押金",
      // 	name: 'foregift'
      // },
      // {
      // 	label: "是否买断",
      // 	name: 'is_buy_out'
      // },
      {
        label: '销售价格',
        name: 'price'
      }]
    };
  },
  mounted: function mounted() {
    document.getElementsByClassName('app-main')[0].scrollTop = 0;
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this = this;
      (0, _goods.getGoodsInfo)(this.goodsId).then(function (res) {
        _this.goodsInfo = res || {};
        _this.goodsInfo.carousel_images = _this.goodsInfo.carousel_images ? _this.goodsInfo.carousel_images.split(',') : [];
        _this.getPriceRate();
      });
    },
    getPriceRate: function getPriceRate() {
      var priceRate = this.goodsInfo.price_rate || 1.3;
      this.goodsInfo.price_rate = isNaN(priceRate) || priceRate <= 0 ? this.defaultPriceRate : priceRate;
    },
    getSpecLable: function getSpecLable(item) {
      console.log('item', item);
      var spec = item.spec[0];
      var specsName = item.specs_name ? item.specs_name : '规格';
      var unit = spec.lease_title.indexOf('月') > -1 ? '月' : item.lease_set == 2 ? '天' : '期';
      var buyOutStr = item.is_buy_out ? '可买断' : '不可买断';
      var str = "".concat(item.title, " - ");
      if (item.specs_value) {
        str += "".concat(specsName, ": ").concat(item.specs_value, " / ");
      }
      str += "\u79DF\u671F: ".concat(spec.lease_title, " / ").concat(unit, "\u79DF\u91D1: ").concat(spec.lease_price, " / \u62BC\u91D1: ").concat(item.foregift, " / ").concat(buyOutStr, " / \u9500\u552E\u4EF7\u683C: ").concat(item.price, " / \u6570\u91CF: ").concat(item.quantity);
      return str;
    },
    chkPriceRate: function chkPriceRate(value) {
      value = parseFloat(value);
      var priceRate = isNaN(value) || value <= 0 ? 1 : value;
      priceRate = priceRate.toFixed(2);
      this.goodsInfo.price_rate = priceRate;
    }
  }
};
exports.default = _default;