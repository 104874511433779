var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "apply" }, [
    _vm._m(0),
    _c("div", { staticClass: "content_wrap" }, [
      _c("p", { staticClass: "text-center title" }, [_vm._v("欢迎入驻商家")]),
      _c("div", { staticClass: "flex align-center justify-center flow_wrap" }, [
        _c("div", { staticClass: "flex flex-column align-center flow_item" }, [
          _c(
            "span",
            {
              staticClass: "inline-block text-center flow_step",
              class: _vm.activeStep >= 1 ? "active" : ""
            },
            [_vm._v("1")]
          ),
          _c("span", [_vm._v("填写店铺信息")])
        ]),
        _c(
          "div",
          {
            staticClass: "flex flex-column align-center flow_item",
            staticStyle: { padding: "0 140px" }
          },
          [
            _c(
              "span",
              {
                staticClass: "inline-block text-center flow_step",
                class: _vm.activeStep >= 2 ? "active" : ""
              },
              [_vm._v("2")]
            ),
            _c("span", [_vm._v("填写公司信息")])
          ]
        ),
        _c("div", { staticClass: "flex flex-column align-center flow_item" }, [
          _c(
            "span",
            {
              staticClass: "inline-block text-center flow_step",
              class: _vm.activeStep == 3 ? "active" : ""
            },
            [_vm._v("3")]
          ),
          _c("span", [_vm._v("提交完成")])
        ])
      ]),
      _c("div", { staticClass: "form_wrap" }, [
        _c(
          "div",
          { staticClass: "main" },
          [
            _c("h3", { staticClass: "step_title" }, [
              _vm._v(
                _vm._s(
                  _vm.activeStep == 1
                    ? "填写店铺信息"
                    : _vm.activeStep == 2
                    ? "填写公司信息"
                    : "提交完成"
                )
              )
            ]),
            _vm.activeStep == 3
              ? _c(
                  "div",
                  {
                    staticClass: "text-center",
                    staticStyle: { padding: "40px 0" }
                  },
                  [
                    _c("div", { staticClass: "flex justify-center" }, [
                      _vm._m(1),
                      _c("div", { staticStyle: { "padding-left": "28px" } }, [
                        _c("h1", { staticClass: "blue-font" }, [
                          _vm._v("商家认证提交成功")
                        ]),
                        _c(
                          "p",
                          {
                            staticClass: "color-999 text-left",
                            staticStyle: { "margin-top": "18px" }
                          },
                          [_vm._v(_vm._s(_vm.currentDate))]
                        )
                      ])
                    ]),
                    _c("p", { staticStyle: { "margin-top": "24px" } }, [
                      _vm._v("商家认证将在1-3个工作日完成审核，节假日除外。")
                    ])
                  ]
                )
              : [
                  _c(
                    "el-form",
                    {
                      ref: "shopForm",
                      attrs: {
                        model: _vm.shopForm,
                        "label-position": "top",
                        rules: _vm.rules
                      }
                    },
                    [
                      _vm.activeStep == 1
                        ? [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "店铺名称", prop: "shopname" }
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入店铺名称" },
                                  model: {
                                    value: _vm.shopForm.shopname,
                                    callback: function($$v) {
                                      _vm.$set(_vm.shopForm, "shopname", $$v)
                                    },
                                    expression: "shopForm.shopname"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "p",
                              { staticClass: "color-999 form_item_tip" },
                              [_vm._v("限制1~50个字符，中文字体为两个字符")]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "店铺头像", prop: "photo" } },
                              [
                                _c("crop-upload", {
                                  attrs: {
                                    "show-type": "photo",
                                    "init-val": _vm.shopForm.photo,
                                    "photo-width": 130,
                                    "photo-height": 130,
                                    "wrapper-width": "130px",
                                    "wrapper-height": "130px"
                                  },
                                  on: {
                                    setVal: function(val) {
                                      return _vm.setUrl("photo", val)
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "p",
                              { staticClass: "color-999 form_item_tip" },
                              [
                                _vm._v(
                                  "小程序商铺展示使用，可上传公司logo图片。支持图片格式jpg、png、bmp，图片大小限制2M。"
                                )
                              ]
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "经营项目", prop: "shoptype" }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请选择"
                                    },
                                    model: {
                                      value: _vm.shopForm.shoptype,
                                      callback: function($$v) {
                                        _vm.$set(_vm.shopForm, "shoptype", $$v)
                                      },
                                      expression: "shopForm.shoptype"
                                    }
                                  },
                                  _vm._l(_vm.typeList, function(item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.id
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "店铺联系人", prop: "people" }
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入联系人姓名" },
                                  model: {
                                    value: _vm.shopForm.people,
                                    callback: function($$v) {
                                      _vm.$set(_vm.shopForm, "people", $$v)
                                    },
                                    expression: "shopForm.people"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "联系人手机号",
                                  prop: "contact_tel"
                                }
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入联系人手机号" },
                                  model: {
                                    value: _vm.shopForm.contact_tel,
                                    callback: function($$v) {
                                      _vm.$set(_vm.shopForm, "contact_tel", $$v)
                                    },
                                    expression: "shopForm.contact_tel"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "p",
                              { staticClass: "color-999 form_item_tip" },
                              [_vm._v("该手机号默认后台管理登陆账号")]
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "公司发票类型（可多选）",
                                  prop: "faptype"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "fp_item",
                                    class: {
                                      active:
                                        _vm.shopForm.faptype.indexOf(1) > -1
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.fpTypeChange(1)
                                      }
                                    }
                                  },
                                  [_vm._v("普通发票")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "fp_item",
                                    class: {
                                      active:
                                        _vm.shopForm.faptype.indexOf(2) > -1
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.fpTypeChange(2)
                                      }
                                    }
                                  },
                                  [_vm._v("增值税发票")]
                                )
                              ]
                            )
                          ]
                        : _vm._e(),
                      _vm.activeStep == 2
                        ? [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "公司/机构名称",
                                  prop: "mechanism"
                                }
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入营业执照公司全称"
                                  },
                                  model: {
                                    value: _vm.shopForm.mechanism,
                                    callback: function($$v) {
                                      _vm.$set(_vm.shopForm, "mechanism", $$v)
                                    },
                                    expression: "shopForm.mechanism"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "工商注册号",
                                  prop: "companyid"
                                }
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入工商注册号" },
                                  model: {
                                    value: _vm.shopForm.companyid,
                                    callback: function($$v) {
                                      _vm.$set(_vm.shopForm, "companyid", $$v)
                                    },
                                    expression: "shopForm.companyid"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "公司所在地区",
                                  prop: "company_area"
                                }
                              },
                              [
                                _c("el-cascader", {
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    placeholder: "请选择公司所在地区",
                                    options: _vm.areaList,
                                    props: _vm.areaProps,
                                    separator: "-"
                                  },
                                  model: {
                                    value: _vm.shopForm.company_area,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.shopForm,
                                        "company_area",
                                        $$v
                                      )
                                    },
                                    expression: "shopForm.company_area"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "公司详细地址",
                                  prop: "company"
                                }
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入公司详细地址" },
                                  model: {
                                    value: _vm.shopForm.company,
                                    callback: function($$v) {
                                      _vm.$set(_vm.shopForm, "company", $$v)
                                    },
                                    expression: "shopForm.company"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "当前经营位置",
                                  prop: "shopcity"
                                }
                              },
                              [
                                _c("el-cascader", {
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    placeholder: "请选择当前经营位置",
                                    options: _vm.regionList,
                                    props: _vm.optionProps,
                                    separator: "-"
                                  },
                                  model: {
                                    value: _vm.shopForm.shopcity,
                                    callback: function($$v) {
                                      _vm.$set(_vm.shopForm, "shopcity", $$v)
                                    },
                                    expression: "shopForm.shopcity"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "flex align-center" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "flex-1",
                                    attrs: {
                                      label: "营业执照图片",
                                      prop: "shopcard"
                                    }
                                  },
                                  [
                                    _c("crop-upload", {
                                      attrs: {
                                        "show-type": "photo",
                                        "init-val": _vm.shopForm.shopcard,
                                        "photo-width": 200,
                                        "photo-height": 150,
                                        "wrapper-width": "200px",
                                        "wrapper-height": "150px"
                                      },
                                      on: {
                                        setVal: function(val) {
                                          return _vm.setUrl("shopcard", val)
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "flex-1",
                                    attrs: {
                                      label: "公司门头照",
                                      prop: "doorphoto"
                                    }
                                  },
                                  [
                                    _c("crop-upload", {
                                      attrs: {
                                        "show-type": "photo",
                                        "init-val": _vm.shopForm.doorphoto,
                                        "photo-width": 200,
                                        "photo-height": 150,
                                        "wrapper-width": "200px",
                                        "wrapper-height": "150px"
                                      },
                                      on: {
                                        setVal: function(val) {
                                          return _vm.setUrl("doorphoto", val)
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "flex-1",
                                    attrs: {
                                      label: "公章图片",
                                      prop: "seal_url"
                                    }
                                  },
                                  [
                                    _c("crop-upload", {
                                      attrs: {
                                        "show-type": "photo",
                                        "init-val": _vm.shopForm.seal_url,
                                        "photo-width": 200,
                                        "photo-height": 150,
                                        "wrapper-width": "200px",
                                        "wrapper-height": "150px"
                                      },
                                      on: {
                                        setVal: function(val) {
                                          return _vm.setUrl("seal_url", val)
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "flex align-center" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "flex-1",
                                    attrs: { label: "法人姓名", prop: "faname" }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "请输入公司法人姓名"
                                      },
                                      model: {
                                        value: _vm.shopForm.faname,
                                        callback: function($$v) {
                                          _vm.$set(_vm.shopForm, "faname", $$v)
                                        },
                                        expression: "shopForm.faname"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "flex-1",
                                    staticStyle: { "margin-left": "50px" },
                                    attrs: {
                                      label: "法人身份证号",
                                      prop: "idcard"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "请输入公司法人身份证号"
                                      },
                                      model: {
                                        value: _vm.shopForm.idcard,
                                        callback: function($$v) {
                                          _vm.$set(_vm.shopForm, "idcard", $$v)
                                        },
                                        expression: "shopForm.idcard"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c("el-form-item", [
                              _c(
                                "p",
                                { attrs: { slot: "label" }, slot: "label" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "required-icon",
                                      staticStyle: { "margin-right": "5px" }
                                    },
                                    [_vm._v("*")]
                                  ),
                                  _vm._v("法人身份证图片")
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "flex flex-center idcard_wrap" },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "faidcard" } },
                                    [
                                      _c("crop-upload", {
                                        attrs: {
                                          "show-type": "photo",
                                          "init-val": _vm.shopForm.faidcard,
                                          "photo-width": 248,
                                          "photo-height": 148,
                                          "wrapper-width": "248px",
                                          "wrapper-height": "148px"
                                        },
                                        on: {
                                          setVal: function(val) {
                                            return _vm.setUrl("faidcard", val)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { "margin-left": "32px" },
                                      attrs: { prop: "faidcard_back" }
                                    },
                                    [
                                      _c("crop-upload", {
                                        attrs: {
                                          "show-type": "photo",
                                          "init-val":
                                            _vm.shopForm.faidcard_back,
                                          "photo-width": 248,
                                          "photo-height": 148,
                                          "wrapper-width": "248px",
                                          "wrapper-height": "148px"
                                        },
                                        on: {
                                          setVal: function(val) {
                                            return _vm.setUrl(
                                              "faidcard_back",
                                              val
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ])
                          ]
                        : _vm._e()
                    ],
                    2
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "text-center",
                      staticStyle: { "margin-top": "30px" }
                    },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.agreeChecked,
                            callback: function($$v) {
                              _vm.agreeChecked = $$v
                            },
                            expression: "agreeChecked"
                          }
                        },
                        [
                          _vm._v("我已阅读同意"),
                          _c("span", { staticClass: "blue-font" }, [
                            _vm._v("《商家认证协议》")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ]
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "bottom" },
          [
            _vm.activeStep == 2
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        _vm.activeStep = 1
                      }
                    }
                  },
                  [_vm._v("上一页")]
                )
              : _vm._e(),
            _vm.activeStep == 1
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        _vm.activeStep = 2
                      }
                    }
                  },
                  [_vm._v("下一页")]
                )
              : _vm._e(),
            _vm.activeStep == 2
              ? _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.submit } },
                  [_vm._v("提交审核")]
                )
              : _vm._e(),
            _vm.activeStep == 3
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.$router.push("/login")
                      }
                    }
                  },
                  [_vm._v("完成")]
                )
              : _vm._e()
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top_wrap" }, [
      _c("div", { staticClass: "login_top" }, [
        _c("div", { staticClass: "left_logo" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "blue-font" }, [
      _c("i", {
        staticClass: "el-icon-success",
        staticStyle: { "font-size": "60px" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }