"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.func = void 0;
require("core-js/modules/es6.regexp.replace");
var func = {
  chkLoginIdentity: function chkLoginIdentity() {
    var flag = 0;
    var host = window.location.host;
    if (host.indexOf('supplier') != -1) {
      // 供应商
      flag = 1;
    } else if (host.indexOf('shop') != -1) {
      // 商户
      flag = 2;
    }
    return flag;
  },
  // 修改页面标题
  setTitle: function setTitle(title) {
    document.title = title;
  },
  // 根据host修改页面标题
  setTitleByHost: function setTitleByHost() {
    var title = '管理系统';
    var identity = this.chkLoginIdentity();
    if (identity == 1) {
      title = '供应商后台管理系统';
    } else if (identity == 2) {
      title = '商户后台管理系统';
    }
    this.setTitle(title);
  },
  openWindow: function openWindow(url) {
    window.open(url, '_blank');
  },
  // 深度克隆
  deepClone: function deepClone(source) {
    return JSON.parse(JSON.stringify(source));
  },
  // 整数过滤
  filterNum: function filterNum(val) {
    return val.replace(/[^[0-9-]*/g, '');
  },
  // 浮点数过滤
  filterFloat: function filterFloat(val) {
    return val.replace(/[^[0-9.-]*/g, '');
  }
};
exports.func = func;