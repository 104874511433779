var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "info_config_page" },
    [
      _c(
        "div",
        { staticClass: "common_form_search" },
        [
          _c(
            "el-form",
            {
              ref: "searchForm",
              attrs: { inline: true, model: _vm.searchForm }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "页面名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入页面名称", clearable: "" },
                    model: {
                      value: _vm.searchForm.title,
                      callback: function($$v) {
                        _vm.$set(_vm.searchForm, "title", $$v)
                      },
                      expression: "searchForm.title"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.search()
                        }
                      }
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.reset()
                        }
                      }
                    },
                    [_vm._v("重置")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "div",
        { staticClass: "flex align-center table-top-btns" },
        [
          _c(
            "h5",
            { staticClass: "flex align-center" },
            [
              _c("svg-icon", {
                staticStyle: { "margin-right": "15px" },
                attrs: { "icon-class": "list", size: 15 }
              }),
              _vm._v("数据列表\n            ")
            ],
            1
          ),
          _vm.$hasMethod("#add")
            ? _c(
                "el-button",
                {
                  staticClass: "add-btn",
                  attrs: { size: "small", icon: "el-icon-plus" },
                  on: { click: _vm.addLink }
                },
                [_vm._v("新增")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, border: "" } },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", prop: "id", width: "60" }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "页面名称",
              prop: "title",
              width: "180"
            }
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "跳转链接" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "path" }, [
                      _vm._v("链接说明: " + _vm._s(scope.row.path))
                    ]),
                    _c("div", { staticClass: "path" }, [
                      _vm._v("链接示例: "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "clipboard",
                              rawName: "v-clipboard:copy",
                              value: scope.row.path_example,
                              expression: "scope.row.path_example",
                              arg: "copy"
                            },
                            {
                              name: "clipboard",
                              rawName: "v-clipboard:success",
                              value: _vm.copySuccess,
                              expression: "copySuccess",
                              arg: "success"
                            }
                          ],
                          staticClass: "blue-font pointer"
                        },
                        [_vm._v(_vm._s(scope.row.path_example))]
                      )
                    ]),
                    _c("div", { staticClass: "path" }, [
                      _vm._v("支付宝示例: "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "clipboard",
                              rawName: "v-clipboard:copy",
                              value: _vm.alipayExample(scope.row.path_example),
                              expression:
                                "alipayExample(scope.row.path_example)",
                              arg: "copy"
                            },
                            {
                              name: "clipboard",
                              rawName: "v-clipboard:success",
                              value: _vm.copySuccess,
                              expression: "copySuccess",
                              arg: "success"
                            }
                          ],
                          staticClass: "blue-font pointer"
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.alipayExample(scope.row.path_example))
                          )
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "path" }, [
                      _vm._v("支付宝h5示例: "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "clipboard",
                              rawName: "v-clipboard:copy",
                              value: _vm.alipayH5Example(
                                scope.row.path_example
                              ),
                              expression:
                                "alipayH5Example(scope.row.path_example)",
                              arg: "copy"
                            },
                            {
                              name: "clipboard",
                              rawName: "v-clipboard:success",
                              value: _vm.copySuccess,
                              expression: "copySuccess",
                              arg: "success"
                            }
                          ],
                          staticClass: "blue-font pointer"
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.alipayH5Example(scope.row.path_example))
                          )
                        ]
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "图片示例", width: "280" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "img-list" },
                      _vm._l(scope.row.images, function(item) {
                        return item
                          ? _c("preview-img", {
                              attrs: { "img-url": item, width: 50, height: 50 }
                            })
                          : _vm._e()
                      }),
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("addLink", {
        ref: "addLink",
        attrs: {
          handleDialog: _vm.handleDialog,
          dialogTitle: _vm.dialogTitle,
          isEdit: _vm.isEdit,
          formData: _vm.formData
        },
        on: { submitForm: _vm.submitForm, closeDialog: _vm.closeDialog }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }