var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c(
      "div",
      {},
      _vm._l(_vm.shopList, function(item, index) {
        return _c("div", { staticClass: "icon_form" }, [
          _c("div", { staticClass: "flex flex-between-center header" }, [
            _c("span", { staticStyle: { color: "#666666" } }, [
              _vm._v("门店 " + _vm._s(index + 1))
            ]),
            _c("p", { staticClass: "tools" }, [
              _c(
                "span",
                {
                  staticClass: "pointer",
                  on: {
                    click: function($event) {
                      return _vm.upSort(index)
                    }
                  }
                },
                [_c("i", { staticClass: "el-icon-top" })]
              ),
              _c(
                "span",
                {
                  staticClass: "pointer",
                  on: {
                    click: function($event) {
                      return _vm.downSort(index)
                    }
                  }
                },
                [_c("i", { staticClass: "el-icon-bottom" })]
              ),
              _c(
                "span",
                {
                  staticClass: "pointer",
                  on: {
                    click: function($event) {
                      return _vm.deleteShop(index)
                    }
                  }
                },
                [_c("i", { staticClass: "el-icon-delete" })]
              )
            ])
          ]),
          _c("div", { staticClass: "input_wrapper" }, [
            _c("div", { staticClass: "flex" }, [
              _vm._m(0, true),
              _c(
                "div",
                { staticClass: "flex-1" },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入门店名称", clearable: "" },
                    model: {
                      value: item.store_name,
                      callback: function($$v) {
                        _vm.$set(item, "store_name", $$v)
                      },
                      expression: "item.store_name"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "flex" }, [
              _vm._m(1, true),
              _c(
                "div",
                { staticClass: "flex-1" },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入门店价格", clearable: "" },
                    on: {
                      input: function(val) {
                        return (item.price = _vm.$func.filterFloat(val))
                      }
                    },
                    model: {
                      value: item.price,
                      callback: function($$v) {
                        _vm.$set(item, "price", $$v)
                      },
                      expression: "item.price"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "flex" }, [
              _vm._m(2, true),
              _c(
                "div",
                { staticClass: "flex-1" },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: item.desc,
                        callback: function($$v) {
                          _vm.$set(item, "desc", $$v)
                        },
                        expression: "item.desc"
                      }
                    },
                    _vm._l(_vm.options, function(optionsItem) {
                      return _c("el-option", {
                        key: optionsItem,
                        attrs: { label: optionsItem, value: optionsItem }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "flex" }, [
              _vm._m(3, true),
              _c(
                "div",
                { staticClass: "flex-1" },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入对比金额", clearable: "" },
                    on: {
                      input: function(val) {
                        return (item.compare_save = _vm.$func.filterFloat(val))
                      }
                    },
                    model: {
                      value: item.compare_save,
                      callback: function($$v) {
                        _vm.$set(item, "compare_save", $$v)
                      },
                      expression: "item.compare_save"
                    }
                  })
                ],
                1
              )
            ])
          ])
        ])
      }),
      0
    ),
    _vm.shopList.length < _vm.goodsNum
      ? _c(
          "div",
          {
            staticClass: "add-div",
            on: {
              click: function($event) {
                return _vm.addShop()
              }
            }
          },
          [
            _c("i", { staticClass: "el-icon-plus" }),
            _vm._v(
              "\n        添加门店(" +
                _vm._s(_vm.shopList.length) +
                "/" +
                _vm._s(_vm.goodsNum) +
                ")\n    "
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "flex align-center justify-center" }, [
      _c("span", { staticClass: "required-icon" }, [_vm._v("*")]),
      _vm._v("\n                        名称\n                    ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "flex align-center justify-center" }, [
      _c("span", { staticClass: "required-icon" }, [_vm._v("*")]),
      _vm._v("\n                        价格\n                    ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "flex align-center justify-center" }, [
      _c("span", { staticClass: "required-icon" }, [_vm._v("*")]),
      _vm._v("\n                        对比说明\n                    ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "flex align-center justify-center" }, [
      _c("span", { staticClass: "required-icon" }, [_vm._v("*")]),
      _vm._v("\n                        对比金额\n                    ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }