var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.dialog.title,
        visible: _vm.dialogVisible,
        width: "500px",
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        { ref: "formData", attrs: { model: _vm.formData, rules: _vm.rules } },
        [
          _c(
            "el-form-item",
            {
              attrs: { label: "备注：", prop: "memo", "label-width": "120px" }
            },
            [
              _c("el-input", {
                attrs: { type: "textarea", rows: 3, placeholder: "请输入备注" },
                model: {
                  value: _vm.formData.memo,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "memo", $$v)
                  },
                  expression: "formData.memo"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.submit()
                }
              }
            },
            [_vm._v("提交")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", plain: "" },
              on: {
                click: function($event) {
                  return _vm.close()
                }
              }
            },
            [_vm._v("关闭")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }