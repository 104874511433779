import { render, staticRenderFns } from "./showAreaDetail.vue?vue&type=template&id=5ac9250c&scoped=true&"
import script from "./showAreaDetail.vue?vue&type=script&lang=js&"
export * from "./showAreaDetail.vue?vue&type=script&lang=js&"
import style0 from "./showAreaDetail.vue?vue&type=style&index=0&id=5ac9250c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ac9250c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\www\\ssz_gold_backend\\multi_merchant_web\\gold_supplier\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5ac9250c')) {
      api.createRecord('5ac9250c', component.options)
    } else {
      api.reload('5ac9250c', component.options)
    }
    module.hot.accept("./showAreaDetail.vue?vue&type=template&id=5ac9250c&scoped=true&", function () {
      api.rerender('5ac9250c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/order/dialogs/showAreaDetail.vue"
export default component.exports