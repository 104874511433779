"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _finance = require("@/api/finance");
var _order = require("@/api/order");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ['item'],
  data: function data() {
    return {
      form: {
        order_id: 0,
        status: 0
      },
      rules: {
        // remark: [
        //   { required: true, message: '备注不能为空', trigger: 'blur' }
        // ]
      },
      dialogVisible: false
    };
  },
  methods: {
    submit: function submit() {
      var _this = this;
      this.form.order_id = this.item.order_id;
      (0, _order.orderHasSue)(this.form).then(function (res) {
        _this.$emit("getList");
        _this.dialogVisible = false;
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.form.status = this.item.is_sue;
        this.form.order_id = this.item.order_id;
      }
    }
  }
};
exports.default = _default;