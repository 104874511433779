"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _xcx = require("@/api/xcx");
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    cropUpload: _cropUpload.default
  },
  data: function data() {
    return {
      pageType: '办公设备',
      page_title: '',
      page_id: 15,
      formData: {
        level: 'first_level',
        main_title: '',
        background_image: '',
        background_color: '#FFFFFF',
        param: {},
        param_plus: []
      },
      param: {
        key: '',
        title: '',
        appId: '',
        path: 'pages/index/index',
        delay: 0,
        query: ''
      },
      imgWidth: 750,
      imgHeight: 1500,
      photoWidth: 359
    };
  },
  computed: {
    photoHeight: function photoHeight() {
      var height = this.photoWidth * this.imgHeight / this.imgWidth;
      return height;
    }
  },
  mounted: function mounted() {
    this.pageType = this.$route.meta.title;
    this.page_title = this.$route.meta.title;
    this.getData();
  },
  beforeRouteUpdate: function beforeRouteUpdate(to, from, next) {
    this.pageType = to.$route.meta.title;
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this = this;
      (0, _xcx.getActivityPageById)(this.page_id).then(function (res) {
        if (res.details) {
          var details = JSON.parse(res.details);
          var data = details.first_level;
          _this.formData.main_title = data.main_title;
          _this.formData.background_color = data.background_color;
          _this.formData.background_image = data.background_image;
          if (data.param) {
            _this.formData.param = data.param;
          } else {
            _this.formData.param = _this.$func.deepClone(_this.param);
          }
          if (data.param_plus) {
            _this.formData.param_plus = data.param_plus;
          }
        }
      });
    },
    addParam: function addParam() {
      var param = this.$func.deepClone(this.param);
      this.formData.param_plus.push(param);
    },
    delParam: function delParam(index) {
      this.formData.param_plus.splice(index, 1);
    },
    // 保存
    save: function save() {
      var _this2 = this;
      var filter = this.formData.param_plus.filter(function (item) {
        return item.key == '';
      });
      if (filter.length) {
        this.$message.error('key不能为空');
        return;
      }
      (0, _xcx.editActivityPage)((0, _objectSpread2.default)({
        type: this.page_id,
        page_title: this.page_title
      }, this.formData)).then(function (res) {
        _this2.submit();
      });
    },
    // 发布
    submit: function submit() {
      var _this3 = this;
      (0, _xcx.release)(this.page_id).then(function (res) {
        _this3.getData();
      });
    }
  }
};
exports.default = _default;