var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "btn" },
        [
          _c(
            "el-button",
            {
              attrs: { plain: "", type: "primary" },
              on: { click: _vm.addRow }
            },
            [_vm._v("添加")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticStyle: { width: "100%" },
          attrs: {
            "header-cell-style": _vm.headerCellStyle,
            "cell-style": _vm.headerCellStyle,
            border: "",
            data: _vm.tableData
          },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", { attrs: { prop: "name", label: "名称" } }),
          _c("el-table-column", { attrs: { prop: "price", label: "价格" } }),
          _c("el-table-column", {
            attrs: { prop: "ico", label: "图标" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-image",
                      {
                        staticStyle: { width: "60px", height: "60px" },
                        attrs: {
                          src: scope.row.ico
                            ? _vm.$globalObj.imgBaseUrl + scope.row.ico
                            : "",
                          "preview-src-list": [
                            _vm.$globalObj.imgBaseUrl + scope.row.ico
                          ]
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "image-slot",
                            attrs: { slot: "error" },
                            slot: "error"
                          },
                          [_c("i", { staticClass: "el-icon-picture-outline" })]
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "create_time", label: "创建时间" }
          }),
          _c("el-table-column", {
            attrs: { prop: "update_time", label: "修改时间" }
          }),
          _c("el-table-column", {
            attrs: { "min-width": "180", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", plain: true, size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.editRow(scope.$index)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger", plain: true, size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.removeRow(scope.row)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: "添加/编辑",
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "main_content" },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    "label-position": "right",
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "80px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "35%",
                        label: "名称",
                        prop: "name"
                      }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.name,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "35%",
                        label: "价格",
                        prop: "price"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "number" },
                        model: {
                          value: _vm.form.price,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "price", _vm._n($$v))
                          },
                          expression: "form.price"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "35%",
                        label: "图标",
                        prop: "imgurl"
                      }
                    },
                    [
                      _c("crop-upload", {
                        ref: "coverUpload",
                        attrs: {
                          "show-type": "photo",
                          fixScale: true,
                          "is-square": true,
                          "always-crop": true,
                          fixedBox: false,
                          "init-val": _vm.submitForm.imageUrl,
                          "wrapper-width": "120px",
                          "wrapper-height": "120px",
                          "photo-width": 120,
                          "photo-height": 120,
                          autoCropWidth: 150,
                          autoCropHeight: 150,
                          cropperWidth: "400px",
                          cropperHeight: "400px",
                          dialogWidth: "600px",
                          "min-width": 150,
                          "min-height": 150,
                          enlarge: 5
                        },
                        on: { setVal: _vm.setUrl }
                      }),
                      _c("div", { staticClass: "color-999" }, [
                        _vm._v(
                          "\n                        注：黄金品类的图标，建议尺寸为150*150px\n                    "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveRow } },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }