"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _marketing = require("@/api/marketing");
var _buy = _interopRequireDefault(require("./components/buy"));
var _recharge = _interopRequireDefault(require("./components/recharge"));
var _set = _interopRequireDefault(require("./components/set"));
var _myDatePicker = _interopRequireDefault(require("@/components/myDatePicker"));
var _customPage = _interopRequireDefault(require("@/components/customPage"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    buyDialog: _buy.default,
    rechargeDialog: _recharge.default,
    setDialog: _set.default,
    myDatePicker: _myDatePicker.default,
    customPage: _customPage.default
  },
  data: function data() {
    return {
      activeName: 'set',
      sms_num: 0,
      sms_automatic: 0,
      shoper_sms: [],
      user_sms: [],
      smsType: 1,
      selectId: '',
      multiple: false,
      searchForm: {
        mobile: '',
        template_id: '',
        send_status: '',
        create_time: ''
      },
      templateList: [],
      tableData: [],
      total: 0
    };
  },
  methods: {
    goToOrder: function goToOrder(orderId) {
      if (orderId) {
        this.$router.push({
          name: 'orderList',
          params: {
            orderId: orderId
          }
        });
      }
    },
    changeNumberInput: function changeNumberInput(value) {
      var pattern = /^[1-9][0-9]*$/;
      if (!pattern.test(value)) {
        // input 框绑定的内容为空
        this.searchForm.mobile = '';
      }
    },
    openSet: function openSet(id, type) {
      if (!this.$hasMethod('#set') && id) {
        return;
      }
      if (id) {
        this.multiple = false;
        this.selectId = id + '';
        this.smsType = 1;
      } else {
        this.multiple = true;
        this.selectId = '';
        this.smsType = type;
      }
      this.$refs.setDialog.dialogVisible = true;
    },
    // 去购买
    toBuy: function toBuy() {
      this.$refs.buyDialog.dialogVisible = true;
    },
    // 自动充值
    autoRecharge: function autoRecharge() {
      var _this = this;
      if (this.sms_automatic == 1) {
        // 关闭
        this.$confirm('确认要关闭自动充值吗?', '关闭自动充值', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(function () {
          (0, _marketing.autoRecharge)({
            baseline: '',
            recharge_num: '',
            recharge_type: 2,
            sms_remind: ''
          }).then(function (res) {
            _this.getData();
          });
        }).catch(function () {});
      } else {
        // 开启
        this.$refs.rechargeDialog.dialogVisible = true;
      }
    },
    // 购买记录
    checkRecords: function checkRecords() {
      this.$router.push({
        name: 'serviceMessageRecord'
      });
    },
    handleClick: function handleClick(tab, event) {
      this.getData();
    },
    // 查询
    search: function search() {
      var pickerVal = this.$refs.myDatePicker.getVal();
      if (pickerVal !== 'error') {
        this.searchForm.create_time = pickerVal;
        this.$refs.customPage.page = 1;
        this.getData();
      }
    },
    // 重置
    reset: function reset() {
      if (this.$refs.myDatePicker) {
        this.$refs.myDatePicker.reset();
      }
      this.searchForm = {
        mobile: '',
        template_id: '',
        send_status: '',
        create_time: ''
      };
      this.getData();
    },
    getData: function getData() {
      var _this2 = this;
      if (this.activeName === 'set') {
        (0, _marketing.smsRemind)().then(function (res) {
          var sms_num = res.sms_num,
            sms_automatic = res.sms_automatic,
            shoper_sms = res.shoper_sms,
            user_sms = res.user_sms;
          _this2.sms_num = sms_num;
          _this2.sms_automatic = sms_automatic;
          _this2.shoper_sms = shoper_sms || [];
          _this2.user_sms = user_sms || [];
        });
      } else {
        this.getSelect();
        var _this$$refs$customPag = this.$refs.customPage,
          page = _this$$refs$customPag.page,
          pageSize = _this$$refs$customPag.pageSize;
        (0, _marketing.smsRecord)((0, _objectSpread2.default)({
          page: page,
          pageSize: pageSize
        }, this.searchForm)).then(function (res) {
          var data = res.data,
            total = res.total;
          _this2.tableData = data || [];
          _this2.total = total || 0;
        });
      }
    },
    getSelect: function getSelect() {
      var _this3 = this;
      (0, _marketing.getSmsTemplate)().then(function (res) {
        _this3.templateList = res || [];
      });
    }
  },
  mounted: function mounted() {
    this.getData();
  }
};
exports.default = _default;