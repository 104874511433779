"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _auth = require("@/utils/auth");
var _account = require("@/api/account");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      balance: 0,
      amount: ''
    };
  },
  methods: {
    changeMoneyInput: function changeMoneyInput() {
      var pattern = /^[0-9]+([.]{1}[0-9]{1,2})?$/; // 正数的正则表达式
      // 不符合正整数时
      if (!pattern.test(this.amount)) {
        // input 框绑定的内容为空
        this.amount = '';
      }
    },
    getData: function getData() {
      var _this = this;
      (0, _account.myAccount)().then(function (res) {
        _this.balance = parseFloat(res.balance).toFixed(2);
      });
    },
    submit: function submit() {
      var _this2 = this;
      if (!this.amount) {
        return;
      }
      (0, _account.recharge)(this.amount).then(function (res) {
        var out_trade_no = res.out_trade_no,
          qr_code = res.qr_code,
          log_id = res.log_id;
        sessionStorage.setItem('amount', _this2.amount);
        sessionStorage.setItem('out_trade_no', out_trade_no);
        sessionStorage.setItem('qr_code', qr_code);
        sessionStorage.setItem('log_id', log_id);
        _this2.$router.push({
          name: 'rechargeCode'
        });
      });
    }
  },
  mounted: function mounted() {
    this.getData();
  },
  computed: {
    userName: function userName() {
      return (0, _auth.getName)();
    }
  }
};
exports.default = _default;