"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _goods = require("@/api/goods");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      dialogVisible: false,
      tableData: [],
      editId: null
    };
  },
  methods: {
    handleChange: function handleChange(row) {
      var value = row.add ? parseInt(row.quantity) + (row.type === 'add' ? parseInt(row.add) : -parseInt(row.add)) : '';
      this.$set(row, 'stock', value);
    },
    changeNumberInput: function changeNumberInput(row, value) {
      var pattern = /^[1-9][0-9]*$/; // 正整数的正则表达式
      // 不符合正整数时
      if (!pattern.test(value)) {
        // input 框绑定的内容为空
        row.add = '';
      }
    },
    submit: function submit() {
      var _this = this;
      var setmeal = this.tableData.map(function (item) {
        return {
          id: item.id,
          stock: item.stock || item.quantity
        };
      });
      (0, _goods.editGoodsStock)({
        goods_id: this.editId,
        setmeal: setmeal
      }).then(function (res) {
        _this.dialogVisible = false;
        _this.$emit('getList', true);
      });
    },
    getData: function getData() {
      var _this2 = this;
      (0, _goods.getSetmealStock)(this.editId).then(function (res) {
        _this2.tableData = res ? res.map(function (item) {
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, item), {}, {
            type: 'add',
            add: ''
          });
        }) : [];
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.getData();
      }
    }
  }
};
exports.default = _default;