"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _order = require("@/api/order");
var _finance = require("@/api/finance");
var _deductLog = _interopRequireDefault(require("@/components/dialogs/deductLog"));
var _setWithhold = _interopRequireDefault(require("./setWithhold"));
var _changeLease = _interopRequireDefault(require("./changeLease"));
var _withholdDeposit = _interopRequireDefault(require("./withholdDeposit"));
var _leaseRepayment = _interopRequireDefault(require("./leaseRepayment"));
var _setSplit = _interopRequireDefault(require("./setSplit"));
var _mayi = require("@/api/mayi");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ['orderId', 'orderStatus', 'editItem'],
  components: {
    deductLog: _deductLog.default,
    setWithhold: _setWithhold.default,
    withholdDeposit: _withholdDeposit.default,
    leaseRepayment: _leaseRepayment.default,
    setSplit: _setSplit.default,
    changeLease: _changeLease.default
  },
  data: function data() {
    return {
      loading: false,
      dialogVisible: false,
      isFullscreen: false,
      tableData: [],
      selectedItem: {},
      selectedId: 0,
      searchForm: {
        leaseStatus: ""
      },
      selectList: [{
        id: -1,
        name: "全部"
      }, {
        id: 1,
        name: "已支付"
      }, {
        id: 2,
        name: "未支付"
      }],
      allSuccessAmount: 0,
      allErrorAmount: 0
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.isFullscreen = false;
        this.getLease();
      }
    }
  },
  methods: {
    getLease: function getLease() {
      var _this = this;
      this.loading = true;
      // this.tableData = []
      (0, _order.getLease)({
        orderId: this.orderId,
        status: this.searchForm.leaseStatus
      }).then(function (res) {
        _this.tableData = res.data || [];
        _this.allSuccessAmount = parseFloat(res.allSuccessAmount).toFixed(2);
        _this.allErrorAmount = parseFloat(res.allErrorAmount).toFixed(2);
        _this.loading = false;
      });
    },
    getTagLabel: function getTagLabel(status) {
      var label = '';
      switch (status) {
        case 1:
          label = '已支付';
          break;
        case 2:
          label = '已退款';
          break;
        case 3:
          label = '已买断';
          break;
        case 4:
          label = '已逾期';
          break;
        case 5:
          label = '线下支付';
          break;
        case 6:
          label = '审核中';
          break;
        case 7:
          label = '审核被拒';
          break;
        case 8:
          label = '代扣失败';
          break;
        default:
          label = '未支付';
      }
      return label;
    },
    getTagType: function getTagType(status) {
      var type = 'info';
      if ([1, 5].indexOf(status) > -1) {
        type = 'success';
      } else if ([6].indexOf(status) > -1) {
        type = 'warning';
      } else if ([4, 7, 8].indexOf(status) > -1) {
        type = 'danger';
      } else if ([2, 3].indexOf(status) > -1) {
        type = '';
      }
      return type;
    },
    switchChange: function switchChange(row) {
      var _this2 = this;
      (0, _finance.withholdSwitch)({
        orderId: row.order_id,
        leaseId: row.id,
        withholdStatus: row.withhold_status
      }).then(function (res) {
        _this2.$parent.getList(true);
      });
    },
    viewLog: function viewLog(row) {
      this.selectedItem = (0, _objectSpread2.default)({}, row);
      this.$refs.deductLog.dialogVisible = true;
    },
    // 申请扣款
    openWithhold: function openWithhold(row) {
      this.selectedItem = (0, _objectSpread2.default)({}, row);
      this.$refs.setWithhold.dialogVisible = true;
      return;
      // this.$confirm("您确定要申请扣款吗？", "申请扣款", {
      // 	confirmButtonText: '确定',
      // 	cancelButtonText: '取消'
      // }).then(() => {
      // 	applyWithhold({
      // 		order_id: row.order_id,
      // 		lease_id: row.id,
      // 	}).then(res => {
      // 		this.getLease()
      // 		setTimeout(() => {
      // 			this.$parent.getList(true);
      // 		}, 1000);
      // 	})
      // }).catch(() => {});
    },
    refresh: function refresh() {
      var _this3 = this;
      setTimeout(function () {
        _this3.getLease();
        _this3.$parent.getList(true);
      }, 2000);
    },
    // 发起扣款
    withholdItem: function withholdItem(row) {
      var _this4 = this;
      console.log('row2', row);
      this.$confirm("您确定要发起扣款吗？", "发起扣款", {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _finance.manualWithholding)({
          orderId: row.order_id,
          leaseId: row.id
        }).then(function (res) {
          _this4.getLease();
          setTimeout(function () {
            _this4.$parent.getList(true);
          }, 1000);
        });
      }).catch(function () {});
    },
    // 线下还款
    payOffline: function payOffline(item) {
      this.selectedItem = (0, _objectSpread2.default)({}, item);
      this.$refs.leaseRepayment.dialogVisible = true;
      return;
    },
    paySplit: function paySplit(row) {
      var _this5 = this;
      this.$confirm("注意:拆分账单后，将影响本订单的征信相关功能，请谨慎操作作!!!", "账单拆分", {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        distinguishCancelAndClose: true
      }).then(function () {
        _this5.selectedItem = (0, _objectSpread2.default)({}, row);
        _this5.$refs.setSplit.dialogVisible = true;
      }).catch(function () {});
    },
    clearWithholdCache: function clearWithholdCache(row) {
      var _this6 = this;
      this.$confirm("注意:该操作有可能会导致程序扣钱、代扣、用户支付同时进行，请先确认安全", "取消支付5分钟限制", {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        distinguishCancelAndClose: true
      }).then(function () {
        (0, _order.clearWithholdCacheApi)({
          leaseId: row.id
        }).then(function (res) {
          _this6.getLease();
          setTimeout(function () {
            _this6.$parent.getList(true);
          }, 1000);
        });
      }).catch(function () {});
    },
    mayiOrderRentalOne: function mayiOrderRentalOne(row) {
      var _this7 = this;
      this.$confirm("您确定要上链租期信息吗？", "上链信息", {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _mayi.maYiLeaseRentalCreateOne)({
          order_id: row.order_id,
          lease_id: row.id
        }).then(function (res) {
          _this7.getLease();
          setTimeout(function () {
            _this7.$parent.getList(true);
          }, 1000);
        });
      }).catch(function () {});
    },
    toggleFullscreen: function toggleFullscreen() {
      this.isFullscreen = !this.isFullscreen;
    },
    withholdDeposit: function withholdDeposit(order_id) {
      this.selectedId = order_id;
      this.$refs.withholdDeposit.dialogVisible = true;
      return;
    },
    changeLease: function changeLease(row) {
      this.selectedItem = (0, _objectSpread2.default)({}, row);
      this.$refs.changeLease.dialogVisible = true;
      return;
    }
  }
};
exports.default = _default;