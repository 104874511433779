var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "flex align-center table-top-btns" },
        [
          _c(
            "h5",
            { staticClass: "flex align-center" },
            [
              _c("svg-icon", {
                staticStyle: { "margin-right": "15px" },
                attrs: { "icon-class": "list", size: 15 }
              }),
              _vm._v("数据列表\n        ")
            ],
            1
          ),
          _vm.$hasMethod("#add")
            ? _c(
                "el-button",
                {
                  staticClass: "add-btn",
                  attrs: { size: "small", icon: "el-icon-plus" },
                  on: {
                    click: function($event) {
                      return _vm.openAddDialog()
                    }
                  }
                },
                [_vm._v("添加")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tableData.length > -1,
              expression: "tableData.length > -1"
            }
          ],
          attrs: { data: _vm.tableData, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", prop: "id", label: "id", width: "70" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "title", label: "标题" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "version", label: "版本号" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "wgt_url", label: "更新包地址" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "apk_url", label: "安卓包链接" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "ios_url", label: "ios包链接" }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "is_force",
              label: "强制更新",
              width: "100"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.is_force
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("强制更新")
                        ])
                      : _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("不强制更新")
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "status",
              label: "状态",
              width: "80"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "pointer",
                        on: {
                          click: function($event) {
                            return _vm.changeStatus(scope.row)
                          }
                        }
                      },
                      [
                        scope.row.status == 1
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("启用")
                            ])
                          : _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("禁用")
                            ])
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              align: "center",
              label: "操作",
              width: "140"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.$hasMethod("#edit")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.openAddDialog(scope.row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    _vm.$hasMethod("#delete")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.removeItem(scope.row)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("custom-page", {
        ref: "customPage",
        attrs: { total: _vm.total },
        on: { getList: _vm.getList }
      }),
      _c("addDialog", {
        ref: "addDialog",
        attrs: { "edit-item": _vm.editItem },
        on: { getList: _vm.getList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }