"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ['editItem', 'merchantList'],
  data: function data() {
    return {
      form: {
        orderId: '',
        merchantId: 0
      },
      dialogVisible: false,
      rules: {
        merchantId: [{
          required: true,
          message: '请选择转单商户',
          trigger: 'blur'
        }]
      }
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.init();
      }
    }
  },
  methods: {
    init: function init() {
      this.form = {
        orderId: this.editItem.orderId,
        merchantId: this.editItem.merchantId
      };
    },
    submit: function submit() {
      var _this = this;
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          (0, _order.setDistributionOrder)(_this.form).then(function (res) {
            _this.$message.success("操作成功");
            _this.dialogVisible = false;
            _this.$emit('getList', true);
          }).catch(function () {});
        } else {
          _this.$message({
            message: "请检查表单错误",
            type: "error"
          });
          return false;
        }
      });
    }
  }
};
exports.default = _default;