"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.string.sub");
var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _order = require("@/api/order");
var _index = require("@/api/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ['editItem'],
  data: function data() {
    return {
      area: [],
      form: {
        id: 0,
        province_id: 0,
        city_id: 0,
        district_id: 0,
        province: '',
        city: '',
        district: '',
        street: '',
        risk_type: ''
      },
      rules: {
        risk_type: [{
          required: true,
          message: '请选择风险等级',
          trigger: 'change'
        }],
        province: [{
          required: true,
          message: '请选择省份',
          trigger: 'change'
        }]
      },
      dialogVisible: false,
      optionProps: {
        value: 'name',
        label: 'name',
        expandTrigger: 'hover',
        children: 'sub'
      },
      areaList: []
    };
  },
  computed: {
    dialogTitle: function dialogTitle() {
      return '风险地址 - ' + (this.form.id > 0 ? '编辑' : '添加');
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.init();
      } else {
        this.$refs.ruleForm.resetFields();
      }
    }
  },
  methods: {
    init: function init() {
      var _this = this;
      if (this.editItem.id > 0) {
        this.$nextTick(function () {
          _this.form = (0, _objectSpread2.default)({}, _this.editItem);
          _this.area = [_this.form.province, _this.form.city, _this.form.district];
        });
      } else {
        this.area = [];
      }
      this.getRegion();
    },
    getRegion: function getRegion() {
      var _this2 = this;
      (0, _index.getRegion)().then(function (res) {
        _this2.areaList = res ? res[0].sub : [];
        _this2.areaList.splice(0, 1);
        console.log('this.areaList', _this2.areaList);
      });
    },
    setArea: function setArea(e) {
      console.log('setArea', e);
      this.form.province = this.area[0];
      this.form.city = this.area[1];
      this.form.district = this.area[2];
    },
    changeArea: function changeArea(area) {
      console.log('changeArea', area);
    },
    submit: function submit() {
      var _this3 = this;
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          var params = (0, _objectSpread2.default)({}, _this3.form);
          (0, _order.editRiskArea)(params).then(function (res) {
            _this3.dialogVisible = false;
            _this3.$emit('getList', true);
          });
        }
      });
    }
  }
};
exports.default = _default;