"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _addRentAd = _interopRequireDefault(require("./addRentAd"));
var _shop = require("@/api/shop");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    customPage: _customPage.default,
    addRentAd: _addRentAd.default
  },
  data: function data() {
    return {
      tableData: [],
      total: 0,
      selectedId: ''
    };
  },
  methods: {
    openDialog: function openDialog(id) {
      this.selectedId = id || '';
      this.$refs.addRentAd.dialogVisible = true;
    },
    changeStatus: function changeStatus(id, status) {
      var _this = this;
      if (!this.$hasMethod("#enable")) {
        return;
      }
      var tip = "",
        title = "";
      if (status == 1) {
        tip = "您确定要禁用吗？";
        title = "禁用";
      } else {
        tip = "您确定要启用吗？";
        title = "启用";
      }
      this.$confirm(tip, title, {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _shop.addOrEditLeaseAddress)({
          id: id,
          status: status == 2 ? 1 : 2
        }).then(function (res) {
          _this.getList();
        });
      }).catch(function () {});
    },
    deleteItem: function deleteItem(id) {
      var _this2 = this;
      this.$confirm('您确定要删除吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _shop.delLeaseAddress)(id).then(function (res) {
          _this2.getList();
        });
      }).catch(function () {});
    },
    editItem: function editItem(id) {
      this.$router.push({
        name: 'shopAddReturnAd',
        params: {
          editId: id
        }
      });
    },
    getList: function getList() {
      var _this3 = this;
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      (0, _shop.leaseAddressList)({
        page: page,
        pageSize: pageSize
      }).then(function (res) {
        _this3.tableData = res.data || [];
        _this3.total = res.total || 0;
      });
    }
  },
  mounted: function mounted() {
    this.getList();
  }
};
exports.default = _default;