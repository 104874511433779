"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _info = require("@/api/info");
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _addSource = _interopRequireDefault(require("../source/addSource"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    customPage: _customPage.default,
    addSources: _addSource.default
  },
  data: function data() {
    return {
      tableData: [],
      dialogTitle: '',
      handleDialog: false,
      isEdit: true,
      total: 0,
      searchForm: {
        source_name: ''
      },
      sourceForm: {
        source_name: '',
        source_code: '',
        status: 1
      }
    };
  },
  methods: {
    search: function search() {
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      this.searchForm = Object.assign({});
      this.getList();
    },
    addSources: function addSources() {
      if (this.$refs.sourcesDlg.$refs.sourceForm) {
        this.$refs.sourcesDlg.$refs.sourceForm.resetFields();
      }
      this.dialogTitle = "新增订单来源";
      this.handleDialog = true;
      this.isEdit = true;
      this.sourceForm = {
        source_name: '',
        source_code: '',
        status: 1
      };
    },
    submitForm: function submitForm() {
      var _this = this;
      (0, _info.updateSource)(this.sourceForm).then(function (res) {
        _this.handleDialog = false;
        _this.getList();
      });
    },
    changeStatus: function changeStatus(id, status) {
      var _this2 = this;
      var title = status == 1 ? '禁用' : '启用';
      this.$confirm("确认要修改状态吗?", title, {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(function () {
        (0, _info.updateSource)({
          id: id,
          status: status == 1 ? 0 : 1
        }).then(function (res) {
          _this2.getList();
        });
      });
    },
    editItem: function editItem(row) {
      if (this.$refs.sourcesDlg.$refs.sourceForm) {
        this.$refs.sourcesDlg.$refs.sourceForm.resetFields();
      }
      this.dialogTitle = "编辑";
      this.handleDialog = true;
      this.isEdit = false;
      var form = (0, _objectSpread2.default)({}, row);
      delete form.source_code;
      delete form.status;
      this.sourceForm = form;
    },
    closeDialog: function closeDialog() {
      this.handleDialog = false;
    },
    // 获取列表
    getList: function getList() {
      var _this3 = this;
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      (0, _info.sourceList)({
        page: page,
        pageSize: pageSize,
        source_name: this.searchForm.source_name
      }).then(function (res) {
        _this3.tableData = res.data;
        _this3.total = res.total;
      });
    }
  },
  mounted: function mounted() {
    this.getList();
  }
};
exports.default = _default;