"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {},
  data: function data() {
    var checkNum = function checkNum(rule, value, callback) {
      var reg = /^[0-9]*$/;
      if (!value && value !== 0) {
        callback(new Error("请输入数字"));
      } else if (!reg.test(value) || value <= 0) {
        callback(new Error("请输入大于0的数字"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      formData: {
        limit_sec: 0,
        limit_count: 0,
        block_sec: 0,
        block_msg: "",
        status: "0"
      },
      rules: {
        limit_sec: [{
          required: true,
          message: "请输入限制时间",
          trigger: "blur"
        }, {
          validator: checkNum,
          trigger: "blur"
        }],
        limit_count: [{
          required: true,
          message: "请输入限制访问次数",
          trigger: "blur"
        }, {
          validator: checkNum,
          trigger: "blur"
        }]
      }
    };
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this = this;
      this.loading = true;
      (0, _system.getBlockIpConfig)().then(function (res) {
        if (res) {
          _this.formData = res;
        }
        _this.loading = false;
      });
    },
    chkNum: function chkNum(key) {
      this.formData[key] = this.$func.filterNum(this.formData[key]);
    },
    submit: function submit() {
      var _this2 = this;
      this.$refs.formData.validate(function (valid) {
        if (valid) {
          (0, _system.saveBlockIpConfig)(_this2.formData).then(function (res) {
            _this2.getData();
          });
        }
      });
    }
  }
};
exports.default = _default;