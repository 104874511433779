"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAccount = getAccount;
exports.getImgBaseUrl = getImgBaseUrl;
exports.getMemberId = getMemberId;
exports.getMerchantId = getMerchantId;
exports.getMobile = getMobile;
exports.getName = getName;
exports.getPhoto = getPhoto;
exports.getShopUserId = getShopUserId;
exports.getToken = getToken;
exports.removeAccount = removeAccount;
exports.removeImgBaseUrl = removeImgBaseUrl;
exports.removeMemberId = removeMemberId;
exports.removeMerchantId = removeMerchantId;
exports.removeName = removeName;
exports.removePhoto = removePhoto;
exports.removeShopUserId = removeShopUserId;
exports.removeToken = removeToken;
exports.setAccount = setAccount;
exports.setImgBaseUrl = setImgBaseUrl;
exports.setMemberId = setMemberId;
exports.setMerchantId = setMerchantId;
exports.setMobile = setMobile;
exports.setName = setName;
exports.setPhoto = setPhoto;
exports.setShopUserId = setShopUserId;
exports.setToken = setToken;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var TokenKey = "xxz_supplier_token";
var NameKey = "xxz_supplier_name";
var MerchantIdKey = "xxz_supplier_merchant_id";
var ShopUserIdKey = "xxz_supplier_shop_user_id";
var MemberIdKey = "xxz_supplier_member_id";
var ImgBaseUrlKey = "xxz_supplier_img_base_url";
var PhotoKey = "xxz_supplier_avatar";
var AccountKey = "xxz_supplier_account";
var mobileKey = "xxz_supplier_mobile";
function getToken() {
  return _jsCookie.default.get(TokenKey);
}
function setToken(token) {
  return _jsCookie.default.set(TokenKey, token);
}
function removeToken() {
  return _jsCookie.default.remove(TokenKey);
}
function getName() {
  return _jsCookie.default.get(NameKey);
}
function setName(name) {
  return _jsCookie.default.set(NameKey, name);
}
function getMobile() {
  return _jsCookie.default.get(mobileKey);
}
function setMobile(mobile) {
  return _jsCookie.default.set(mobileKey, mobile);
}
function removeName() {
  return _jsCookie.default.remove(NameKey);
}
function getMerchantId() {
  return _jsCookie.default.get(MerchantIdKey);
}
function setMerchantId() {
  var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return _jsCookie.default.set(MerchantIdKey, id);
}
function removeMerchantId() {
  return _jsCookie.default.remove(MerchantIdKey);
}
function getShopUserId() {
  return _jsCookie.default.get(ShopUserIdKey);
}
function setShopUserId(id) {
  return _jsCookie.default.set(ShopUserIdKey, id);
}
function removeShopUserId() {
  return _jsCookie.default.remove(ShopUserIdKey);
}
function getMemberId() {
  return _jsCookie.default.get(MemberIdKey);
}
function setMemberId(id) {
  return _jsCookie.default.set(MemberIdKey, id);
}
function removeMemberId() {
  return _jsCookie.default.remove(MemberIdKey);
}
function getImgBaseUrl() {
  return _jsCookie.default.get(ImgBaseUrlKey);
}
function setImgBaseUrl(id) {
  return _jsCookie.default.set(ImgBaseUrlKey, id);
}
function removeImgBaseUrl() {
  return _jsCookie.default.remove(ImgBaseUrlKey);
}
function getPhoto() {
  return _jsCookie.default.get(PhotoKey);
}
function setPhoto(url) {
  return _jsCookie.default.set(PhotoKey, url);
}
function removePhoto() {
  return _jsCookie.default.remove(PhotoKey);
}
function getAccount() {
  return _jsCookie.default.get(AccountKey);
}
function setAccount(no) {
  return _jsCookie.default.set(AccountKey, no);
}
function removeAccount() {
  return _jsCookie.default.remove(AccountKey);
}