"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _shop = require("@/api/shop");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    customPage: _customPage.default
  },
  data: function data() {
    return {
      searchForm: {
        title: ''
      },
      tableData: [],
      total: 0
    };
  },
  methods: {
    // 更改排序
    changeSort: function changeSort(id, val) {
      var _this = this;
      (0, _shop.editSort)({
        type: 'Goods',
        id: id,
        sort: val
      }).then(function (res) {
        _this.getList();
      });
    },
    search: function search() {
      this.$refs.customPage.page = 1;
      this.getList();
    },
    getList: function getList() {
      var _this2 = this;
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      (0, _shop.goodsSortList)((0, _objectSpread2.default)({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        _this2.tableData = res.data || [];
        _this2.total = res.total;
      });
    }
  },
  mounted: function mounted() {
    this.getList();
  }
};
exports.default = _default;