"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _info = require("@/api/info");
var _addCategory = _interopRequireDefault(require("../list/addCategory"));
var _addChild = _interopRequireDefault(require("../list/addChild"));
var _customPage = _interopRequireDefault(require("@/components/customPage"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    addCategory: _addCategory.default,
    addChild: _addChild.default,
    customPage: _customPage.default
  },
  data: function data() {
    return {
      tableData: [],
      firstLevel: [],
      parentVisible: false,
      childVisible: false,
      dialogTitle: '',
      total: 0,
      parentForm: {
        id: '',
        addType: 'parent',
        parentid: 0,
        name: '',
        image: '',
        link: '',
        banner_image: '',
        banner_link: '',
        enabled: 1,
        displayorder: ''
      },
      childForm: {
        id: '',
        addType: 'children',
        parentid: '',
        name: '',
        image: '',
        link: '',
        banner_image: '',
        banner_link: '',
        enabled: 1,
        displayorder: '',
        rent_category: '',
        // insurance_category: "",
        zlb_class: ''
      }
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    // 获取列表
    getList: function getList() {
      var _this = this;
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      (0, _info.categoryList)({
        page: page,
        pageSize: pageSize
      }).then(function (res) {
        _this.tableData = res.data;
        _this.total = res.total;
        res.data.forEach(function (item) {
          _this.firstLevel.push({
            id: item.id,
            name: item.name
          });
        });
      });
    },
    // 列表项删除
    removeItem: function removeItem(id) {
      var _this2 = this;
      this.$confirm('您确定要删除吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _info.delCategory)({
          id: id
        }).then(function (res) {
          _this2.getList();
        });
      });
    },
    handleEnable: function handleEnable(id, enabled) {
      var _this3 = this;
      this.$confirm('确认要修改状态吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        var status = enabled == 1 ? 0 : 1;
        (0, _info.updateCategory)({
          id: id,
          enabled: status
        }).then(function (res) {
          _this3.getList();
        });
      });
    },
    editItem: function editItem(row) {
      // if (this.$refs.cateForm.$refs.parentForm) {
      //   this.$refs.cateForm.$refs.parentForm.resetFields();
      // }
      var obj = (0, _objectSpread2.default)({}, row);
      if (row.parentid == 0) {
        this.dialogTitle = '编辑品类';
        this.parentVisible = true;
        this.parentForm = obj;
      } else {
        this.dialogTitle = '编辑子菜单';
        this.childVisible = true;
        this.childForm = obj;
      }
    },
    // 更改排序
    changeSort: function changeSort(id, num) {
      var _this4 = this;
      (0, _info.updateCategory)({
        id: id,
        displayorder: num
      }).then(function (res) {
        _this4.getList();
      });
    },
    setUrl: function setUrl(obj) {
      this.parentForm[obj.key] = obj.val;
    },
    setChildUrl: function setChildUrl(obj) {
      this.childForm[obj.key] = obj.val;
    },
    addCategorys: function addCategorys() {
      // if (this.$refs.cateForm.$refs.parentForm) {
      //   this.$refs.cateForm.$refs.parentForm.resetFields();
      // }
      // this.$nextTick(() => {
      //   this.$refs.cateForm.$refs.parentForm.resetFields();
      // })
      this.dialogTitle = '新增品类';
      this.parentForm = {
        id: '',
        addType: 'parent',
        parentid: 0,
        name: '',
        image: '',
        link: '',
        banner_image: '',
        banner_link: '',
        enabled: 1,
        displayorder: 0
      };
      this.parentVisible = true;
    },
    submitForm: function submitForm(val) {
      var _this5 = this;
      var firetForm = (0, _objectSpread2.default)({}, this.parentForm);
      var obj = {
        id: this.parentForm.id || '',
        addType: 'parent',
        parentid: 0
      };
      if (this.parentForm.id > 0) {
        delete firetForm.children;
        delete obj.addType;
      }
      firetForm.displayorder = firetForm.displayorder + '';
      (0, _info.updateCategory)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, obj), firetForm)).then(function (res) {
        _this5.parentVisible = false;
        _this5.getList();
      });
    },
    submitChildForm: function submitChildForm(val) {
      var _this6 = this;
      var obj = {
        id: this.childForm.id || '',
        addType: 'children'
      };
      if (obj.id) {
        delete obj.addType;
      }
      var params = (0, _objectSpread2.default)({}, this.childForm);
      params.displayorder = params.displayorder + '';
      (0, _info.updateCategory)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, obj), params)).then(function (res) {
        _this6.childVisible = false;
        _this6.getList();
      });
    },
    addItemChild: function addItemChild(row) {
      var pid = row.id;
      this.dialogTitle = '添加子菜单';
      this.childForm = {
        id: '',
        addType: 'children',
        parentid: '',
        name: '',
        image: '',
        link: '',
        banner_image: '',
        banner_link: '',
        enabled: 1,
        displayorder: 0
      };
      this.childForm.enabled = 1;
      this.childVisible = true;
      this.childForm['parentid'] = pid || '';
    },
    closeDialog: function closeDialog() {
      this.parentVisible = false;
      this.childVisible = false;
    }
  }
};
exports.default = _default;