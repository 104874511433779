import { render, staticRenderFns } from "./messageRecord.vue?vue&type=template&id=2739ecb4&scoped=true&"
import script from "./messageRecord.vue?vue&type=script&lang=js&"
export * from "./messageRecord.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2739ecb4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\www\\ssz_gold_backend\\multi_merchant_web\\gold_supplier\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2739ecb4')) {
      api.createRecord('2739ecb4', component.options)
    } else {
      api.reload('2739ecb4', component.options)
    }
    module.hot.accept("./messageRecord.vue?vue&type=template&id=2739ecb4&scoped=true&", function () {
      api.rerender('2739ecb4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/service/messageRecord.vue"
export default component.exports