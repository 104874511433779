"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("quill/dist/quill.core.css");
require("quill/dist/quill.snow.css");
require("quill/dist/quill.bubble.css");
var _vueQuillEditor = require("vue-quill-editor");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "textEditor",
  components: {
    quillEditor: _vueQuillEditor.quillEditor
  },
  props: {
    content: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      str: "",
      textLength: 0,
      editorOption: {
        placeholder: "请输入文章内容",
        modules: {
          toolbar: [["bold", "italic", "underline", "strike"],
          //加粗，斜体，下划线，删除线
          ["blockquote", "code-block"],
          //引用，代码块
          [{
            header: 1
          }, {
            header: 2
          }],
          // 标题，键值对的形式；1、2表示字体大小
          [{
            list: "ordered"
          }, {
            list: "bullet"
          }],
          //列表
          [{
            indent: "-1"
          }, {
            indent: "+1"
          }],
          // 缩进
          [{
            direction: "rtl"
          }],
          // 文本方向
          [{
            color: []
          }, {
            background: []
          }],
          // 字体颜色，字体背景颜色
          [{
            align: []
          }],
          //对齐方式
          ["clean"],
          //清除字体样式
          ["image", "video"] //上传图片、上传视频
          // [{ script: "sub" }, { script: "super" }], // 上下标
          // [{ size: ["small", false, "large", "huge"] }], // 字体大小
          // [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
          // [{ font: [] }], //字体
          ]
        }
      }
    };
  },

  methods: {
    onEditorChange: function onEditorChange(event) {
      this.textLength = event.text.length - 1;
      this.$emit("onEditorChange", event.html);
    } // 转码
    // escapeStringHTML(str) {
    //   str = str.replace(/&lt;/g, "<");
    //   str = str.replace(/&gt;/g, ">");
    //   return str;
    // },
  },
  computed: {
    editor: function editor() {
      return this.$refs.myQuillEditor.quill;
    }
  },
  mounted: function mounted() {}
};
exports.default = _default;