"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _shop = require("@/api/shop");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      loading: false,
      currId: 0,
      tmpList: []
    };
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      this.loading = true;
      this.getShopTemplateList();
      this.getShopTemplateId();
    },
    getShopTemplateList: function getShopTemplateList() {
      var _this = this;
      (0, _shop.getShopTemplateList)().then(function (res) {
        console.log('getShopTemplateList', res);
        _this.tmpList = res.data;
        _this.loading = false;
      });
    },
    getShopTemplateId: function getShopTemplateId() {
      var _this2 = this;
      (0, _shop.getShopTemplateId)().then(function (res) {
        if (res) {
          _this2.currId = res;
        }
      });
    },
    chooseTemplate: function chooseTemplate(item) {
      this.currId = item.id;
    },
    save: function save() {
      var _this3 = this;
      var param = {
        template_id: this.currId
      };
      this.loading = true;
      (0, _shop.saveShopTemplateId)(param).then(function (res) {
        _this3.loading = false;
      });
    }
  }
};
exports.default = _default;