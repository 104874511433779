"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _createForOfIteratorHelper2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/createForOfIteratorHelper.js"));
require("core-js/modules/es6.number.constructor");
var _marketing = require("@/api/marketing");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    selectId: {
      type: String
    },
    smsType: {
      type: Number,
      default: 1
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      dataList: []
    };
  },
  methods: {
    changeNumberInput: function changeNumberInput(item) {
      var pattern = /^[1-9][0-9]*$/; // 正整数的正则表达式
      // 不符合正整数时
      if (!pattern.test(item.send_mobile)) {
        // input 框绑定的内容为空
        item.send_mobile = '';
      }
    },
    submit: function submit() {
      var _this = this;
      if (this.multiple) {
        var list = [];
        var _iterator = (0, _createForOfIteratorHelper2.default)(this.dataList),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var item = _step.value;
            var id = item.id,
              is_open = item.is_open,
              send_mobile = item.send_mobile;
            var obj = {
              sms_template_id: id,
              status: is_open,
              send_mobile: send_mobile
            };
            list.push(obj);
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
        var jsonStr = JSON.stringify(list);
        (0, _marketing.setBatchSms)({
          list: jsonStr
        }).then(function (res) {
          _this.dialogVisible = false;
          _this.$emit('getList');
        });
      } else {
        var _this$dataList$ = this.dataList[0],
          _id = _this$dataList$.id,
          _send_mobile = _this$dataList$.send_mobile,
          _is_open = _this$dataList$.is_open;
        (0, _marketing.setSingleSms)({
          sms_template_id: _id,
          status: _is_open,
          send_mobile: _send_mobile
        }).then(function (res) {
          _this.dialogVisible = false;
          _this.$emit('getList');
        });
      }
    },
    getData: function getData() {
      var _this2 = this;
      if (this.multiple) {
        // 批量
        (0, _marketing.batchSmsInfo)(this.smsType).then(function (res) {
          _this2.dataList = res.sms_template || [];
          if (_this2.smsType == 2) {
            var _iterator2 = (0, _createForOfIteratorHelper2.default)(_this2.dataList),
              _step2;
            try {
              for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                var item = _step2.value;
                item.send_mobile = '';
              }
            } catch (err) {
              _iterator2.e(err);
            } finally {
              _iterator2.f();
            }
          }
        });
      } else {
        // 获取单个信息
        (0, _marketing.singleSmsInfo)(this.selectId).then(function (res) {
          if (res.sms_type == 2) {
            res.send_mobile = '';
          }
          _this2.dataList = [(0, _objectSpread2.default)({}, res)];
        });
      }
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.getData();
      } else {
        this.dataList = [];
      }
    }
  }
};
exports.default = _default;