"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _myDatePicker = _interopRequireDefault(require("@/components/myDatePicker"));
var _merchant = require("@/api/merchant");
var _feeDialog = _interopRequireDefault(require("./feeDialog"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    customPage: _customPage.default,
    myDatePicker: _myDatePicker.default,
    feeDialog: _feeDialog.default
  },
  data: function data() {
    return {
      searchForm: {
        orderNo: '',
        moneyFlow: '',
        flowType: '',
        serviceFeeType: '',
        createTime: ''
      },
      typeList: [{
        id: 1,
        label: '结算'
      }, {
        id: 2,
        label: '退款'
      }, {
        id: 3,
        label: '充值'
      }, {
        id: 4,
        label: '买断结算'
      }, {
        id: 6,
        label: '提现'
      }, {
        id: 7,
        label: '增值服务业务充值'
      }, {
        id: 9,
        label: '扣手续费'
      }, {
        id: 10,
        label: '退手续费'
      }],
      serviceFeeList: [],
      tableData: [],
      total: 0,
      amount: 0,
      day_amount: 0,
      service_fee: 0,
      withdrawal: 0,
      charge: 0,
      viewItem: {}
    };
  },
  mounted: function mounted() {
    this.getList();
    this.getServiceFeeList();
  },
  methods: {
    // 获取数据
    getList: function getList() {
      var _this = this;
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      (0, _merchant.reconciliationFlow)((0, _objectSpread2.default)({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        var _res$list = res.list,
          data = _res$list.data,
          total = _res$list.total,
          _res$count = res.count,
          amount = _res$count.amount,
          service_fee = _res$count.service_fee,
          withdrawal = _res$count.withdrawal,
          charge = _res$count.charge,
          day_amount = _res$count.day_amount;
        _this.tableData = data || [];
        _this.total = total || 0;
        _this.amount = parseFloat(amount) ? parseFloat(amount).toFixed(4) : '0.0000';
        _this.day_amount = parseFloat(day_amount) ? parseFloat(day_amount).toFixed(4) : '0.0000';
        _this.service_fee = parseFloat(service_fee) ? parseFloat(service_fee).toFixed(4) : '0.0000';
        _this.withdrawal = parseFloat(withdrawal).toFixed(4) || 0;
        _this.charge = parseFloat(charge).toFixed(4) || 0;
      });
    },
    getServiceFeeList: function getServiceFeeList() {
      var _this2 = this;
      (0, _merchant.getServiceFeeList)().then(function (res) {
        // console.log('res', res)
        _this2.serviceFeeList = res;
      }).catch(function (error) {});
    },
    openFeeDialog: function openFeeDialog(item) {
      if (item.service_fee > 0) {
        this.viewItem = item;
        this.$refs.feeDialog.dialogVisible = true;
      }
    },
    goToOrder: function goToOrder(orderId) {
      if (orderId) {
        this.$router.push({
          name: 'orderList',
          params: {
            orderId: orderId
          }
        });
      }
    },
    getTypeName: function getTypeName(id) {
      var obj = this.typeList.find(function (k) {
        return k.id == id;
      });
      return obj ? obj.label : '';
    },
    // 查询
    search: function search() {
      var pickerVal = this.$refs.myDatePicker.getVal();
      if (pickerVal !== 'error') {
        this.searchForm.createTime = pickerVal;
        this.$refs.customPage.page = 1;
        this.getList();
      }
    },
    // 重置
    reset: function reset() {
      if (this.$refs.myDatePicker) {
        this.$refs.myDatePicker.reset();
      }
      this.searchForm = {
        orderNo: '',
        moneyFlow: '',
        flowType: '',
        createTime: ''
      };
      this.getList();
    },
    // 导出
    exportFile: function exportFile() {
      var pickerVal = this.$refs.myDatePicker.getVal();
      if (pickerVal !== 'error') {
        this.searchForm.createTime = pickerVal;
        (0, _merchant.reconciliationExport)(this.searchForm);
      }
    }
  }
};
exports.default = _default;