"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _topBanner = _interopRequireDefault(require("./child/topBanner"));
var _nineGrid = _interopRequireDefault(require("./child/nineGrid"));
var _capsuleBanner = _interopRequireDefault(require("./child/capsuleBanner"));
var _thirdHot = _interopRequireDefault(require("./child/thirdHot"));
var _bannerCol = _interopRequireDefault(require("./child/bannerCol"));
var _bannerColThree = _interopRequireDefault(require("./child/bannerColThree"));
var _twoColGoods = _interopRequireDefault(require("./child/twoColGoods"));
var _secBanner = _interopRequireDefault(require("./child/secBanner"));
var _twoActivity = _interopRequireDefault(require("./child/twoActivity"));
var _category = _interopRequireDefault(require("./child/category"));
var _backgroundImg = _interopRequireDefault(require("./child/backgroundImg"));
var _goodsRecommend = _interopRequireDefault(require("./child/goodsRecommend"));
var _priceComparison = _interopRequireDefault(require("./child/priceComparison"));
var _imageLink = _interopRequireDefault(require("./child/imageLink"));
var _validate = require("@/utils/validate");
var _xcx = require("@/api/xcx");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    topBanner: _topBanner.default,
    capsuleBanner: _capsuleBanner.default,
    nineGrid: _nineGrid.default,
    thirdHot: _thirdHot.default,
    bannerCol: _bannerCol.default,
    twoColGoods: _twoColGoods.default,
    secBanner: _secBanner.default,
    twoActivity: _twoActivity.default,
    category: _category.default,
    backgroundImg: _backgroundImg.default,
    bannerColThree: _bannerColThree.default,
    goodsRecommend: _goodsRecommend.default,
    priceComparison: _priceComparison.default,
    imageLink: _imageLink.default
  },
  data: function data() {
    return {
      activeType: '',
      activeNum: 0,
      sectionList: [{
        type: 'goodsRecommend',
        title: '商品推荐',
        show: true,
        imgName: 'nine8.jpg'
      }, {
        type: 'priceComparison',
        title: '价格对比',
        show: true,
        imgName: 'nine4.jpg'
      }, {
        type: 'pictureShow',
        title: '图片展示',
        show: true,
        imgName: 'nine5.jpg'
      }
      // {
      //     type: 'nineGrid',
      //     title: '九宫格',
      //     show: true,
      //     imgName: '2.jpg',
      // },
      // {
      //     type: 'capsuleBanner',
      //     title: '胶囊轮播图',
      //     show: true,
      //     imgName: '3.jpg',
      // },
      // {
      //     type: 'thirdHot',
      //     title: '三栏式热门',
      //     show: true,
      //     imgName: '4.jpg',
      // },
      // {
      //     type: 'bannerCol',
      //     title: '轮播图+二栏式',
      //     show: true,
      //     imgName: '5.jpg',
      // },
      // {
      //     type: 'twoColGoods',
      //     title: '二栏式*2商品',
      //     show: true,
      //     imgName: '6.jpg',
      // },
      // {
      //     type: 'secBanner',
      //     title: '次级轮播图',
      //     show: true,
      //     imgName: '7.jpg',
      // },
      // {
      //     type: 'twoActivity',
      //     title: '二栏式活动',
      //     show: true,
      //     imgName: '8.jpg',
      // },
      // {
      //     type: 'category',
      //     title: '品类商品推荐',
      //     show: true,
      //     imgName: '9.jpg',
      // },
      // {
      //     type: 'backgroundImg',
      //     title: '头部搜索背景图(和轮播图互斥)',
      //     show: true,
      //     imgName: '10.jpg',
      // },
      // {
      //     type: 'bannerColThree',
      //     title: '轮播图+三栏式',
      //     show: true,
      //     imgName: '11.jpg',
      // },
      ],

      editData: null,
      submitData: null
    };
  },
  watch: {
    activeNum: function activeNum(val) {
      this.editData = null;
      this.submitData = null;
      this.getParams();
    }
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;
      (0, _xcx.previewIndex)().then(function (res) {
        var showTypes = res.map(function (k) {
          return k.type;
        });
        console.log('showTypes', showTypes);
        _this.sectionList.forEach(function (item, index) {
          if (showTypes.indexOf(item.type) === -1) {
            item.show = false;
          }
        });
        _this.activeType = _this.sectionList[0].type;
        _this.getParams();
      });
    },
    preview: function preview() {
      this.$router.push({
        name: 'xcxPosPreview'
      });
    },
    release: function release() {
      (0, _xcx.releaseOnline)().then(function (res) {});
    },
    // 点击首页推荐位
    clickSection: function clickSection(item, index) {
      // console.log('clickSection', { ...item }, index)
      this.activeType = item.type;
      this.activeNum = index;
    },
    getParams: function getParams() {
      var _this2 = this;
      var type = this.activeType;
      (0, _xcx.getParamsByType)(type).then(function (res) {
        if (res) {
          _this2.sectionList[_this2.activeNum].show = res.status == 2;
          if (!res.params) {
            return;
          }
          var data = JSON.parse(res.params);
          _this2.editData = data;
        }
      });
    },
    setSubmitData: function setSubmitData(data) {
      this.submitData = data;
    },
    save: function save() {
      var _this3 = this;
      var obj = {
        sort: this.activeNum,
        type: this.activeType,
        status: this.sectionList[this.activeNum].show ? 2 : 1,
        params: JSON.stringify(this.submitData)
      };
      (0, _xcx.saveParams)(obj).then(function (res) {
        _this3.getParams();
      });
    }
  }
};
exports.default = _default;