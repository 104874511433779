"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _account = require("@/api/account");
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _finance = require("@/api/finance");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    customPage: _customPage.default
  },
  data: function data() {
    return {
      balance: 0,
      frozen: 0,
      tableData: [],
      total: 0
    };
  },
  methods: {
    goToRecharge: function goToRecharge() {
      var routeData = this.$router.resolve({
        name: "recharge"
      });
      window.open(routeData.href, '_blank');
    },
    getData: function getData() {
      var _this = this;
      (0, _account.myAccount)().then(function (res) {
        var balance = res.balance,
          frozen = res.frozen;
        _this.balance = parseFloat(balance).toFixed(2);
        _this.frozen = parseFloat(frozen).toFixed(2);
      });
    },
    // 获取数据
    getList: function getList() {
      var _this2 = this;
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      (0, _finance.withdrawalList)({
        page: page,
        pageSize: pageSize,
        adminMobile: '',
        status: '',
        arrivalTime: ''
      }).then(function (res) {
        _this2.tableData = res.data || [];
        _this2.total = res.total || 0;
      });
    }
  },
  mounted: function mounted() {
    this.getData();
    this.getList();
  }
};
exports.default = _default;