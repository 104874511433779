var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "shop_code" }, [
    _c(
      "div",
      { staticClass: "warn-box" },
      [
        _c("svg-icon", {
          staticStyle: { "margin-right": "17px" },
          attrs: { "icon-class": "warn", size: 20 }
        }),
        _vm._m(0)
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "flex align-center" },
      [
        _c(
          "el-image",
          {
            staticStyle: { width: "250px", height: "250px" },
            attrs: { src: _vm.codeUrl ? _vm.codeUrl : "" }
          },
          [
            _c(
              "div",
              {
                staticClass: "image-slot",
                attrs: { slot: "error" },
                slot: "error"
              },
              [_c("i", { staticClass: "el-icon-picture-outline" })]
            )
          ]
        ),
        _c(
          "el-button",
          {
            staticStyle: { "margin-left": "45px" },
            attrs: { type: "primary" },
            on: { click: _vm.download }
          },
          [_vm._v("下载店铺二维码")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "color-666" }, [
      _c(
        "span",
        { staticClass: "font-16", staticStyle: { "line-height": "35px" } },
        [_vm._v("店铺二维码说明")]
      ),
      _c("br"),
      _vm._v(
        "1. 商家生成下载店铺二维码进行推广宣传，通过扫描二维码下单的手续费率为1.5-8%区间。"
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }