"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ['editItem'],
  data: function data() {
    return {
      order_no: "",
      price: "获取中",
      dialogVisible: false
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.submit();
      }
    }
  },
  methods: {
    submit: function submit() {
      var _this = this;
      (0, _order.getMerchantTransByMoreServiceId)({
        moreServiceId: this.editItem.id
      }).then(function (res) {
        _this.price = res;
      });
    }
  }
};
exports.default = _default;