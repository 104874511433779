var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order_list" },
    [
      _c(
        "div",
        { staticClass: "common_form_search" },
        [
          _c(
            "el-form",
            {
              ref: "searchForm",
              attrs: { inline: true, model: _vm.searchForm }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "订单号" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "220px" },
                    attrs: { placeholder: "请输入订单号", clearable: "" },
                    model: {
                      value: _vm.searchForm.orderNo,
                      callback: function($$v) {
                        _vm.$set(_vm.searchForm, "orderNo", $$v)
                      },
                      expression: "searchForm.orderNo"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入商品名称", clearable: "" },
                    model: {
                      value: _vm.searchForm.goodsName,
                      callback: function($$v) {
                        _vm.$set(_vm.searchForm, "goodsName", $$v)
                      },
                      expression: "searchForm.goodsName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "用户名" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入用户名", clearable: "" },
                    model: {
                      value: _vm.searchForm.receivingName,
                      callback: function($$v) {
                        _vm.$set(_vm.searchForm, "receivingName", $$v)
                      },
                      expression: "searchForm.receivingName"
                    }
                  })
                ],
                1
              ),
              _vm.merchantId == 0
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { label: "订单来源" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "全部", clearable: "" },
                            model: {
                              value: _vm.searchForm.channel,
                              callback: function($$v) {
                                _vm.$set(_vm.searchForm, "channel", $$v)
                              },
                              expression: "searchForm.channel"
                            }
                          },
                          _vm._l(_vm.channelList, function(item) {
                            return _c("el-option", {
                              key: item.key,
                              attrs: {
                                label: item.app_name + " - " + item.name,
                                value: item.key
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "手机号" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入手机号", clearable: "" },
                    model: {
                      value: _vm.searchForm.receivingMobile,
                      callback: function($$v) {
                        _vm.$set(_vm.searchForm, "receivingMobile", $$v)
                      },
                      expression: "searchForm.receivingMobile"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "全部", clearable: "" },
                      model: {
                        value: _vm.searchForm.orderStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "orderStatus", $$v)
                        },
                        expression: "searchForm.orderStatus"
                      }
                    },
                    _vm._l(_vm.selectList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "创建时间" } },
                [_c("my-date-picker", { ref: "myDatePicker" })],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.search()
                        }
                      }
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.reset()
                        }
                      }
                    },
                    [_vm._v("重置")]
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "div",
        { staticClass: "child_page" },
        [
          _c(
            "el-tabs",
            {
              staticClass: "flex-1 nav_tabs",
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            _vm._l(_vm.tabList, function(item) {
              return _c("el-tab-pane", {
                key: item.id,
                attrs: {
                  label: item.name + "(" + item.count + ")",
                  name: item.id
                }
              })
            }),
            1
          ),
          _c(
            "div",
            { staticClass: "list_wrap" },
            [
              _c("div", { staticClass: "flex list_header" }, [
                _c(
                  "p",
                  { staticStyle: { width: "31%", "padding-left": "20px" } },
                  [
                    _vm.merchantId > 0
                      ? _c(
                          "span",
                          { staticClass: "check_all" },
                          [
                            _c("el-checkbox", {
                              attrs: { indeterminate: _vm.isIndeterminate },
                              on: { change: _vm.handleCheckAll },
                              model: {
                                value: _vm.checkAll,
                                callback: function($$v) {
                                  _vm.checkAll = $$v
                                },
                                expression: "checkAll"
                              }
                            }),
                            1
                              ? _c(
                                  "el-dropdown",
                                  {
                                    attrs: { trigger: "click" },
                                    on: { command: _vm.handleCommand }
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "border-btn batch-btn",
                                        attrs: { size: "small" }
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            "icon-class": "batch",
                                            size: 10
                                          }
                                        }),
                                        _vm._v(
                                          "\n                                    批量操作\n                                "
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-dropdown-menu",
                                      {
                                        attrs: { slot: "dropdown" },
                                        slot: "dropdown"
                                      },
                                      [
                                        1
                                          ? _c(
                                              "el-dropdown-item",
                                              {
                                                attrs: { command: "auditOrder" }
                                              },
                                              [_vm._v("跟单分配")]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(
                      "\n                        商品信息\n                    "
                    )
                  ]
                ),
                _c(
                  "p",
                  { staticClass: "text-center", staticStyle: { width: "5%" } },
                  [_vm._v("数量")]
                ),
                _c(
                  "p",
                  { staticClass: "text-center", staticStyle: { width: "9%" } },
                  [_vm._v("攒金周期")]
                ),
                _c(
                  "p",
                  { staticClass: "text-center", staticStyle: { width: "14%" } },
                  [_vm._v("下次扣款日期")]
                ),
                _c(
                  "p",
                  { staticClass: "text-center", staticStyle: { width: "11%" } },
                  [_vm._v("金额详情")]
                ),
                _c(
                  "p",
                  { staticClass: "text-center", staticStyle: { width: "12%" } },
                  [_vm._v("订单总金额")]
                ),
                _c("p", { staticStyle: { width: "19%" } }, [
                  _vm._v("收货人信息")
                ])
              ]),
              _vm.loading
                ? _c("div", {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      }
                    ],
                    staticClass: "loading"
                  })
                : _vm._e(),
              _vm._l(_vm.tableData, function(item) {
                return _c("div", { key: item.id, staticClass: "list_item" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex justify-between align-center item_header"
                    },
                    [
                      _c(
                        "el-checkbox",
                        {
                          ref: "check",
                          refInFor: true,
                          attrs: { label: item.id },
                          on: { change: _vm.handleCheck },
                          model: {
                            value: _vm.checkList,
                            callback: function($$v) {
                              _vm.checkList = $$v
                            },
                            expression: "checkList"
                          }
                        },
                        [_vm._v(" ")]
                      ),
                      _c(
                        "el-tag",
                        {
                          attrs: { type: _vm.getOrderStatusType(item.status) }
                        },
                        [_vm._v(_vm._s(item.statusName))]
                      ),
                      _c("div", { staticClass: "flex-1 flex align-center" }, [
                        _c("p", [_vm._v(_vm._s(item.shopname))]),
                        _c("p", [_vm._v("ID：" + _vm._s(item.id))]),
                        item.is_one_rmb_buy
                          ? _c("p", [
                              _c("span", { staticClass: "red-font" }, [
                                _vm._v("1元租")
                              ])
                            ])
                          : _vm._e(),
                        _c("p", [
                          _vm._v("创建时间：" + _vm._s(item.create_time))
                        ]),
                        _c("p", [_vm._v("订单号：" + _vm._s(item.trade_no))]),
                        _c(
                          "p",
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "续租订单",
                                  placement: "top-start"
                                }
                              },
                              [
                                item.is_renewal == 1
                                  ? _c("img", {
                                      attrs: {
                                        src: require("../../assets/images/renew.png"),
                                        alt: "续租订单"
                                      }
                                    })
                                  : _vm._e()
                              ]
                            )
                          ],
                          1
                        ),
                        _vm.merchantId == 0
                          ? _c("p", [
                              _vm._v("订单来源：" + _vm._s(item.channel_name))
                            ])
                          : _vm._e(),
                        _vm.merchantId == 0
                          ? _c("p", [
                              _vm._v(
                                "\n                                下单次数：\n                                "
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "express-font pointer",
                                  on: {
                                    click: function($event) {
                                      return _vm.jumpListByMobile(
                                        item.mobile,
                                        item.person_order_num
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(item.person_order_num) +
                                      "\n                                "
                                  )
                                ]
                              )
                            ])
                          : _vm._e(),
                        _vm.merchantId == 0 && item.member_count > 1
                          ? _c("p", [
                              _c(
                                "span",
                                {
                                  staticClass: "express-font pointer",
                                  on: {
                                    click: function($event) {
                                      return _vm.jumpListByRiskName(
                                        item.cert_name
                                      )
                                    }
                                  }
                                },
                                [_vm._v("账号异常")]
                              )
                            ])
                          : _vm._e(),
                        _vm.merchantId == 0 && item.other_rent_ing > 0
                          ? _c("p", [
                              _c(
                                "span",
                                {
                                  staticClass: "express-font pointer",
                                  on: {
                                    click: function($event) {
                                      return _vm.jumpListByRiskName(
                                        item.cert_name
                                      )
                                    }
                                  }
                                },
                                [_vm._v("租用异常")]
                              )
                            ])
                          : _vm._e(),
                        _c("p", [
                          _vm._v(
                            "\n                                快递信息：\n                                "
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "express-font pointer",
                              on: {
                                click: function($event) {
                                  return _vm.checkLogistics(item)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    item.logistic.delivery_type == 2
                                      ? "上门自取"
                                      : item.logistic.delivery_type == 3
                                      ? "上门安装"
                                      : item.logistic.logistics_no
                                      ? item.logistic.logistics_no +
                                        " (" +
                                        item.logistic.logistics_name +
                                        ")"
                                      : "无"
                                  ) +
                                  "\n                                "
                              )
                            ]
                          )
                        ]),
                        item.adj_info.used_time
                          ? _c("p", [
                              _vm._v(
                                "下单耗时: " + _vm._s(item.adj_info.used_time)
                              )
                            ])
                          : _vm._e(),
                        item.adj_info.phone_brand
                          ? _c("p", [
                              _vm._v(
                                "下单机型: " +
                                  _vm._s(item.adj_info.phone_brand) +
                                  " " +
                                  _vm._s(item.adj_info.phone_model)
                              )
                            ])
                          : _vm._e(),
                        _vm.merchantId == 0 && item.is_share
                          ? _c(
                              "p",
                              [
                                _vm._v(
                                  "\n                                是分销单 :" +
                                    _vm._s(item.share_name) +
                                    " - " +
                                    _vm._s(item.share_mobile) +
                                    "\n                                "
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "border-btn",
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function($event) {
                                        return _vm.orderShareList(item)
                                      }
                                    }
                                  },
                                  [_vm._v("分销详情")]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        item.status == 1
                          ? _c("p", [
                              _vm._v("关单时间 : " + _vm._s(item.log_time))
                            ])
                          : _vm._e(),
                        item.status == 16
                          ? _c("p", [
                              _vm._v("退款时间 : " + _vm._s(item.log_time))
                            ])
                          : _vm._e()
                      ])
                    ],
                    1
                  ),
                  _c("div", { staticClass: "flex item_row" }, [
                    _c("div", { staticStyle: { width: "31%" } }, [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c(
                            "el-image",
                            {
                              staticStyle: { width: "100px", height: "100px" },
                              attrs: {
                                src: item.imgurl
                                  ? _vm.$globalObj.imgBaseUrl + item.imgurl
                                  : ""
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-slot",
                                  attrs: { slot: "error" },
                                  slot: "error"
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-picture-outline"
                                  })
                                ]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "flex-1",
                              staticStyle: {
                                "padding-left": "10px",
                                overflow: "hidden"
                              }
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass: "text-ellipsis title blue-font"
                                },
                                [_vm._v(_vm._s(item.goods_title))]
                              ),
                              _c("p", { staticClass: "meal" }, [
                                _vm._v(
                                  "套餐信息：" + _vm._s(item.setmeal_title)
                                )
                              ]),
                              _c("p", { staticClass: "meal" }, [
                                _vm._v(
                                  "商品单价：" + _vm._s(item.setmeal_price)
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "flex flex-center color-666 font-16",
                        staticStyle: { width: "5%" }
                      },
                      [_vm._v("x" + _vm._s(item.amount))]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "color-666 font-12 flex flex-column flex-center",
                        staticStyle: { width: "9%" }
                      },
                      [
                        _c("p", {}, [
                          _vm._v(_vm._s(item.cycle_type_name) + " ")
                        ]),
                        item.cycle_type == "month"
                          ? _c("p", { staticClass: "color-999 font-12" }, [
                              _vm._v(
                                " 每月 " + _vm._s(item.cycle_days) + " 日 "
                              )
                            ])
                          : item.cycle_type == "week"
                          ? _c("p", { staticClass: "color-999 font-12" }, [
                              _vm._v(
                                " 每周 " +
                                  _vm._s(
                                    item.cycle_days == 7
                                      ? "日"
                                      : item.cycle_days
                                  )
                              )
                            ])
                          : _vm._e()
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "flex flex-column flex-center",
                        staticStyle: { width: "14%" }
                      },
                      [
                        _c("p", {}, [_vm._v(_vm._s(item.next_cycle_date))]),
                        item.foregift_deduct > 0
                          ? _c(
                              "p",
                              {
                                staticClass: "color-999 font-12",
                                staticStyle: {
                                  "text-decoration": "line-through"
                                }
                              },
                              [
                                _vm._v(
                                  "￥" +
                                    _vm._s(
                                      parseFloat(
                                        parseFloat(item.foregift) +
                                          parseFloat(item.foregift_deduct)
                                      ).toFixed(2)
                                    )
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "flex flex-center font-12 color-666",
                        staticStyle: { width: "11%" }
                      },
                      [
                        _c("p", { staticClass: "color-999 font-12" }, [
                          _vm._v(
                            "\n                                商品金额: ￥" +
                              _vm._s(item.goods_money) +
                              " "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n                                运费: ￥" +
                              _vm._s(item.transfer_cost) +
                              " "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n                                手工费: ￥" +
                              _vm._s(item.process_cost) +
                              " "
                          ),
                          _c("br")
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "flex flex-column flex-center",
                        staticStyle: { width: "12%" }
                      },
                      [
                        _c("p", { staticClass: "red-font" }, [
                          _vm._v("￥" + _vm._s(item.total_money) + " ")
                        ])
                      ]
                    ),
                    _c("div", { staticStyle: { width: "19%" } }, [
                      _c("p", { staticClass: "font-12 color-666" }, [
                        _c("span", [_vm._v(_vm._s(item.realname) + " ")]),
                        _c("span", [_vm._v(" " + _vm._s(item.mobile))])
                      ]),
                      _c(
                        "p",
                        {
                          staticClass: "font-12 color-666",
                          staticStyle: { "word-break": "break-all" }
                        },
                        [
                          _vm._v(
                            _vm._s(item.prov) +
                              _vm._s(item.city) +
                              _vm._s(item.area) +
                              _vm._s(item.address)
                          )
                        ]
                      ),
                      _c(
                        "div",
                        [
                          _vm.merchantId == 0 && item.risk_type_receipt > 0
                            ? _c(
                                "span",
                                {
                                  staticClass: "pointer",
                                  staticStyle: { "margin-right": "5px" },
                                  on: {
                                    click: function($event) {
                                      return _vm.openRiskArea(item, 2)
                                    }
                                  }
                                },
                                [
                                  item.risk_type_receipt == 1
                                    ? _c(
                                        "el-tag",
                                        {
                                          attrs: {
                                            type: "warning",
                                            effect: "dark"
                                          }
                                        },
                                        [_vm._v("中风险")]
                                      )
                                    : _c(
                                        "el-tag",
                                        {
                                          attrs: {
                                            type: "danger",
                                            effect: "dark"
                                          }
                                        },
                                        [_vm._v("高风险")]
                                      )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "warning" },
                              on: {
                                click: function($event) {
                                  return _vm.showAreaDetail(item)
                                }
                              }
                            },
                            [_vm._v("地址详情")]
                          ),
                          _vm.$hasMethod("#editAddress")
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    plain: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.editAddress(item)
                                    }
                                  }
                                },
                                [_vm._v("修改地址")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "flex item_row" }, [
                    _c(
                      "div",
                      { staticClass: "detail", staticStyle: { width: "57%" } },
                      [
                        _c("p", { staticClass: "color-666 font-12" }, [
                          _vm._v("用户备注：" + _vm._s(item.remark))
                        ]),
                        _c(
                          "p",
                          { staticClass: "color-666" },
                          [
                            _vm.$hasMethod("#checkFlow")
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "border-btn",
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function($event) {
                                        return _vm.viewFlow(item.trade_no)
                                      }
                                    }
                                  },
                                  [_vm._v("流水记录")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "flex flex-column",
                        staticStyle: { width: "43%" }
                      },
                      [
                        item.orderRefundReason
                          ? _c("p", [
                              _vm._v(
                                "退款原因：" + _vm._s(item.orderRefundReason)
                              )
                            ])
                          : _vm._e(),
                        item.is_renewal == 1
                          ? _c(
                              "p",
                              { staticStyle: { "margin-left": "10px" } },
                              [
                                _vm._v(
                                  "\n                                原订单号：\n                                "
                                ),
                                _c("span", { staticClass: "blue-font" }, [
                                  _vm._v(_vm._s(item.parent_order_no))
                                ])
                              ]
                            )
                          : _vm._e(),
                        item.is_abnormal == 1 &&
                        [41, 42, 43, 44, 45, 49, 60, 65].indexOf(item.status) >
                          -1
                          ? _c(
                              "p",
                              {
                                staticClass: "red-font",
                                staticStyle: { "margin-left": "10px" }
                              },
                              [_vm._v("该订单提前归还")]
                            )
                          : _vm._e(),
                        item.check_refuse_reason && item.status == 43
                          ? _c(
                              "p",
                              {
                                staticClass: "red-font",
                                staticStyle: { "margin-left": "10px" }
                              },
                              [
                                _vm._v(
                                  "审核拒绝原因：" +
                                    _vm._s(item.check_refuse_reason)
                                )
                              ]
                            )
                          : _vm._e(),
                        _c("p", { staticClass: "color-666 red-font" }, [
                          _c("span", { staticClass: "red-font" }, [
                            _vm._v("用户下单位置")
                          ]),
                          _vm._v(
                            "\n                                ：" +
                              _vm._s(item.al_province) +
                              _vm._s(item.al_city) +
                              _vm._s(item.al_district) +
                              _vm._s(item.al_street) +
                              "\n                                "
                          ),
                          _vm.merchantId == 0 && item.risk_type > 0
                            ? _c(
                                "span",
                                {
                                  staticClass: "pointer",
                                  on: {
                                    click: function($event) {
                                      return _vm.openRiskArea(item, 1)
                                    }
                                  }
                                },
                                [
                                  item.risk_type == 1
                                    ? _c(
                                        "el-tag",
                                        {
                                          attrs: {
                                            type: "warning",
                                            effect: "dark"
                                          }
                                        },
                                        [_vm._v("中风险")]
                                      )
                                    : _c(
                                        "el-tag",
                                        {
                                          attrs: {
                                            type: "danger",
                                            effect: "dark"
                                          }
                                        },
                                        [_vm._v("高风险")]
                                      )
                                ],
                                1
                              )
                            : _vm._e()
                        ]),
                        item.is_renewal == 2
                          ? _c(
                              "p",
                              { staticClass: "btns flex align-center" },
                              [
                                item.status == 10 && _vm.$hasMethod("#delivery")
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          type: "warning"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.openDelivery(item.id)
                                          }
                                        }
                                      },
                                      [_vm._v("订单发货")]
                                    )
                                  : _vm._e(),
                                item.status == 20 && _vm.$hasMethod("#receipt")
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          type: "warning"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.confirmReceipt(item.id)
                                          }
                                        }
                                      },
                                      [_vm._v("确认收货")]
                                    )
                                  : _vm._e(),
                                _vm.merchantId && [9].indexOf(item.status) > -1
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          type: "primary"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.riskPass(item)
                                          }
                                        }
                                      },
                                      [_vm._v("通过审核")]
                                    )
                                  : _vm._e(),
                                _vm.merchantId && [11].indexOf(item.status) > -1
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          type: "primary"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.merRefuseRefund(item)
                                          }
                                        }
                                      },
                                      [_vm._v("拒绝取消申请")]
                                    )
                                  : _vm._e(),
                                _vm.merchantId && [9].indexOf(item.status) > -1
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          type: "warning"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.riskReject(item)
                                          }
                                        }
                                      },
                                      [_vm._v("拒绝审核")]
                                    )
                                  : _vm._e(),
                                _vm.merchantId &&
                                [11].indexOf(item.status) > -1 &&
                                item.is_merchant == 0
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          type: "warning"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.riskReject(item)
                                          }
                                        }
                                      },
                                      [_vm._v("同意申请")]
                                    )
                                  : _vm._e(),
                                _vm.merchantId &&
                                [11].indexOf(item.status) > -1 &&
                                item.is_merchant == 1
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          type: "warning"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.passRefund(item)
                                          }
                                        }
                                      },
                                      [_vm._v("同意申请")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "p",
                          { staticClass: "btns flex align-center" },
                          [
                            [
                              20,
                              30,
                              31,
                              32,
                              33,
                              34,
                              39,
                              40,
                              41,
                              42,
                              43,
                              44,
                              45,
                              49,
                              50,
                              51,
                              52,
                              56,
                              57,
                              58
                            ].indexOf(item.status) > -1 &&
                            _vm.$hasMethod("#checkLogistics")
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "primary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.checkLogistics(item)
                                      }
                                    }
                                  },
                                  [
                                    item.WLInfo
                                      ? _c("span", [_vm._v("物流记录")])
                                      : _vm._e(),
                                    item.WLInfo == 0
                                      ? _c(
                                          "span",
                                          { staticClass: "red-font" },
                                          [_vm._v("查看物流")]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              : _vm._e(),
                            item.status == 44 && _vm.$hasMethod("#confirmR")
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "warning" },
                                    on: {
                                      click: function($event) {
                                        return _vm.confirmReturn(item.id)
                                      }
                                    }
                                  },
                                  [_vm._v("确认归还")]
                                )
                              : _vm._e(),
                            [
                              9,
                              10,
                              11,
                              12,
                              13,
                              14,
                              20,
                              30,
                              31,
                              32,
                              33,
                              34,
                              39,
                              40,
                              41,
                              42,
                              43,
                              44,
                              45,
                              49,
                              50,
                              51,
                              52,
                              56,
                              57,
                              58,
                              71
                            ].indexOf(item.status) > -1 &&
                            _vm.$hasMethod("#finish") &&
                            _vm.merchantId == 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "success" },
                                    on: {
                                      click: function($event) {
                                        return _vm.finishOrder(item.id)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    交易完成\n                                "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            [
                              10,
                              12,
                              13,
                              14,
                              20,
                              30,
                              31,
                              32,
                              33,
                              34,
                              39,
                              40,
                              41,
                              42,
                              43,
                              44,
                              45,
                              49,
                              50,
                              51,
                              52,
                              56,
                              57,
                              58
                            ].indexOf(item.status) > -1 &&
                            _vm.$hasMethod("#finish") &&
                            _vm.merchantId > 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "success" },
                                    on: {
                                      click: function($event) {
                                        return _vm.finishOrderMerchant(item.id)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    交易完成\n                                "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            item.status == 41 && _vm.$hasMethod("#agree")
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "warning" },
                                    on: {
                                      click: function($event) {
                                        return _vm.agreeReturn(item.id)
                                      }
                                    }
                                  },
                                  [_vm._v("同意归还")]
                                )
                              : _vm._e(),
                            item.status == 41 && _vm.$hasMethod("#refuse")
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "danger" },
                                    on: {
                                      click: function($event) {
                                        return _vm.refuseReturn(item.id)
                                      }
                                    }
                                  },
                                  [_vm._v("拒绝归还")]
                                )
                              : _vm._e(),
                            [2, 3, 9, 10].indexOf(item.status) > -1 &&
                            _vm.$hasMethod("#close") &&
                            _vm.merchantId == 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "danger" },
                                    on: {
                                      click: function($event) {
                                        return _vm.closeOrder(
                                          item.id,
                                          item.status
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("关闭订单")]
                                )
                              : _vm._e(),
                            item.status == 60 && _vm.merchantId == 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "danger" },
                                    on: {
                                      click: function($event) {
                                        return _vm.successOrderReturn(item.id)
                                      }
                                    }
                                  },
                                  [_vm._v("订单已退款")]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm.merchantId == 0
                          ? _c("div", [
                              [11, 12, 13, 14].indexOf(item.status) > -1
                                ? _c(
                                    "p",
                                    { staticClass: "btns flex align-center" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "btns-title" },
                                        [_vm._v("退款审核：")]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "warning"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleRefundOrder(
                                                item.trade_no
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("去审核")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ])
                          : _vm._e()
                      ]
                    )
                  ])
                ])
              })
            ],
            2
          ),
          _c("custom-page", {
            ref: "customPage",
            attrs: { total: _vm.total },
            on: { getList: _vm.getList }
          })
        ],
        1
      ),
      _c("check-lease", {
        ref: "checkLease",
        attrs: {
          "order-id": _vm.selectedId,
          "order-status": _vm.orderStatus,
          editItem: _vm.editItem
        }
      }),
      _c("emergencyContact", {
        ref: "emergencyContact",
        attrs: { editItem: _vm.editItem }
      }),
      _c("maYiPayRule", {
        ref: "maYiPayRule",
        attrs: { "order-id": _vm.selectedId, "order-status": _vm.orderStatus }
      }),
      _c("order-share-list", {
        ref: "orderShareList",
        attrs: { "order-id": _vm.selectedId, "order-no": _vm.orderNo }
      }),
      _c("checklRiskArea", {
        ref: "checklRiskArea",
        attrs: { "edit-item": _vm.editItem }
      }),
      _c("check-risk", {
        ref: "checkRisk",
        attrs: { "order-id": _vm.selectedId, "merchant-id": _vm.merchantId }
      }),
      _c("check-risk-two", {
        ref: "checkRiskTwo",
        attrs: { "order-id": _vm.selectedId, "merchant-id": _vm.merchantId }
      }),
      _c("check-risk-three", {
        ref: "checkRiskThree",
        attrs: { "order-id": _vm.selectedId, "merchant-id": _vm.merchantId }
      }),
      _c("check-risk-zhima", {
        ref: "checkRiskZhima",
        attrs: { "order-id": _vm.selectedId, "merchant-id": _vm.merchantId }
      }),
      _c("checkRiskJiaohu", {
        ref: "checkRiskJiaohu",
        attrs: { "order-id": _vm.selectedId, "merchant-id": _vm.merchantId }
      }),
      _c("set-address", {
        ref: "setAddress",
        attrs: { "edit-item": _vm.editItem },
        on: { getList: _vm.getList }
      }),
      _c("show-area-detail", {
        ref: "showAreaDetail",
        attrs: { "edit-item": _vm.editItem }
      }),
      _c("setDistributionOrder", {
        ref: "setDistributionOrder",
        attrs: { "edit-item": _vm.editItem, "merchant-list": _vm.merchantList },
        on: { getList: _vm.getList }
      }),
      _c("setAuditOrder", {
        ref: "setAuditOrder",
        attrs: {
          "edit-item": _vm.editItem,
          "merchant-list": _vm.merchantChildList
        },
        on: {
          getList: _vm.getList,
          getMerchantChildList: _vm.getMerchantChildList
        }
      }),
      _c("refushDistributionOrder", {
        ref: "refushDistributionOrder",
        attrs: { "order-id": _vm.selectedId, "order-no": _vm.orderNo }
      }),
      _c("set-device", {
        ref: "setDevice",
        attrs: { "edit-item": _vm.editItem },
        on: { getList: _vm.getList }
      }),
      _c("setSetmeal", {
        ref: "setSetmeal",
        attrs: { "edit-item": _vm.editItem },
        on: { getList: _vm.getList }
      }),
      _c("setService", {
        ref: "setService",
        attrs: { "edit-item": _vm.editItem },
        on: { getList: _vm.getList }
      }),
      _c("set-remark", {
        ref: "setRemark",
        attrs: { "order-id": _vm.selectedId },
        on: { getList: _vm.getList }
      }),
      _c("setRelet", {
        ref: "setRelet",
        attrs: { editItem: _vm.editItem },
        on: { getList: _vm.getList }
      }),
      _c("check-remark", {
        ref: "checkRemark",
        attrs: { "order-id": _vm.selectedId }
      }),
      _c("check-flow", {
        ref: "checkFlow",
        attrs: { "order-no": _vm.selectedId }
      }),
      _c("delivery", {
        ref: "delivery",
        attrs: { "order-id": _vm.selectedId },
        on: { getList: _vm.getList }
      }),
      _c("receipt", {
        ref: "receipt",
        attrs: { "order-id": _vm.selectedId },
        on: { getList: _vm.getList }
      }),
      _c("auth-info", {
        ref: "authInfo",
        attrs: { "edit-item": _vm.editItem },
        on: { getList: _vm.getList }
      }),
      _c("check-bill", {
        ref: "checkBill",
        attrs: { "order-id": _vm.selectedId }
      }),
      _c("check-logistics", {
        ref: "checkLogistics",
        attrs: { "order-id": _vm.selectedId }
      }),
      _c("applyCloseOrder", {
        ref: "applyCloseOrder",
        attrs: { "edit-item": _vm.editItem },
        on: { getList: _vm.getList }
      }),
      _c("setRiskReject", {
        ref: "setRiskReject",
        attrs: { "edit-item": _vm.editItem },
        on: { getList: _vm.getList }
      }),
      _c("saveCostFun", {
        ref: "saveCostFun",
        attrs: { "edit-item": _vm.editItem },
        on: { getList: _vm.getList }
      }),
      _c("serviceInfo", {
        ref: "serviceInfo",
        attrs: { "edit-item": _vm.editItem }
      }),
      _c("orderInfo", { ref: "orderInfo", attrs: { editItem: _vm.editItem } }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "拒绝归还",
            visible: _vm.returnVisible,
            width: "500px"
          },
          on: {
            "update:visible": function($event) {
              _vm.returnVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.returnForm } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "拒绝原因", "label-width": "120px" } },
                [
                  _c("el-input", {
                    attrs: {
                      autocomplete: "off",
                      placeholder: "请输入拒绝原因",
                      type: "text",
                      maxlength: "50",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.returnForm.checkRefuseReason,
                      callback: function($$v) {
                        _vm.$set(_vm.returnForm, "checkRefuseReason", $$v)
                      },
                      expression: "returnForm.checkRefuseReason"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.closeReturn } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmReturnVisible }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }