"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _orderRefund = require("@/api/orderRefund");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["refundId", "refundStatus"],
  data: function data() {
    return {
      dialogVisible: false,
      isShowOther: false,
      formLabelWidth: "120px",
      form: {
        refuse_reason: "",
        other: ""
      },
      curReason: ''
    };
  },
  methods: {
    changeSelect: function changeSelect(val) {
      this.form.other = '';
      if (val == '2') {
        this.isShowOther = true;
      } else {
        this.isShowOther = false;
      }
    },
    close: function close() {
      this.dialogVisible = false;
      this.form = {
        refuse_reason: "",
        other: ""
      };
      this.isShowOther = false;
    },
    submit_refuse: function submit_refuse() {
      var _this = this;
      if (!this.form.refuse_reason) {
        this.$message.warning("拒绝原因不能为空");
        return false;
      }
      if (this.form.refuse_reason == 2 && !this.form.other) {
        this.$message.warning("请输入拒绝退款原因！");
        return false;
      }
      if (this.form.refuse_reason == 1) {
        this.curReason = '订单商品已发货';
      } else {
        this.curReason = this.form.other;
      }
      var obj = {
        refund_id: this.refundId,
        refuse_reason: this.curReason
      };
      (0, _orderRefund.shopCheckRefuse)(obj).then(function (res) {
        _this.dialogVisible = false;
        _this.form = {
          refuse_reason: "",
          other: ""
        };
        _this.isShowOther = false;
        _this.$parent.getList();
      });
    }
  },
  mounted: function mounted() {}
};
exports.default = _default;