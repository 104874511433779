"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _system = require("@/api/system");
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _add = _interopRequireDefault(require("./add"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    customPage: _customPage.default,
    addDialog: _add.default
  },
  data: function data() {
    return {
      searchForm: {},
      total: 0,
      tableData: [],
      editItem: null
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList(flag) {
      var _this = this;
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      if (!flag) {
        this.tableData = [];
      }
      (0, _system.getAppVersionList)({
        page: page,
        pageSize: pageSize
      }).then(function (res) {
        _this.tableData = res.data || [];
        _this.total = res.total;
      });
    },
    // 删除
    removeItem: function removeItem(item) {
      // let tip = `<span class='red-font'>当前通道 ${item.key} - ${item.title}</span><br><span>您确定要删除通道吗？</span>`
      // this.$confirm(tip, '删除', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     dangerouslyUseHTMLString: true
      // }).then(() => {
      //     delAppVersion(item.id).then(res => {
      //         this.getList(true);
      //     });
      // }).catch(() => {});
    },
    // 添加/编辑账号
    openAddDialog: function openAddDialog() {
      var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      this.editItem = item || {};
      this.$refs.addDialog.dialogVisible = true;
    },
    // 查询
    search: function search() {
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      this.searchForm = this.$options.data().searchForm;
      this.getList();
    },
    // 启用/禁用
    changeStatus: function changeStatus(item) {}
  }
};
exports.default = _default;