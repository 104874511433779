"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
var _createForOfIteratorHelper2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/createForOfIteratorHelper.js"));
var _bus = _interopRequireDefault(require("./bus"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      list: []
    };
  },
  created: function created() {
    var _this = this;
    _bus.default.$on("setTopNav", function (val) {
      _this.list = [];
      var _iterator = (0, _createForOfIteratorHelper2.default)(_this.menu),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;
          if (item.id == val.id) {
            _this.list[0] = {
              title: item.label,
              pathName: item.children && item.children.length > 0 ? item.children[0].path : ""
            };
            break;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      var _iterator2 = (0, _createForOfIteratorHelper2.default)(_this.permission_routes),
        _step2;
      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var _item = _step2.value;
          if (_item.name === val.route) {
            _this.list[1] = {
              title: _item.meta.title,
              pathName: ""
            };
            var _iterator3 = (0, _createForOfIteratorHelper2.default)(_item.children),
              _step3;
            try {
              for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
                var child = _step3.value;
                if (!child.hidden) {
                  _this.list[1].pathName = child.name;
                  break;
                }
              }
            } catch (err) {
              _iterator3.e(err);
            } finally {
              _iterator3.f();
            }
            break;
          }
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
      var meta = _this.$route.meta;
      if (meta && meta.activeMenu) {
        _this.list[2] = {
          title: meta.parentTitle,
          pathName: meta.parentRouteName
        };
      }
    });
  },
  watch: {
    $route: function $route(val) {
      var parentRoute = val.matched[0].name;
      var _iterator4 = (0, _createForOfIteratorHelper2.default)(this.permission_routes),
        _step4;
      try {
        for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
          var item = _step4.value;
          if (item.name === parentRoute) {
            var obj = {
              title: item.meta.title,
              pathName: ""
            };
            var _iterator5 = (0, _createForOfIteratorHelper2.default)(item.children),
              _step5;
            try {
              for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
                var child = _step5.value;
                if (!child.hidden) {
                  obj.pathName = child.name;
                  break;
                }
              }
            } catch (err) {
              _iterator5.e(err);
            } finally {
              _iterator5.f();
            }
            this.$set(this.list, 1, obj);
            break;
          }
        }
      } catch (err) {
        _iterator4.e(err);
      } finally {
        _iterator4.f();
      }
      if (val.meta && val.meta.activeMenu) {
        this.$set(this.list, 2, {
          title: val.meta.parentTitle,
          pathName: val.meta.parentRouteName
        });
      } else {
        this.$delete(this.list, 2);
      }
    }
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['menu', 'permission_routes'])),
  beforeDestroy: function beforeDestroy() {
    _bus.default.$off('setTopNav');
  }
};
exports.default = _default;