"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _bannerForm = _interopRequireDefault(require("../components/bannerForm"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    bannerForm: _bannerForm.default
  },
  props: {
    width: {
      default: ''
    },
    height: {
      default: ''
    },
    itemNum: {
      default: 3
    },
    editData: {
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      itemList: []
    };
  },
  watch: {
    editData: function editData(val) {
      if (val) {
        this.itemList = val;
      }
    },
    itemList: function itemList(val) {
      // console.log('itemList', val)
      this.$emit('setSubmitData', val);
    }
  },
  methods: {
    // 升序
    upSortItem: function upSortItem(index) {
      if (index === 0) {
        return;
      }
      var item = this.itemList[index];
      this.$set(this.itemList, index, this.itemList[index - 1]);
      this.$set(this.itemList, index - 1, item);
    },
    // 降序
    downSortItem: function downSortItem(index) {
      if (index === this.itemList.length - 1) {
        return;
      }
      var item = this.itemList[index];
      this.$set(this.itemList, index, this.itemList[index + 1]);
      this.$set(this.itemList, index + 1, item);
    },
    // 删除推荐位
    deleteItem: function deleteItem(index) {
      this.itemList.splice(index, 1);
    },
    addItem: function addItem() {
      this.itemList.push({
        img: '',
        jumpType: '',
        jumpUrl: '',
        goods: {}
      });
    }
  }
};
exports.default = _default2;