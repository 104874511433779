var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "xcx_home_config" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        staticClass: "flex main"
      },
      [
        _c(
          "div",
          { staticClass: "content" },
          _vm._l(_vm.tmpList, function(item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "temp-item",
                on: {
                  click: function($event) {
                    return _vm.chooseTemplate(item)
                  }
                }
              },
              [
                _c("h5", { staticClass: "color-666 normal-font" }, [
                  _vm._v(_vm._s(item.title))
                ]),
                _c("div", {
                  staticClass: "img",
                  style: {
                    "background-image":
                      "url('" + _vm.$globalObj.imgApiUrl + item.img + "')"
                  }
                }),
                _c(
                  "div",
                  { staticClass: "radio" },
                  [
                    _c(
                      "el-radio",
                      {
                        attrs: { label: item.id },
                        model: {
                          value: _vm.currId,
                          callback: function($$v) {
                            _vm.currId = $$v
                          },
                          expression: "currId"
                        }
                      },
                      [_vm._v(_vm._s(item.title))]
                    )
                  ],
                  1
                )
              ]
            )
          }),
          0
        )
      ]
    ),
    _c(
      "div",
      { staticClass: "fix-footer-btns" },
      [
        _c(
          "el-button",
          {
            attrs: { size: "small", type: "primary" },
            on: { click: _vm.save }
          },
          [_vm._v("保存")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }