"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _order = require("@/api/order");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ['editItem'],
  data: function data() {
    return {
      order_no: "",
      form: {
        orderId: "",
        cost_price: 0
      },
      rules: {
        remark: [{
          required: true,
          message: '备注不能为空',
          trigger: 'blur'
        }]
      },
      dialogVisible: false
    };
  },
  methods: {
    submit: function submit() {
      var _this = this;
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          (0, _order.setOrderCostPrice)((0, _objectSpread2.default)({}, _this.form)).then(function (res) {
            _this.dialogVisible = false;
            _this.$emit('getList', true);
          });
        } else {
          _this.$message({
            message: "请检查表单错误",
            type: "error"
          });
          return false;
        }
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      console.log(this.editItem);
      if (val) {
        this.form.orderId = this.editItem.id;
        this.form.cost_price = this.editItem.cost_price;
        this.order_no = this.editItem.trade_no;
      } else {
        this.$refs['ruleForm'].resetFields();
      }
    }
  }
};
exports.default = _default;