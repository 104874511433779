"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _web = require("@/api/web");
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      loading: false,
      rateList: []
    };
  },
  methods: {
    delAllRedis: function delAllRedis() {
      var _this = this;
      this.$confirm('是否清除所有缓存！', '请确认是否清除？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _web.delAllRedis)().then(function (res) {
          _this.$message.success("操作成功");
        });
      }).catch(function () {});
    }
  }
};
exports.default = _default;