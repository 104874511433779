"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _orderRefund = require("@/api/orderRefund");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["refundId"],
  data: function data() {
    return {
      dialogVisible: false,
      orderData: [],
      rateData: [],
      isShow: false,
      sum: {
        total_deduct_amount: "",
        total_deduct_fee: "",
        total_paid_fee: "",
        total_payable_fee: "",
        total_refund_amount: ""
      }
    };
  },
  methods: {
    clickShow: function clickShow() {
      this.isShow = !this.isShow;
    },
    getData: function getData() {
      var _this = this;
      (0, _orderRefund.orderRefundDetail)(this.refundId).then(function (res) {
        _this.sum = res;
        _this.rateData = [{}];
        for (var i in res) {
          _this.$set(_this.rateData, i, res[i]);
        }
      });
    },
    getSummaries: function getSummaries(param) {
      var _this2 = this;
      var columns = param.columns,
        data = param.data;
      var sums = [];
      columns.forEach(function (column, index) {
        if (index === 0) {
          sums[index] = '总计';
          return;
        }
        switch (column.property) {
          case "payable_fee":
            sums[index] = _this2.sum.total_payable_fee != 0 ? '￥' + _this2.sum.total_payable_fee : '--';
            break;
          case "paid_fee":
            sums[index] = _this2.sum.total_paid_fee != 0 ? '￥' + _this2.sum.total_paid_fee : '--';
            break;
          case "refund_amount":
            sums[index] = _this2.sum.total_refund_amount != 0 ? '￥' + _this2.sum.total_refund_amount : '--';
            break;
          case "deduct_fee":
            sums[index] = _this2.sum.total_deduct_fee != 0 ? '￥' + _this2.sum.total_deduct_fee : '--';
            break;
          case "deduct_refund_amount":
            sums[index] = _this2.sum.total_deduct_amount != 0 ? '￥' + _this2.sum.total_deduct_amount : '--';
            break;
          default:
            break;
        }
        // const values = data.map(item => Number(item[column.property]));
        // if (!values.every(value => isNaN(value))) {
        //   sums[index] = values.reduce((prev, curr) => {
        //     const value = Number(curr);
        //     if (!isNaN(value)) {
        //       return prev + curr;
        //     } else {
        //       return prev;
        //     }
        //   }, 0);
        //   sums[index] = sums[index]!=0 ? '￥'+sums[index].toFixed(4) : '--';
        // } else {
        //   sums[index] = '';
        // }
      });

      return sums;
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var _this3 = this;
      if (val) {
        this.isShow = false;
        (0, _orderRefund.orderRefundTransData)(this.refundId).then(function (res) {
          _this3.orderData = [{}];
          for (var i in res) {
            _this3.$set(_this3.orderData[0], i, res[i]);
          }
        });
        this.getData();
      }
    }
  }
};
exports.default = _default;