"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _myDatePicker = _interopRequireDefault(require("@/components/myDatePicker"));
var _finance = require("@/api/finance");
var _add = _interopRequireDefault(require("./dialogs/add"));
var _offlinePayMore = _interopRequireDefault(require("./dialogs/offlinePayMore"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["editItem", "roleList"],
  components: {
    customPage: _customPage.default,
    myDatePicker: _myDatePicker.default,
    addDialog: _add.default,
    offlinePayMore: _offlinePayMore.default
  },
  data: function data() {
    return {
      searchForm: {
        orderNo: '',
        moreserviceNo: '',
        goodsName: '',
        status: 3,
        orderCreateTime: '',
        orderPayTime: ''
      },
      statusList: [{
        id: -1,
        name: '全部'
      }, {
        id: 0,
        name: '未支付'
      }, {
        id: 1,
        name: '订单关闭'
      }, {
        id: 2,
        name: '待支付'
      }, {
        id: 3,
        name: '支付成功'
      }, {
        id: 4,
        name: '支付失败'
      }, {
        id: 5,
        name: '已退款'
      }],
      tableData: [],
      total: 0,
      selectList: [],
      merchantList: [],
      editItemId: 0,
      edItem: {},
      datedata: {
        today_pay: '-',
        today_no_pay: '-',
        tomorrow_all: '-'
      }
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    goToOrder: function goToOrder(orderId) {
      if (orderId) {
        // console.log(this.$router.resolve({ path:'/order/list?orderId='+orderId}))
        this.$func.openWindow('/order/list?orderId=' + orderId);
        // this.$router.push({ name: 'orderList', params: { orderId } });
      }
    },
    // 获取数据
    getList: function getList() {
      var _this = this;
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      (0, _finance.getMoreServiceOrderList)((0, _objectSpread2.default)({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        _this.tableData = res.data || [];
        _this.total = res.total || 0;
      });
    },
    // 查询
    search: function search() {
      var pickerVal1 = this.$refs.makeOrderPicker.getVal();
      var pickerVal3 = this.$refs.payPicker.getVal();
      //       const pickerVal4 = this.$refs.refundPicker.getVal();
      //       if (pickerVal1 == 'error' || pickerVal2 == 'error' || pickerVal3 == 'error' || pickerVal4 == 'error') {
      //           return;
      //       }
      this.searchForm.orderCreateTime = pickerVal1;
      this.searchForm.orderPayTime = pickerVal3;
      //       this.searchForm.refundTime = pickerVal4;
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      if (this.$refs.makeOrderPicker) {
        this.$refs.makeOrderPicker.reset();
      }
      if (this.$refs.withholdPicker) {
        this.$refs.withholdPicker.reset();
      }
      if (this.$refs.payPicker) {
        this.$refs.payPicker.reset();
      }
      if (this.$refs.refundPicker) {
        this.$refs.refundPicker.reset();
      }
      this.searchForm = {
        orderNo: '',
        goodsName: '',
        orderCreateTime: '',
        orderPayTime: ''
      };
      this.getList();
    },
    // 导出
    exportFile: function exportFile() {
      var param = this.$func.deepClone(this.searchForm);
      (0, _finance.moreServerListExport)(param);
    },
    getSelect: function getSelect() {
      var _this2 = this;
      getOrderStatus().then(function (res) {
        var list = [];
        for (var key in res) {
          list.push({
            id: key,
            name: res[key]
          });
        }
        _this2.selectList = list || [];
      });
    },
    getLeaseStatusType: function getLeaseStatusType(status) {
      var listtype = {
        0: "未支付",
        1: "订单关闭",
        2: "待支付",
        3: "支付成功",
        4: "支付失败",
        5: "已退款"
      };
      return listtype[status];
    },
    refund: function refund(id) {
      console.log("id", id);
      this.editItemId = id || null;
      this.$refs.addDialog.dialogVisible = true;
    },
    payOrder: function payOrder(item) {
      this.edItem = item;
      this.$refs.offlinePayMore.dialogVisible = true;
    }
  }
};
exports.default = _default;