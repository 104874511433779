"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.maYiChainCreate = maYiChainCreate;
exports.maYiInsuranceCancel = maYiInsuranceCancel;
exports.maYiInsuranceCreate = maYiInsuranceCreate;
exports.maYiLeasePromiseCreate = maYiLeasePromiseCreate;
exports.maYiLeaseRentalCreate = maYiLeaseRentalCreate;
exports.maYiLeaseRentalCreateOne = maYiLeaseRentalCreateOne;
exports.maYiLogisticCreate = maYiLogisticCreate;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
/**
 * 订单调用蚂蚁接口
 */

// 订单上链
function maYiChainCreate(order_id) {
  return _fetch.default.post("/Supplier/MaYi/chainCreate", {
    order_id: order_id
  });
}
// 订单上链
function maYiLogisticCreate(order_id) {
  return _fetch.default.post("/Supplier/MaYi/goodMYCreateLogistic", {
    order_id: order_id
  });
}
// 合约上链（承诺信息上链）
function maYiLeasePromiseCreate(order_id) {
  return _fetch.default.post("/Supplier/MaYi/goodMYCreateLeasePromise", {
    order_id: order_id
  });
}
// 合约上链（承诺信息上链）
function maYiLeaseRentalCreate(order_id) {
  return _fetch.default.post("/Supplier/MaYi/goodMYCreateRental", {
    order_id: order_id
  });
}
// 合约上链（承诺信息上链）
function maYiLeaseRentalCreateOne(params) {
  return _fetch.default.post("/Supplier/MaYi/goodMYCreateRentalOne", params);
}

// 合约上链 购买保险
function maYiInsuranceCreate(order_id) {
  return _fetch.default.post("/Supplier/MaYi/maYiInsuranceCreate", {
    order_id: order_id
  });
}

// 合约上链 退保险
function maYiInsuranceCancel(order_id) {
  return _fetch.default.post("/Supplier/MaYi/maYiInsuranceCancel", {
    order_id: order_id
  });
}