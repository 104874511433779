"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _bannerForm = _interopRequireDefault(require("../components/bannerForm"));
var _colForm = _interopRequireDefault(require("../components/colForm"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    bannerForm: _bannerForm.default,
    colForm: _colForm.default
  },
  data: function data() {
    return {
      carousel: [],
      configData: [{
        backgroundImg: '',
        title: '',
        subtitle: '',
        jumpUrl: '',
        jumpType: ''
      }, {
        backgroundImg: '',
        title: '',
        subtitle: '',
        jumpUrl: '',
        jumpType: ''
      }, {
        backgroundImg: '',
        title: '',
        subtitle: '',
        jumpUrl: '',
        jumpType: ''
      }]
    };
  },
  methods: {
    // 升序
    upSortItem: function upSortItem(index) {
      if (index === 0) {
        return;
      }
      var item = this.carousel[index];
      this.$set(this.carousel, index, this.carousel[index - 1]);
      this.$set(this.carousel, index - 1, item);
    },
    // 降序
    downSortItem: function downSortItem(index) {
      if (index === this.carousel.length - 1) {
        return;
      }
      var item = this.carousel[index];
      this.$set(this.carousel, index, this.carousel[index + 1]);
      this.$set(this.carousel, index + 1, item);
    },
    // 删除推荐位
    deleteItem: function deleteItem(index) {
      this.carousel.splice(index, 1);
    },
    addBanner: function addBanner() {
      this.carousel.push({
        img: '',
        jumpType: '',
        jumpUrl: '',
        goods: {}
      });
    },
    setVal0: function setVal0(_ref) {
      var jumpType = _ref.jumpType,
        jumpUrl = _ref.jumpUrl,
        goods = _ref.goods;
      this.configData[0].jumpType = jumpType;
      this.configData[0].jumpUrl = jumpUrl;
      this.configData[0].goods = jumpType == 2 ? goods : {};
    },
    setVal1: function setVal1(_ref2) {
      var jumpType = _ref2.jumpType,
        jumpUrl = _ref2.jumpUrl,
        goods = _ref2.goods;
      this.configData[1].jumpType = jumpType;
      this.configData[1].jumpUrl = jumpUrl;
      this.configData[1].goods = jumpType == 2 ? goods : {};
    },
    setVal2: function setVal2(_ref3) {
      var jumpType = _ref3.jumpType,
        jumpUrl = _ref3.jumpUrl,
        goods = _ref3.goods;
      this.configData[2].jumpType = jumpType;
      this.configData[2].jumpUrl = jumpUrl;
      this.configData[2].goods = jumpType == 2 ? goods : {};
    }
  }
};
exports.default = _default;