"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/toConsumableArray.js"));
var _goodsLink = _interopRequireDefault(require("@/components/goodsLink"));
var _goodsDialog = _interopRequireDefault(require("@/components/goodsDialog.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    goodsLink: _goodsLink.default,
    goodsDialog: _goodsDialog.default
  },
  props: {
    goodsNum: {
      default: 3
    },
    editData: {
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      goodsList: []
    };
  },
  watch: {
    editData: function editData(val) {
      if (val) {
        this.goodsList = val;
      }
    },
    goodsList: function goodsList(val) {
      // console.log('goodsList', val)
      this.$emit('setSubmitData', val);
    }
  },
  mounted: function mounted() {},
  methods: {
    // 添加商品
    openSelect: function openSelect() {
      this.$refs.goodsDialog.dialogVisible = true;
      this.$refs.goodsDialog.selectItems = (0, _toConsumableArray2.default)(this.goodsList);
    },
    // 设置选中
    setSelect: function setSelect(goodsList) {
      this.goodsList = goodsList;
    },
    // 升序
    upSort: function upSort(index) {
      if (index === 0) {
        return;
      }
      var item = this.goodsList[index];
      this.$set(this.goodsList, index, this.goodsList[index - 1]);
      this.$set(this.goodsList, index - 1, item);
    },
    // 降序
    downSort: function downSort(index) {
      if (index === this.goodsList.length - 1) {
        return;
      }
      var item = this.goodsList[index];
      this.$set(this.goodsList, index, this.goodsList[index + 1]);
      this.$set(this.goodsList, index + 1, item);
    },
    // 删除商品
    deleteGoods: function deleteGoods(index) {
      this.goodsList.splice(index, 1);
    }
  }
};
exports.default = _default2;