"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es6.regexp.replace");
var _order = require("@/api/order");
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    cropUpload: _cropUpload.default
  },
  props: ['orderId'],
  data: function data() {
    return {
      form: {
        orderId: "",
        delivery_type: 1,
        logistics_name: '',
        logistics_no: '',
        cost_price: 0,
        images: []
      },
      rules: {
        delivery_type: [{
          required: true,
          message: '请选择发货类型',
          trigger: 'change'
        }],
        logistics_name: [{
          required: true,
          message: '请选择物流公司',
          trigger: 'change'
        }],
        logistics_no: [{
          required: true,
          message: '物流单号不能为空',
          trigger: 'blur'
        }],
        images: [{
          required: true,
          message: '请上传发货凭证',
          trigger: 'change'
        }]
      },
      dialogVisible: false,
      logisticsNames: []
    };
  },
  methods: {
    changeInput: function changeInput(value) {
      var pattern = /[^[0-9a-zA-Z]*/g;
      this.form.logistics_no = this.form.logistics_no.replace(pattern, '');
    },
    imgChange: function imgChange(val, type) {
      if (type === 'del') {
        this.form.images.splice(val, 1);
      } else {
        this.form.images.push(val);
      }
      this.$refs.cropUpload.sortList = this.form.images;
    },
    handleTypeChange: function handleTypeChange(val) {
      if (val != 1) {
        this.form.logistics_name = '';
        this.form.logistics_no = '';
        this.form.cost_price = 0;
        this.form.images = [];
      }
    },
    submit: function submit() {
      var _this = this;
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          var params = (0, _objectSpread2.default)({}, _this.form);
          params.images = params.images.join(',');
          (0, _order.deliverGoods)(params).then(function (res) {
            _this.dialogVisible = false;
            _this.$emit('getList', true);
          });
        } else {
          _this.$message({
            message: "请检查表单错误",
            type: "error"
          });
          return false;
        }
      });
    },
    getSelect: function getSelect() {
      var _this2 = this;
      (0, _order.getLogisticAll)().then(function (res) {
        _this2.logisticsNames = res || [];
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.form = {
          orderId: this.orderId,
          delivery_type: 1,
          logistics_name: '',
          logistics_no: '',
          cost_price: 0,
          images: []
        };
        this.getSelect();
      } else {
        this.$refs['ruleForm'].resetFields();
      }
    }
  }
};
exports.default = _default;