var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "rate_info footer-page"
    },
    [
      _vm._m(0),
      _c("div", { staticClass: "flex flex-wrap list_info" }, [
        _c(
          "div",
          { staticClass: "list_item" },
          [
            _c("label", [_vm._v("前置风控 ：")]),
            _c(
              "el-select",
              {
                attrs: { clearable: "", placeholder: "请选择" },
                model: {
                  value: _vm.value,
                  callback: function($$v) {
                    _vm.value = $$v
                  },
                  expression: "value"
                }
              },
              _vm._l(_vm.options, function(item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value }
                })
              }),
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "page-footer-btns" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("提交")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "danger" },
              on: {
                click: function($event) {
                  return _vm.$router.back()
                }
              }
            },
            [_vm._v("返回")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "label_title" }, [
      _c("p", [_vm._v("配置设置")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }