var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tab-title-page" },
    [
      _c(
        "div",
        { staticClass: "nav_tabs" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            [
              _c("el-tab-pane", { attrs: { label: "商品排序", name: "sort" } }),
              _c("el-tab-pane", {
                attrs: { label: "轮播图设置", name: "banner" }
              }),
              _c("el-tab-pane", {
                attrs: { label: "店铺分类设置", name: "classify" }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("el-divider", { staticClass: "middle_divider" }),
      _c(
        "div",
        { staticClass: "child_page" },
        [
          _vm.activeName === "sort"
            ? _c("sort-set", { ref: "sortSet" })
            : _vm._e(),
          _vm.activeName === "banner"
            ? _c("banner-set", { ref: "bannerSet" })
            : _vm._e(),
          _vm.activeName === "classify"
            ? _c("classify-set", { ref: "classifySet" })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }