"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _finance = require("@/api/finance");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ['viewItem'],
  data: function data() {
    return {
      dialogVisible: false,
      tableData: [],
      feeRate: ''
    };
  },
  methods: {},
  watch: {
    dialogVisible: function dialogVisible(val) {
      var _this = this;
      if (val) {
        var _this$viewItem = this.viewItem,
          order_id = _this$viewItem.order_id,
          trans_id = _this$viewItem.trans_id;
        (0, _finance.getFeeDetails)({
          orderId: order_id,
          transId: trans_id
        }).then(function (res) {
          _this.tableData = [(0, _objectSpread2.default)({}, res)] || [];
          // let risk_amount = res.risk_amount || 0;
          // let contract_amount = res.contract_amount || 0;
          // let realname_amount = res.realname_amount || 0;
          // const fee = (parseFloat(risk_amount) + parseFloat(contract_amount) + parseFloat(realname_amount)).toFixed(2);
          // const rateTotal = (res.file_rate ? parseFloat(res.file_rate) : 0) +
          //   (res.financing_rate ? parseFloat(res.financing_rate) : 0) +
          //   (res.insurance_rate ? parseFloat(res.insurance_rate) : 0) +
          //   (res.platform_rate ? parseFloat(res.platform_rate) : 0) +
          //   (res.withhold_rate ? parseFloat(res.withhold_rate) : 0);
          _this.feeRate = res.order_fixed_cost + '+' + res.order_rate + '%';
        });
      }
    }
  }
};
exports.default = _default;