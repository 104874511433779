"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_supplier/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _editService = _interopRequireDefault(require("./components/editService"));
var _goods = require("@/api/goods");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    customPage: _customPage.default,
    editService: _editService.default
  },
  data: function data() {
    return {
      loading: false,
      searchForm: {},
      total: 0,
      tableData: [],
      currItem: {}
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.loading = true;
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      (0, _goods.getServiceList)((0, _objectSpread2.default)({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        // console.log('getServiceList', res)
        _this.tableData = res.data;
        _this.total = res.total;
        _this.loading = false;
      });
    },
    edit: function edit() {
      var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.currItem = item;
      this.$refs.editService.open();
    },
    // editSort(item, e) {
    // 	let params = {
    // 		setmeal_id: item.id,
    // 		sort: e,
    // 	}
    // 	this.editGoodsSetmealStatus(params)
    // },
    // editStatus(item) {
    // 	let status = item.meal_status == 0 ? 1 : 0
    // 	let params = {
    // 		setmeal_id: item.id,
    // 		meal_status: status,
    // 	}
    // 	this.editGoodsSetmealStatus(params)
    // },
    // editGoodsSetmealStatus(params) {
    // 	editGoodsSetmealStatus(params).then(res => {
    // 		this.getList()
    // 	}).catch()
    // },
    // 删除
    handleDelete: function handleDelete(id) {
      var _this2 = this;
      this.$confirm('您确定要删除该套餐信息吗？', '删除套餐信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        deleteGoodsSetmeal({
          goods_id: _this2.goodsId,
          setmeal_id: id
        }).then(function (res) {
          _this2.getList();
        });
      });
    }
  }
};
exports.default = _default;