"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _system = require("@/api/system");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {},
  data: function data() {
    return {
      loading: false,
      tableData: []
    };
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this = this;
      this.loading = true;
      (0, _system.getBlockIpList)().then(function (res) {
        if (res) {
          _this.tableData = res;
        }
        _this.loading = false;
      });
    },
    clearBlockIp: function clearBlockIp() {
      (0, _system.clearBlockIp)().then(function (res) {});
    }
  }
};
exports.default = _default;